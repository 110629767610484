import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ToggleDropdown } from '../toggle-dropdown';
import { connect } from "react-redux";
import { StoreState } from "../../types/store-state.type";
import { destroySession } from "../../store/auth.store";
import { isAdmin } from '../../helpers/role.helper';
import { TopbarIcon } from './topbar-icon';
import { readableNum } from '../../helpers/ui.helper';

function HeaderComponent({
    user,
    walletDetails,
    endSession
}: any) {

    const [isNavVisible, setIsNavVisible] = useState(false);

    /**
     * Logout functionality
     * @param e
     */
    const logOut = (e: any) => {
        e.preventDefault()
        endSession();
        window.location.href = "/";
        localStorage.removeItem("fgc.user");
    }

    const toggleNav = (e: any) => {
        e.preventDefault();
        setIsNavVisible(!isNavVisible);
    }

    const { fgc } = walletDetails;

    const userIsAdmin = isAdmin(user);

    return (
        <div className="topbar-wrap">
            <div className="topbar is-sticky">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <ul className="topbar-nav d-lg-none">
                            <li className="topbar-nav-item relative">
                                <a className={"toggle-nav" + (isNavVisible ? " active" : "")} href="#" onClick={toggleNav}>
                                    <div className="toggle-icon">
                                        <span className="toggle-line" />
                                        <span className="toggle-line" />
                                        <span className="toggle-line" />
                                        <span className="toggle-line" />
                                    </div>
                                </a>
                            </li>
                            {/* .topbar-nav-item */}
                        </ul>
                        {/* .topbar-nav */}
                        <a className="topbar-logo" href="./">
                            <img src="/images/fgc_logo_2.png" alt="logo" />
                        </a>
                        <ul className="topbar-nav">
                            <li className="topbar-nav-item relative" style={{ display: user?.name ? 'none' : 'block' }}>
                                <a href="login">
                                    <button className="btn btn-primary btn-block">Login</button>
                                </a>
                            </li>
                            <li className="topbar-nav-item relative" style={{ display: user?.name ? 'block' : 'none' }}>
                                <span className="user-welcome d-none d-lg-inline-block">Welcome! {user && user.name}</span>
                                <ToggleDropdown
                                    buttonClass="user-thumb"
                                    buttonElement={<em className="ti ti-user" />}
                                    dropdownClass="user-dropdown"
                                    direction="right"
                                    arrowDirection="right"
                                    dropdownContent={
                                        <>
                                            {
                                                user.role === 'user' ?
                                                    <>
                                                        <div className="user-status" >
                                                            <h6 className="user-status-title">Token balance</h6>
                                                            <div className="user-status-balance">{readableNum(fgc)} <small>FGC</small></div>
                                                        </div>
                                                        <ul className="user-links">
                                                            <li><Link to="profile"><i className="ti ti-id-badge" />My Profile</Link>
                                                            </li>
                                                            <li><Link to="referrals"><i className="ti ti-infinite" />Referral</Link></li>
                                                            {/*<li><a href="activity.html"><i className="ti ti-eye" />Activity</a></li>*/}
                                                        </ul>
                                                    </>
                                                    : <></>
                                            }
                                            <ul className="user-links bg-light">
                                                <li><a href="sign-in.html" onClick={logOut}><i className="ti ti-power-off" />Logout</a>
                                                </li>
                                            </ul>
                                        </>
                                    } />
                            </li>
                            {/* .topbar-nav-item */}
                        </ul>
                        {/* .topbar-nav */}
                    </div>
                </div>
                {/* .container */}
            </div>
            {/* .topbar */}
            <div className={"navbar" + (isNavVisible ? " active" : "")}>
                <div className="container">
                    <div className="navbar-innr">
                        <ul className="navbar-menu" onClick={() => setIsNavVisible(false)} >
                            <TopbarIcon show={true} to="/" iconClass="dashboard">Overview</TopbarIcon>
                            <TopbarIcon show={!userIsAdmin} to="/buy-token" iconClass="coins">Buy</TopbarIcon>
                            {/* <TopbarIcon show={!userIsAdmin} to="/deposits" iconClass="wallet">Buy With Crypto</TopbarIcon> */}
                            <TopbarIcon show={!userIsAdmin} to="/ico-distribution" iconClass="distribution">Information</TopbarIcon>
                            <TopbarIcon show to="/transactions" iconClass="transactions">Transactions</TopbarIcon>
                            <TopbarIcon show={userIsAdmin} to="/kyc-list" iconClass="user">KYC List</TopbarIcon>
                            <TopbarIcon show={userIsAdmin} to="/kyc-list/yoti" iconClass="user">Yoti KYC List</TopbarIcon>
                            <TopbarIcon show={userIsAdmin} to="/offline-transactions" iconClass="transactions">Offline Transactions</TopbarIcon>
                            <TopbarIcon show={!userIsAdmin} to="/profile" iconClass="user">Profile</TopbarIcon>
                            <TopbarIcon show={true} target='_blank' to="http://footballgoalcoin.com/" iconClass="globe-outline">Main Website</TopbarIcon>
                            <TopbarIcon show={true} target='_blank' to="http://mining.footballgoalcoin.com/" iconClass="coins" wrapperClass="d-lg-none">Mining</TopbarIcon>
                            <TopbarIcon show={true} target='_blank' to="https://footballgoalcoin.com/earn/" iconClass="distribution" wrapperClass="d-lg-none">Earn</TopbarIcon>
                            {/* <TopbarIcon show={!userIsAdmin} to="https://footballgoalcoin.com/" iconClass="globe-outline" target='_blank'>Main Website</TopbarIcon> */}
                        </ul>
                        {user.role === 'user' ? <NavBarKYCButton user={user}></NavBarKYCButton> : <></>}
                    </div>
                    {/* .navbar-innr */}
                </div>
                {/* .container */}
            </div>
            {/* .navbar */}
        </div>
    )
}

function NavBarKYCButton({ user }: any) {
    return (
        <ul className="navbar-btns">
            {
                user && user.kycStatus === "pending" ? (
                    <li><Link to="kyc-application" className="btn btn-sm btn-outline btn-light"><em
                        className="text-primary ti ti-files" /><span>KYC Application</span></Link></li>
                ) : <></>
            }
            {
                user && user.kycStatus === "rejected" ? (
                    <li><Link to="kyc-application" className="btn btn-sm btn-outline btn-light"><em
                        className="text-primary ti ti-files" /><span>KYC Rejected</span></Link></li>
                ) : <></>
            }
            {
                user && user.kycStatus === "approved" && false ? (
                    <li><span className="badge badge-outline badge-success badge-lg"><em
                        className="text-success ti ti-files mgr-1x" /><span
                            className="text-success">KYC Approved</span></span></li>
                ) : <></>
            }
            {
                user && user.kycStatus === "verifying" ? (
                    <li><span className="badge badge-outline badge-success badge-lg"><em
                        className="text-info ti ti-files mgr-1x" /><span
                            className="text-info">KYC Verifying</span></span></li>
                ) : <></>
            }
        </ul>
    )
}

const stp = (state: StoreState) => ({
    user: state.auth,
    walletDetails: state.walletDetails,
});
const dtp = {
    endSession: destroySession
}

export const Header = connect(stp, dtp)(HeaderComponent)
