import React, { useState } from 'react';
import { DataTable } from '../../../components/data-table';
import { approveKyc, deleteKyc, getKycList, rejectKyc } from '../../../services/kyc.service';
import { KycDetail } from '../../../types/generic/kyc-details.type';
import cx from "classnames";
import { RecordThreeDotMenu } from '../../../components/record-three-dot-menu';
import { getDownloadPathFromStorage, httpErrorToast, isAxiosError } from '../../../helpers/ui.helper';
import { KycDetailsModal } from '../../../components/modals/kyc-details.modal';
import { openModal } from '../../../helpers/modal.helper';
import { toast } from 'react-toastify';

const columns = [
    {
        className: "dt-user",
        title: "User"
    },
    {
        className: "dt-doc-type",
        title: "Document Type"
    },
    {
        className: "dt-doc-front",
        title: "Document Front"
    },
    {
        className: "dt-doc-back",
        title: "Document Back"
    },
    {
        className: "dt-webcam-capture",
        title: "Webcam Capture"
    },
    {
        className: "dt-status",
        title: "Status"
    }
];

function getStatusClass(status: string) {
    switch (status) {
        case "pending":
            return "warning";
        case "verifying":
            return "info";
        case "approved":
            return "success";
        case "rejected":
            return "danger";
        default:
            return "primary";
    }
}

function renderRow(record: KycDetail, {
    setSelectedRecord,
    onApprove,
    onReject,
    onDelete,
}: any) {

    const recordStatus = record.status.slice(0, 1).toUpperCase() + record.status.slice(1);

    return [
        {
            className: "dt-user",
            content: (
                <div>
                    <span className="lead user-name">{record.user?.name ?? "NA"}</span>
                    <span className="sub user-id">{record.user?.id ?? "NA"}</span>
                </div>
            ),
        },
        {
            className: "dt-doc-type",
            content: (
                <span className="sub sub-s2 sub-dtype">{record.documentType.toUpperCase()}</span>
            ),
        },
        {
            className: "dt-doc-front",
            content: (
                <>
                    <img src={getDownloadPathFromStorage(record.documentPath[0])} alt="" width="54px" />&nbsp;
                    <a title="download" target="_blank" href={getDownloadPathFromStorage(record.documentPath[0])} download><em className="fas fa-download"></em></a>
                </>
            ),
        },
        {
            className: "dt-doc-back",
            content: (
                <>
                    {
                        record.documentType !== 'driver-license' ?
                            <>
                                <img src={getDownloadPathFromStorage(record.documentPath[1])} alt="" width="54px" />&nbsp;
                                <a title="download" target="_blank" href={getDownloadPathFromStorage(record.documentPath[1])} download><em className="fas fa-download"></em></a>
                            </> : <> - </>
                    }
                </>
            ),
        },
        {
            className: "dt-webcam-capture",
            content: (
                <>
                    <img src={getDownloadPathFromStorage(record.picturePath)} alt="" width="54px" /> &nbsp;
                    <a title="download" target="_blank" href={getDownloadPathFromStorage(record.picturePath)} download><em className="fas fa-download"></em></a>
                </>
            ),
        },
        {
            className: "dt-status",
            content: (
                <div>
                    <span className={
                        cx(
                            "dt-status-md badge badge-outline badge-md",
                            "badge-" + getStatusClass(record.status)
                        )
                    }>{recordStatus}</span>
                    <span className={
                        cx(
                            "dt-status-sm badge badge-sq badge-outline badge-md",
                            "badge-" + getStatusClass(record.status)
                        )
                    }>{recordStatus.slice(0, 1)}</span>
                </div>
            ),
        },
        {
            className: "",
            content: (
                <RecordThreeDotMenu
                    onViewDetails={e => {
                        setSelectedRecord(record);
                        openModal("kyc-details");
                    }}
                    onApprove={onApprove}
                    onCancel={onReject}
                // onDelete={onDelete}
                />
            ),
        },
    ];
}

export function KycListPage() {

    const [selectedRecord, setSelectedRecord] = useState<KycDetail>();
    const [data, setData] = useState<KycDetail[]>([]);

    const updateRecord = (record: KycDetail) => {
        setData(data.map(item => {
            if (item.id === record.id) {
                return record;
            }
            return item;
        }));
    }

    const deleteRecord = (record: KycDetail) => {
        setData(data.filter(item => {
            if (item.id === record.id) {
                return false;
            }
            return item;
        }));
    }

    const onApprove = async (record: KycDetail) => {
        console.log("record", record);
        try {
            const response = await approveKyc(record.id);
            const { message, kyc } = response;
            // update the data
            updateRecord(kyc);
            toast.success(message);
        } catch (error) {
            httpErrorToast(error);
        }
    }

    const onReject = async (record: KycDetail) => {
        try {
            const response = await rejectKyc(record.id);
            const { message, kyc } = response;
            // update the data
            updateRecord(kyc);
            toast.success(message);
        } catch (error) {
            httpErrorToast(error);
        }
    }

    const onDelete = async (record: KycDetail) => {
        return;
        try {
            const response = await deleteKyc(record.id);
            const { message, kyc } = response;
            // update the data
            deleteRecord(kyc);
            toast.success(message);
        } catch (error) {
            httpErrorToast(error);
        }
    }

    const renderRowWithProps = (record: KycDetail) => {
        return renderRow(record, {
            setSelectedRecord,
            onApprove: () => onApprove(record),
            onReject: () => onReject(record),
            onDelete: () => onDelete(record),
        })
    };

    return (
        <div className="KycListPage">
            <div className="page-user">
                <div className="page-content">
                    <div className="container">
                        <div className="card content-area">
                            <div className="card-innr">
                                <div className="card-head">
                                    <h4 className="card-title">KYC List</h4>
                                </div>
                                <DataTable
                                    columns={columns}
                                    tableClass="kyc-list"
                                    renderRow={renderRowWithProps}
                                    fetcherMethod={getKycList}
                                    dataState={{
                                        data,
                                        setData,
                                    }} />
                            </div>{/* .card-innr */}
                        </div>{/* .card */}
                    </div>{/* .container */}
                </div>
                {/* Modal Large */}
                <KycDetailsModal
                    modalId="kyc-details"
                    kyc={selectedRecord}
                    onClose={() => {
                        setSelectedRecord(undefined);
                    }}
                    threeDotMenu={(
                        <RecordThreeDotMenu
                            onApprove={e => onApprove(selectedRecord!)}
                            onCancel={e => onReject(selectedRecord!)}
                            // onDelete={e => { }}
                            btnSize="sm"
                        />)} />
                {/* Modal End */}
                {/* JavaScript (include all script here) */}
            </div>
        </div>
    )
}
