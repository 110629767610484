import React from 'react';
import { FormErrorList } from './form-error-list.atom';

type FormInputProps = {
    type?: "text" | "password",
    placeholder?: string,
    value: string,
    onChange: Function,
    errors?: string[],
    disabled?: boolean,
}

export function FormInput({
    type = "text",
    placeholder = "",
    value = "",
    errors,
    onChange = (value: string) => {
    },
    disabled = false,
}: FormInputProps) {
    return (
        <div className="input-item">
            <input
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={e => {
                    onChange(e.target.value);
                }}
                className="input-bordered" disabled={disabled} />
            {/* form errors */}
            <FormErrorList errors={errors} />

        </div>
    )
}
