import React, {useEffect, useState} from "react";
import {CSVLink, CSVDownload} from "react-csv";
import {getAllUserTransactions} from "../../services/transaction.service";
import {Transaction} from "../../types/generic/transaction.type";

export function ExportToCSV({filterObject }: any) {
    const [csvData, setCSVData] = useState<any>([]);
    const [downloadFile, setDownloadFile] = useState(false);
    const [csvHeaders, setCSVHeaders] = useState<string[]>([]);

    const initiateCSVDownload = () => {
        getAllFilteredTransactions();
    }

    const getAllFilteredTransactions = () => {
        const reqQuery = {...filterObject, ...{pageLength: 1000}};
        getAllUserTransactions(reqQuery).then((r) => {
            prepareDataForCSVExport(r.data);
        });
    }

    const prepareDataForCSVExport = (data: Transaction[]) => {
        setCSVHeaders(["User name", "TXN No", "Created at", "Tokens", "Amount", "Contribution Currency", "USD Contribution", "Wallet Address", "TXN type"])
        const transactionList = data.map(transaction => {
            return [
                transaction.tokenDetails.user.name,
                transaction.hash,
                transaction.createdAt,
                transaction.tokenDetails.tokens + transaction.tokenDetails.bonusTokens,
                transaction.tokenDetails.contributionAmount,
                transaction.tokenDetails.contributionCurrency.toUpperCase(),
                transaction.tokenDetails.usdContribution,
                transaction.fromWalletAddress,
                transaction.type.slice(0, 1).toUpperCase() + transaction.type.slice(1)
            ]
        })
        const csvData = [csvHeaders, ...transactionList];
        setCSVData(csvData);
        setDownloadFile(!downloadFile);
    }
    return (
        <>
            {/*<CSVLink download="transactions-list.csv" style={{minWidth: '115px'}} className="btn btn-primary" data={csvData}>Export CSV 1</CSVLink>*/}
            <button style={{minWidth: '115px'}} className="btn btn-primary" onClick={() => initiateCSVDownload()}>Export CSV</button>
            {
                downloadFile ? <CSVDownload filename='sample.csv' data={csvData} target="_blank"/> : <></>
            }
        </>
    )
}
