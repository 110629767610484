import ConfigResponse from "../types/responses/config-response.type"
import ExchangeRateResponse from "../types/responses/exchange-rate-response.type";
import { httpGet } from "./http.service"


const getConfig = async (): Promise<ConfigResponse> => {
    return httpGet<ConfigResponse>("config");
}

const getExchangeRate = async (): Promise<ExchangeRateResponse> => {
    return httpGet<ExchangeRateResponse>("https://api.coingecko.com/api/v3/simple/price?ids=bitcoin%2Cethereum&vs_currencies=usd");
}

export {
    getExchangeRate,
    getConfig,
}