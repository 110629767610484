import React, { MouseEventHandler } from 'react';
import { ToggleDropdown } from "../components/toggle-dropdown";
import cx from "classnames";

interface RecordThreeDotMenuProps {
    onViewDetails?: MouseEventHandler<HTMLSpanElement>,
    onApprove?: MouseEventHandler<HTMLSpanElement>,
    onCancel?: MouseEventHandler<HTMLSpanElement>,
    onDelete?: MouseEventHandler<HTMLSpanElement>,
    btnSize?: "xs" | "sm",
}

export function RecordThreeDotMenu({
    onViewDetails,
    onApprove,
    onCancel,
    onDelete,
    btnSize = "xs",
}: RecordThreeDotMenuProps) {
    return (
        <>
            <div className="relative d-inline-block">
                <ToggleDropdown
                    buttonClass={cx(
                        "btn btn-light-alt btn-icon",
                        "btn-" + btnSize,
                    )}
                    direction="top-left"
                    buttonElement={<em className="ti ti-more-alt"></em>}
                    dropdownContent={
                        <ul className="dropdown-list">
                            {
                                onViewDetails ? (
                                    <li><a href="#" onClick={onViewDetails}><em className="ti ti-eye"></em> View Details</a></li>
                                ) : <></>
                            }
                            {
                                onApprove ? (
                                    <li><a href="#" onClick={onApprove}><em className="ti ti-check"></em> Approve</a></li>
                                ) : <></>
                            }
                            {
                                onCancel ? (
                                    <li><a href="#" onClick={onCancel}><em className="ti ti-na"></em> Cancel</a></li>
                                ) : <></>
                            }
                            {
                                onDelete ? (
                                    <li><a href="#" onClick={onDelete}><em className="ti ti-trash"></em> Delete</a></li>
                                ) : <></>
                            }
                        </ul>
                    }
                />
            </div>
        </>
    )
}