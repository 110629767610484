export function BankTransferBtn() {
    return (
        <div className="accordion" id="accordion-1">
            <div className="accordion-item">
                <button className="btn btn-primary w-100" data-toggle="collapse" data-target="#collapse-1-1">
                    <div className="d-flex justify-content-between">
                        <span>Bank Transfer</span>
                        <span><img src="./images/bank-transfer.png" style={{
                            height: '65px',
                            position: 'absolute',
                            top: '-14px',
                            right: '-20px'
                        }} alt="" /></span>
                    </div>
                </button>
                <div id="collapse-1-1" className="collapse" data-parent="#accordion-1">
                    <div className="accordion-content mt-3">
                        <div>
                            <div className='text-primary'>
                                Offline bank transfers can take from a few minutes to five working days. Once the transfer has been made, please send us your user name, your direct contact number, and evidence of the transfer to info@footballgoalcoin.com. Kindly add your user name as the reference note for the transfer.
                            </div>
                            <div className="gaps-1x" />
                            <div>
                                Account name: Football Goal Coin Limited
                            </div>
                            <div>
                                Sort code: 23-14-70
                            </div>
                            <div>
                                Account number: 33837298
                            </div>
                            <div>
                                IBAN: GB59 TRWI 2314 7033 8372 98
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}