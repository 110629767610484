import React from 'react';
import { readableNum } from "../../helpers/ui.helper";
import { StoreState } from "../../types/store-state.type";
import { connect } from "react-redux";
import { TokenSalesProgressCard } from '../../components/token-sales-progress-card.component';
import { ContributionCard } from '../../components/contribution-card.component';
import { Link } from "react-router-dom";
import './ico-distribution.scss';

function ICODistributionPageComponent({
    user,
    walletDetails
}: any) {

    const { usd, fgc, eth, btc } = walletDetails;

    return (
        <div className="ICODistributionPage">
            <div className="page-user">
                <div className="page-content">
                    <div className="container">
                        <div className="row d-none d-lg-flex">
                            <div className="col-md-4">
                                <div className="text-center">
                                    <img height="230px" src="/images/ico_distribution_1.jpg" alt="" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-center">
                                    <img height="230px" src="/images/ico_distribution_2.jpg" alt="" />
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="text-center">
                                    <img height="230px" src="/images/money_back_guarantee.png" alt="" />
                                </div>
                            </div>
                        </div>
                        <div className='row d-block d-lg-none'>
                            <div className='col-md-12'>
                                <div className="text-white h1" style={{ fontSize: '1.7em' }}>Information</div>
                            </div>
                            <div className="my-1" />
                            <div className='col-md-12'>
                                <iframe width="100%" height="300" frameBorder="0"
                                    src="https://www.youtube.com/embed/bu6wVUmHYOI">
                                </iframe>
                            </div>
                        </div>
                        <div className="gaps-3x" />
                        <div className="row">
                            <div className="main-content col-lg-8">
                                <div className="content-area card">
                                    <div className="card-innr">
                                        <div className="mb-2 d-none d-lg-block">
                                            <iframe width="100%" height="300" frameBorder="0"
                                                src="https://www.youtube.com/embed/bu6wVUmHYOI">
                                            </iframe>
                                        </div>
                                        <div className="card-head">
                                            <h4 className="card-title">Ready to invest in Football Goal Coin? Let's go!</h4>
                                        </div>
                                        <div className="card-text">
                                            <p className='text-justify'>
                                                Find key information about Football Goal Coin's (FGC) initial coin offering (ICO) here. FGC ICO Tokens are currently available for purchase; however, there is limited supply.
                                            </p>
                                        </div>
                                        <div className="gaps-3x" />
                                        <div className="card-head">
                                            <h3 className="card-title">What is Football Goal Coin?</h3>
                                        </div>
                                        <div className="card-text">
                                            <p className='text-justify'>
                                                Football Goal Coin (FGC) is a revolutionary fintech ecosystem designed specifically for mass adoption. With its unique approach to combining football with blockchain technology, FGC offers investors a chance to participate in an innovative project that has enormous growth and potential. The Football Goal Coin ecosystem includes the following all-in-one global solution assets: Cryptocurrency, Blockchain, Exchange, Escrow, Mining System, Payments, Lending, Franchise, Off-The-Grid Storage and DAO Management Committee.
                                            </p>
                                        </div>
                                        <div className="gaps-3x" />
                                        <div className="card-head">
                                            <h5 className="card-title card-title-md">ICO Schedule</h5>
                                        </div>
                                        <div className="schedule-item">
                                            <div className="row">
                                                <div className="col-xl-6 col-md-6 col-lg-6">
                                                    <div className="pdb-1x">
                                                        <h5 className="schedule-title"><span>Phase 1:</span>
                                                            {/* <span className="badge badge-success ucap badge-xs">Running</span> */}
                                                        </h5>
                                                        <span>Start at Jul 01, 2023 - 00:00 AM</span>
                                                        <span>End at Jul 31, 2023 - 00:00 AM</span>
                                                        <div className='py-3'>Max FGC you can buy 50000</div>
                                                        <div className='ico-schedule'>
                                                            <div className='list'>
                                                                <div>{'<'}50</div>
                                                                <div>0.9950 $</div>
                                                            </div>
                                                            <div className='list'>
                                                                <div>{'<'}100</div>
                                                                <div>0.9930 $</div>
                                                            </div>
                                                            <div className='list'>
                                                                <div>{'<'}500</div>
                                                                <div>0.9900 $</div>
                                                            </div>
                                                            <div className='list'>
                                                                <div>{'<'}1000</div>
                                                                <div>0.9880 $</div>
                                                            </div>
                                                            <div className='list'>
                                                                <div>{'<'}5000</div>
                                                                <div>0.9850 $</div>
                                                            </div>
                                                            <div className='list'>
                                                                <div>{'<'}20000</div>
                                                                <div>0.9800 $</div>
                                                            </div>
                                                            <div className='list'>
                                                                <div>{'<'}50000</div>
                                                                <div>0.9700 $</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-md-6 col-lg-6">
                                                    <div className="pdb-1x">
                                                        <h5 className="schedule-title"><span>Phase 2:</span>
                                                            {/* <span className="badge badge-success ucap badge-xs">Running</span> */}
                                                        </h5>
                                                        <span>Start at Aug 01, 2023 - 00:00 AM</span>
                                                        <span>End at Aug 31, 2023 - 00:00 AM</span>

                                                        <div className="py-3"></div>

                                                        <div className='ico-schedule'>
                                                            <div className="list">
                                                                <div>{'<'}50</div>
                                                                <div>0.9975 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}100</div>
                                                                <div>0.9965 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}500</div>
                                                                <div>0.9950 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}1000</div>
                                                                <div>0.9940 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}5000</div>
                                                                <div>0.9925 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}20000</div>
                                                                <div>0.9900 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}50000</div>
                                                                <div>0.9850 $</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="schedule-item">
                                            <div className="row">
                                                <div className="col-xl-6 col-md-6 col-lg-6">
                                                    <div className="pdb-1x">
                                                        <h5 className="schedule-title">
                                                            <span>Phase 3:</span>
                                                            {/* <span className="badge badge-disabled ucap badge-xs">Upcomming</span> */}
                                                        </h5>
                                                        <span>Start at Sept 01, 2023 - 00:00 AM</span>
                                                        <span>End at December 31, 2023 - 00:00 AM</span>

                                                        <div className="py-3"></div>

                                                        <div className="ico-schedule">
                                                            <div className="list">
                                                                <div>{'<'}50</div>
                                                                <div>0.9990 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}100</div>
                                                                <div>0.9986 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}500</div>
                                                                <div>0.9980 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}1000</div>
                                                                <div>0.9976 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}5000</div>
                                                                <div>0.9970 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}20000</div>
                                                                <div>0.9960 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}50000</div>
                                                                <div>0.9940 $</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-md-6 col-lg-6">
                                                    <div className="pdb-1x">
                                                        <h5 className="schedule-title"><span>Phase 4:</span>
                                                            {/* <span className="badge badge-success ucap badge-xs">Running</span> */}
                                                        </h5>
                                                        <span>Start at Jan 01, 2024 - 00:00 AM</span>
                                                        <span>End at Jun 30, 2024 - 00:00 AM</span>

                                                        <div className="py-3"></div>

                                                        <div className="ico-schedule">
                                                            <div className="list">
                                                                <div>{'<'}50</div>
                                                                <div>0.9990 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}100</div>
                                                                <div>0.9986 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}500</div>
                                                                <div>0.9980 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}1000</div>
                                                                <div>0.9976 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}5000</div>
                                                                <div>0.9970 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}20000</div>
                                                                <div>0.9960 $</div>
                                                            </div>
                                                            <div className="list">
                                                                <div>{'<'}50000</div>
                                                                <div>0.9940 $</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*<h6 className="text-light mb-0">* Time zone set in (GMT +6) Dhaka, Bangladesh</h6>*/}
                                    </div>
                                </div>
                                <div className="content-area card">
                                    <div className="card-innr">
                                        <div className="card-head">
                                            <h5 className="card-title card-title-md">Why buy FGC ICO Tokens?</h5>
                                        </div>
                                        <div className="card-text">
                                            <p className='text-justify'>
                                                The Football Goal Coin Initial Coin Offering represents a unique investment opportunity for football enthusiasts and cryptocurrency investors alike. With its integration of football-themed mining and the potential for significant rewards, the FGC ICO offers a promising avenue that will not be repeated.
                                            </p>
                                        </div>
                                        <div className="gaps-3x" />
                                        <div className="card-head">
                                            <h5 className="card-title card-title-md">Get FREE tokens by inviting your friends and family.</h5>
                                        </div>
                                        <div className="card-text">
                                            <p className='text-justify'>
                                                You have a unique referral link below that you can share with friends and family to earn a percentage bonus on their contribution. Anyone who buys FGC ICO Tokens via your referral link will be added to your referral program, and you will gain more FGC Tokens every time they make a purchase. Referral links are only valid during this ICO token sale – hurry, there's not much time left!
                                            </p>
                                        </div>
                                        <div className="referral-form">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h5 className="mb-0 font-bold">Referral URL</h5>
                                                <Link to="/referrals">
                                                    <a className="link link-primary link-ucap">See Your referral</a>
                                                </Link>
                                            </div>
                                            <div className="copy-wrap mgb-1-5x mgt-1-5x">
                                                <span className="copy-feedback" />
                                                <em className="fas fa-link" />
                                                <input type="text" className="copy-address" defaultValue={`${process.env.REACT_APP_PANEL_URL}register?ref=${user.referralCode}`} disabled />
                                                <button className="copy-trigger copy-clipboard" data-clipboard-text={`${process.env.REACT_APP_PANEL_URL}register?ref=${user.referralCode}`}><em className="ti ti-files" /></button>
                                            </div>{/* .copy-wrap */}
                                        </div>
                                        {/*<ul className="share-links">*/}
                                        {/*    <li>Share with : </li>*/}
                                        {/*    <li><a href="#"><em className="fab fa-google-plus-g" /></a></li>*/}
                                        {/*    <li><a href="#"><em className="fab fa-twitter" /></a></li>*/}
                                        {/*    <li><a href="#"><em className="fab fa-facebook-f" /></a></li>*/}
                                        {/*    <li><a href="#"><em className="fab fa-linkedin-in" /></a></li>*/}
                                        {/*</ul>*/}

                                        <div className="card-head">
                                            <h4 className="font-bold">Links For Further Information</h4>
                                        </div>
                                        <div className="card-text">
                                            <ul style={{ padding: '0px 0 0 25px' }}>
                                                <li style={{ listStyle: 'disc' }}>
                                                    Buy FGC Tokens here:
                                                    <a target='_blank' href="https://panel.footballgoalcoin.com/buy-token"> https://panel.footballgoalcoin.com/buy-token</a>
                                                </li>
                                                <li style={{ listStyle: 'disc' }}>
                                                    Your previous purchases:
                                                    <a target='_blank' href="https://panel.footballgoalcoin.com/transactions"> https://panel.footballgoalcoin.com/transactions</a>
                                                </li>
                                                <li style={{ listStyle: 'disc' }}>
                                                    Your profile:
                                                    <a target='_blank' href="https://panel.footballgoalcoin.com/profile"> https://panel.footballgoalcoin.com/profile</a>
                                                </li>
                                                <li style={{ listStyle: 'disc' }}>
                                                    Deposit funds to buy FGC:
                                                    <a target='_blank' href="https://panel.footballgoalcoin.com/deposits"> https://panel.footballgoalcoin.com/deposits</a>
                                                </li>
                                                <li style={{ listStyle: 'disc' }}>
                                                    Download the FGC Whitepaper:
                                                    <a target='_blank' href="https://panel.footballgoalcoin.com/assets/Football_Goal_Coin_FGC_Whitepaper_2023_v.1.pdf"> https://panel.footballgoalcoin.com/assets/Football_Goal_Coin_FGC_Whitepaper_2023_v.1.pdf</a>
                                                </li>
                                                <li style={{ listStyle: 'disc' }}>
                                                    Visit the main website:
                                                    <a target='_blank' href="https://footballgoalcoin.com/"> https://footballgoalcoin.com/</a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="gaps-3x" />
                                        <div className="card-head">
                                            <h4 className="font-bold">Do you have more questions? </h4>
                                        </div>
                                        <div className="card-text">
                                            <p>
                                                Contact the Football Goal Coin team on Telegram for a quick response via
                                                <a href="https://t.me/footballgoalcoin1" target='_blank'> https://t.me/footballgoalcoin1 </a>
                                                or Whatsapp +44 7495 134467.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* .col */}
                            <div className="aside sidebar-right col-lg-4">
                                <ContributionCard />
                            </div>{/* .col */}
                        </div>{/* .container */}
                    </div>{/* .container */}
                </div>
            </div>
        </div>
    )
};

const stp = (state: StoreState) => ({
    user: state.auth,
    walletDetails: state.walletDetails,
});

const dtp = {};

export const ICODistributionPage = connect(stp, dtp)(ICODistributionPageComponent)
