import React, { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect } from 'react-redux';
import { User } from './types/generic/user.type';
import generateRoutes from './routes';
import { getWalletDetails } from './store/wallet-details.store';
import { getConfigFromServer } from './store/config.store';
import { getExchangeRateFromServer } from './store/exchange-rate.store';
import { createSession, fetchUserProfile } from './store/auth.store';
import { CustomIdleTimer } from "./helpers/idle-timer";
import { getTotalTokenSaleFromServer } from './store/token-sale.store';
import { verifyKyc } from './services/kyc.service';


const helmetContext = {};

interface AppComponentProps {
    user: User | null,
    setUser: Function,
    getWalletDetails: Function,
    getConfigFromServer: Function,
    getExchangeRateFromServer: Function,
    fetchUserProfile: Function,
    getTotalTokenSaleFromServer: Function,
}

function AppComponent({
    // state
    user,
    // dispatch
    setUser,
    getWalletDetails,
    getConfigFromServer,
    getExchangeRateFromServer,
    fetchUserProfile,
    getTotalTokenSaleFromServer,
}: AppComponentProps) {


    // fetch these on load
    useEffect(() => {
        getExchangeRateFromServer();
    }, []);

    const userId = user?.id;
    // fetch whenever user changes
    useEffect(() => {
        if (userId) {
            getConfigFromServer();
            getWalletDetails();
            fetchUserProfile();
            getTotalTokenSaleFromServer();
            // verifyKycDetails();
        }
    }, [userId]);


    // depricated, not using yoti now
    // const verifyKycDetails = async () => {
    //     if (user?.kycStatus === "pending") return console.log("KYC pending, skipping check");
    //     if (user?.kycStatus === "approved") return console.log("User KYC already done, skipping check");
    //     const response = await verifyKyc();
    //     if (response.kycStatus === "approved") {
    //         // update the user
    //         toast.success(response.message);
    //         const userObject = {
    //             ...user,
    //             kycStatus: response.kycStatus,
    //         };
    //         setUser(userObject);
    //         localStorage.setItem('fgc.user', JSON.stringify(userObject));
    //     } else {
    //         if (response.kycStatus !== "verifying") {
    //             toast.error("KYC could not be completed");
    //         }
    //         console.log("Something went wrong while verifying kyc", response);
    //     }
    // }

    return (
        <HelmetProvider context={helmetContext}>
            <RouterProvider router={generateRoutes(user)} />
            <ToastContainer />
            <CustomIdleTimer idleTime={30} />
        </HelmetProvider>
    );
}

const stp = (state: any) => ({
    user: state.auth
});

const dtp = {
    getWalletDetails,
    getConfigFromServer,
    getExchangeRateFromServer,
    fetchUserProfile,
    getTotalTokenSaleFromServer,
    setUser: createSession
};

const App = connect(stp, dtp)(AppComponent);

export default App;
