import React from 'react';
import cx from "classnames";

interface PaginationButtonsProps {
    pageStart: number;
    recordsTotal: number;
    recordsFiltered: number;
    pageLength: number;
    getData: Function;
}

export function PaginationButtons({
    pageStart = 0,
    recordsTotal = 0,
    recordsFiltered = 0,
    pageLength = 0,
    getData,
}: PaginationButtonsProps) {

    const currentPage = pageLength ? Math.floor(pageStart / pageLength) + 1 : 1;
    const lastPage = pageLength && recordsTotal ? Math.ceil(recordsTotal / pageLength) : 1;

    return (
        <div className="row align-items-center">
            {/* prev next buttons */}
            <div className="col-sm-6 text-left">
                <div
                    className="dataTables_paginate paging_simple_numbers"
                    id="DataTables_Table_0_paginate">
                    <ul className="pagination">
                        <li className={
                            cx(
                                "paginate_button page-item previous",
                                {
                                    disabled: currentPage === 1
                                }
                            )
                        }
                            id="DataTables_Table_0_previous" onClick={() => {
                                if (currentPage !== 1) {
                                    getData((currentPage - 2) * pageLength);
                                }
                            }}>
                            <a
                                tabIndex={0}
                                className="page-link">
                                Prev</a>
                        </li>
                        {/* <li className="paginate_button page-item active"><a href="#" aria-controls="DataTables_Table_0" data-dt-idx={1} tabIndex={0} className="page-link">1</a></li><li className="paginate_button page-item "><a href="#" aria-controls="DataTables_Table_0" data-dt-idx={2} tabIndex={0} className="page-link">2</a></li> */}
                        <li className={
                            cx(
                                "paginate_button page-item next",
                                {
                                    disabled: currentPage === lastPage
                                }
                            )
                        }
                            id="DataTables_Table_0_next" onClick={() => {
                                if (currentPage !== lastPage) {
                                    getData(currentPage * pageLength);
                                }
                            }}>
                            <a
                                tabIndex={0}
                                className="page-link">Next</a>
                        </li>
                    </ul>
                </div>
            </div>
            {/* total records indicator */}
            {
                recordsTotal ? (
                    <div className="col-sm-6 text-sm-right">
                        <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                            Page {currentPage} showing: {pageStart + 1} - {pageStart + recordsFiltered} of {recordsTotal}
                        </div>
                    </div>
                ) : <></>
            }
        </div>
    )
}