import React, { useState } from 'react';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import { loginWithGoogle } from '../../services/auth.service';
import LoginResponse from '../../types/responses/login-response.type';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { createSession } from '../../store/auth.store';
import { useNavigate } from 'react-router-dom';
import { isAxiosError } from 'axios';
import { axiosSetError, httpErrorToast } from '../../helpers/ui.helper';
import moment from 'moment';
import { useQuery } from '../../hooks/useQuery.hook';

export function GoogleLogin() {


    return (
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ""}>
            <GoogleLoginButton />
        </GoogleOAuthProvider>
    );
}

function GoogleLoginButtonComponent({
    setUser
}: any) {

    const query = useQuery();
    const referralCode = query.get("ref");

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();

    const signIn = useGoogleLogin({
        onSuccess: async (response) => {
            setIsLoading(true);
            // get access token
            const { access_token } = response as any;
            // login using this access token
            try {
                const response = await loginWithGoogle(access_token, referralCode ?? "");
                if (response.message) {
                    // all correct, start session
                    toast.success(response.message);
                    const userObject = {
                        ...response.user,
                        token: (response as any).token,
                    };
                    setUser(userObject);
                    localStorage.setItem('fgc.user', JSON.stringify(userObject));
                    localStorage.setItem('fgc.userSession', JSON.stringify({ session: 'active', lastActive: moment() }));
                    navigate('/dashboard')
                }
            } catch (error: any) {
                setIsLoading(false);
                httpErrorToast(error);
                console.log("Google login error", error);
                // axiosSetError(error);
            }
        },
        onError: (errorResponse) => {
            httpErrorToast(errorResponse);
            console.log("Google error response", errorResponse);
        },

    });

    return (
        <a onClick={e => {
            e.preventDefault();
            setIsLoading(true);
            signIn();
        }} className="btn btn-outline btn-dark btn-google btn-block"><em className="fab fa-google" /><span>Google</span></a>
    );
}

const dtp = {
    setUser: createSession
}

const GoogleLoginButton = connect(() => ({}), dtp)(GoogleLoginButtonComponent);