import React, { useEffect, useState } from "react";
import { StoreState } from "../../types/store-state.type";
import { updateSession } from "../../store/auth.store";
import { connect } from "react-redux";
import { FormInput } from "../../components/atoms/form-input.atom";
import moment from "moment/moment";
import { FormSelect } from "../../components/atoms/form-select.atom";
import { FormCheckbox } from "../../components/atoms/form-checkbox.atom";
import { Spinner } from "../../components/spinner";
import { handleError, httpPost } from "../../services/http.service";
import axios from "axios";
import { toast } from "react-toastify";
import { FormErrorList } from "../../components/atoms/form-error-list.atom";
import './kyc-form.scss';
import { getUserTokenFromLS } from "../../helpers/storage.helper";
import { useNavigate } from "react-router-dom";
import {
    City,
    Country,
    getCitiesList, getCountriesList,
    getPhoneCodeList,
    getStatesList, PhoneCode, State
} from "../../services/country-state-city.service";
import { ImageCapture } from "../../components/image-capture";

function KYCFormComponent({
    // state
    user,
    // dispatch
    updateUser,
}: any) {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [emailAddress, setEmailAddress] = useState('');
    const [countryCode, setCountryCode] = useState("+91");
    const [countryCodeList, setCountryCodeList] = useState<PhoneCode[]>([]);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [telegramUsername, setTelegramUsername] = useState<string>('');
    const [address, setAddress] = useState<any>({});
    const [documentType, setDocumentType] = useState<any>('');
    const [payingWalletType, setPayingWalletType] = useState<any>('ethereum');
    const [payingWalletAddress, setPayingWalletAddress] = useState<string>('0xde0b295669a9fd93d5f28d9ec85e40f4cb697bae');
    const [acceptedTocAndPrivacyPolicy, setAcceptedTocAndPrivacyPolicy] = useState<boolean>(false);
    const [userHasConsented, setUserHasConsented] = useState<boolean>(false);
    const [errors, setErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUploadingDocuments, setIsUploadingDocuments] = useState<boolean>(false);
    const [formData, setFormData] = useState(new FormData());
    const [webCamImage, setWebCamImage] = useState();
    const navigate = useNavigate();

    const [countriesList, setCountriesList] = useState<Country[]>([]);
    const [statesList, setStatesList] = useState<State[]>([]);
    const [citiesList, setCitiesList] = useState<City[]>([]);

    useEffect(() => {
        getCountriesList().then(countries => {
            setCountriesList(countries);
        });
        getPhoneCodeList().then(phoneCodeList => {
            setCountryCodeList(phoneCodeList);
        });
    }, []);

    const updateAddress = (e: any, field: string) => {
        const obj = { ...address, ...{ [field]: e } }
        setAddress(obj);
    }

    const handleNationalityChange = (e: string) => {
        updateAddress(e, 'nationality');
        getStatesList(e).then(states => {
            setStatesList(states);
        })
    }

    const handleStateChange = (e: string) => {
        updateAddress(e, 'state');
        getCitiesList(address.nationality, e).then(states => {
            setCitiesList(states);
        })
    }

    const submitForm = async () => {
        setErrors({});
        const payload = {
            firstName,
            lastName,
            emailAddress,
            countryCode,
            phoneNumber,
            dateOfBirth,
            telegramUsername,
            address,
            documentType,
            payingWalletType,
            payingWalletAddress,
            acceptedTocAndPrivacyPolicy,
            userHasConsented,
        };
        if (payload.documentType !== 'driver-license') {
            if ([...formData].length === 1) {
                setErrors({ documentType: ['Please attach the documents.'] })
                return
            }
        } else {
            if ([...formData].length === 0) {
                setErrors({ documentType: ['Please attach the documents.'] })
                return
            }
        }
        if (!payload.acceptedTocAndPrivacyPolicy) {
            setErrors({ acceptedTocAndPrivacyPolicy: ['Please accept terms and conditions'] })
            return
        }
        if (!payload.userHasConsented) {
            setErrors({ userHasConsented: ['Please check consent.'] })
            return
        }
        if (webCamImage === undefined) {
            setErrors({ webCamImage: ['Please submit your picture from webcam.'] })
            return
        }

        setIsLoading(true);
        try {
            let response: any = await httpPost('my/update-kyc-details', payload);
            if (response.message) {
                toast.success(response.message);
                setIsUploadingDocuments(true);
                updateUser({ ...user, kycStatus: response.kycStatus });
                const documentResp = await uploadDocuments();
                const webcamImageResp = await uploadWebcamImage();
                navigate('/kyc-thank-you');
                location.reload();
            }
            console.log(response);
        } catch (error) {
            const err = handleError(error);
            setErrors(redefineErrors(err.data));
            setIsLoading(false);
        }
    }

    const redefineErrors = (data: any) => {
        for (let key in data) {
            if (key === 'address.line1') {
                data['line1'] = ['The address line 1 field is required.'];
                delete data['address.line1']
            }
            if (key === 'address.nationality') {
                data['nationality'] = ['The nationality field is required.'];
                delete data['address.nationality']
            }
            if (key === 'address.state') {
                data['state'] = ['The state field is required.'];
                delete data['address.state']
            }
            if (key === 'address.city') {
                data['city'] = ['The city field is required.'];
                delete data['address.city']
            }
            if (key === 'address.zipCode') {
                data['zipCode'] = ['The zipCode field is required.'];
                delete data['address.zipCode']
            }
            if (key === 'documentType') {
                data['documentType'] = ['The Documents are required.'];
                delete data['address.zipCode']
            }
        }
        return data
    }

    const handleFileUpload = (e: any, documentType: string, side = 'front') => {
        try {
            let file = e.target.files[0];
            formData.set('kycDocument_' + side, file);
            setFormData(formData);
            setDocumentType(documentType);
        } catch (e) {
            toast.error("Please try uploading the document again.");
        }
    }

    const uploadDocuments = async () => {
        return new Promise(async (resolve, reject) => {
            const url = process.env.REACT_APP_API_ENDPOINT + 'my/update-kyc-document'
            try {
                const headers = {
                    Authorization: "Bearer " + getUserTokenFromLS(),
                }
                let response = await axios.post(url, formData, { headers })
                if (response.data.message) {
                    // toast.success(response.data.message);
                    console.log(response);
                    resolve(true);
                }
                return;
            } catch (error) {
                const err = handleError(error);
                setErrors(err.data);
                setIsLoading(false);
                reject(true)
            }
        })
    }

    const uploadWebcamImage = async () => {
        return new Promise(async (resolve, reject) => {
            const url = process.env.REACT_APP_API_ENDPOINT + 'my/update-kyc-picture'
            try {
                const headers = {
                    Authorization: "Bearer " + getUserTokenFromLS(),
                }
                let formData = new FormData();
                formData.set('kycPicture', webCamImage as any, "webcam_image.jpg");
                let response = await axios.post(url, formData, { headers })
                if (response.data.message) {
                    // toast.success(response.data.message);
                    console.log(response);
                    resolve(true);
                }
            } catch (error) {
                const err = handleError(error);
                setErrors(err.data);
                setIsLoading(false);
                reject(true)
            }
        });
    }

    return (
        <div className="ICOKYCFormPage">
            <div className="page-header page-header-kyc">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-xl-7 text-center">
                            <h2 className="page-title text-white">Verify Your Identity</h2>
                            <p className="large text-white">We do not share your personal information</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-user">
                <div className="page-content">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-10 col-xl-9">
                                <div className="kyc-form-steps card mx-lg-4">
                                    <div className="form-step form-step1">
                                        <div className="form-step-head card-innr">
                                            <div className="step-head">
                                                <div className="step-number">01</div>
                                                <div className="step-head-text">
                                                    <h4>Personal Details</h4>
                                                    {/* <p>Your simple personal information required for identification</p> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* .step-head */}
                                        <div className="form-step-fields card-innr">
                                            <div className="note note-plane note-light-alt note-md pdb-1x">
                                                <em className="fas fa-info-circle" />
                                                <p>Kindly ensure accurate typing as the details entered cannot be modified after form submission.</p>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-item input-with-label">
                                                        <label className="input-item-label">First Name <span
                                                            className="text-danger">*</span></label>
                                                        <FormInput onChange={setFirstName} value={firstName}
                                                            errors={errors?.firstName} />
                                                    </div>
                                                    {/* .input-item */}
                                                </div>
                                                {/* .col */}
                                                <div className="col-md-6">
                                                    <div className="input-item input-with-label">
                                                        <label className="input-item-label">Last Name <span
                                                            className="text-danger">*</span></label>
                                                        <FormInput onChange={setLastName} value={lastName}
                                                            errors={errors?.lastName} />
                                                    </div>
                                                    {/* .input-item */}
                                                </div>
                                                {/* .col */}
                                                <div className="col-md-6">
                                                    <div className="input-item input-with-label">
                                                        <label className="input-item-label">Email Address <span
                                                            className="text-danger">*</span></label>
                                                        <FormInput onChange={setEmailAddress} value={emailAddress}
                                                            errors={errors?.emailAddress} />
                                                    </div>
                                                    {/* .input-item */}
                                                </div>
                                                {/* .col */}
                                                <div className="col-md-6">
                                                    <div className="input-item input-with-label">
                                                        <label className="input-item-label">Phone Number <span
                                                            className="text-danger">*</span></label>
                                                        {/* <FormInput onChange={setPhoneNumber} value={phoneNumber}
                                                            errors={errors?.phoneNumber} /> */}
                                                        <div className="mobile-with-Country-code">
                                                            <FormSelect onChange={setCountryCode} options={countryCodeList} value={countryCode}
                                                                errors={errors?.countryCode} />
                                                            <FormInput placeholder="Your Mobile" value={phoneNumber}
                                                                onChange={setPhoneNumber} errors={errors?.phoneNumber} />
                                                        </div>
                                                    </div>
                                                    {/* .input-item */}
                                                </div>
                                                {/* .col */}
                                                <div className="col-md-6">
                                                    <div className="input-item input-with-label">
                                                        <label className="input-item-label">Date of Birth <span
                                                            className="text-danger">*</span></label>
                                                        <input type='date' max={moment().format("YYYY-MM-DD")}
                                                            value={moment(dateOfBirth).format("YYYY-MM-DD")}
                                                            onChange={(e) => setDateOfBirth(moment(e.target.value).format("YYYY-MM-DD"))}
                                                            className="input-bordered date-picker" />
                                                        <FormErrorList errors={errors?.dateOfBirth} />
                                                    </div>
                                                    {/* .input-item */}
                                                </div>
                                                {/* <div className="col-md-6">
                                                    <div className="input-item input-with-label">
                                                        <label className="input-item-label">Telegram Username</label>
                                                        <FormInput onChange={setTelegramUsername}
                                                            value={telegramUsername}
                                                            errors={errors?.telegramUsername} />
                                                    </div>
                                                </div> */}
                                            </div>
                                            {/* .row */}
                                            <h4 className="text-secondary mgt-0-5x">Your Address</h4>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="input-item input-with-label">
                                                        <label className="input-item-label">Address Line 1 <span
                                                            className="text-danger">*</span></label>
                                                        <FormInput
                                                            onChange={(e: HTMLInputElement) => updateAddress(e, 'line1')}
                                                            value={address?.line1} errors={errors.line1} />
                                                    </div>
                                                    {/* .input-item */}
                                                </div>
                                                {/* .col */}
                                                <div className="col-md-6">
                                                    <div className="input-item input-with-label">
                                                        <label className="input-item-label">Address Line 2</label>
                                                        <FormInput
                                                            onChange={(e: HTMLInputElement) => updateAddress(e, 'line2')}
                                                            value={address?.line2} errors={errors['address.line2']} />
                                                    </div>
                                                    {/* .input-item */}
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-item input-with-label">
                                                        <label htmlFor="nationality" className="input-item-label">
                                                            Country of Residence
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <FormSelect options={countriesList} onChange={handleNationalityChange}
                                                            value={address.nationality} errors={errors.nationality} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-item input-with-label">
                                                        <label htmlFor="nationality" className="input-item-label">
                                                            State
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <FormInput onChange={(e: HTMLInputElement) => updateAddress(e, 'state')}
                                                            value={address.state} errors={errors.state} />
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="input-item input-with-label">
                                                        <label htmlFor="nationality" className="input-item-label">
                                                            City
                                                            <span className="text-danger">*</span>
                                                        </label>
                                                        <FormInput onChange={(e: HTMLInputElement) => updateAddress(e, 'city')}
                                                            value={address.city} errors={errors.city} />
                                                    </div>
                                                </div>
                                                {/* .col */}
                                                <div className="col-md-6">
                                                    <div className="input-item input-with-label">
                                                        <label className="input-item-label">Postcode / Zip code<span
                                                            className="text-danger">*</span></label>
                                                        <FormInput
                                                            onChange={(e: HTMLInputElement) => updateAddress(e, 'zipCode')}
                                                            value={address?.zipCode}
                                                            errors={errors.zipCode} />
                                                    </div>
                                                    {/* .input-item */}
                                                </div>
                                                {/* .col */}
                                            </div>
                                            {/* .row */}
                                        </div>
                                        {/* .step-fields */}
                                    </div>
                                    <div className="form-step form-step2">
                                        <div className="form-step-head card-innr">
                                            <div className="step-head">
                                                <div className="step-number">02</div>
                                                <div className="step-head-text">
                                                    <h4>Document Upload</h4>
                                                    <p>To verify your identity, please upload your documents</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* .step-head */}
                                        <div className="form-step-fields card-innr">
                                            <div className="note note-plane note-light-alt note-md pdb-0-5x">
                                                <em className="fas fa-info-circle" />
                                                <p>In order to complete, please upload any of the following personal
                                                    document.</p>
                                            </div>
                                            <div className="gaps-2x" />
                                            <ul className="nav nav-tabs nav-tabs-bordered row flex-wrap guttar-20px"
                                                role="tablist">
                                                <li className="nav-item flex-grow-0">
                                                    <a className="nav-link d-flex align-items-center active"
                                                        data-toggle="tab" href="#passport">
                                                        <div className="nav-tabs-icon">
                                                            <img src="images/icon-passport.png" alt="icon" />
                                                            <img src="images/icon-passport-color.png" alt="icon" />
                                                        </div>
                                                        <span>Passport</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item flex-grow-0">
                                                    <a className="nav-link d-flex align-items-center" data-toggle="tab"
                                                        href="#national-card">
                                                        <div className="nav-tabs-icon">
                                                            <img src="images/icon-national-id.png" alt="icon" />
                                                            <img src="images/icon-national-id-color.png" alt="icon" />
                                                        </div>
                                                        <span>National ID Card</span>
                                                    </a>
                                                </li>
                                                <li className="nav-item flex-grow-0">
                                                    <a className="nav-link d-flex align-items-center" data-toggle="tab"
                                                        href="#driver-licence">
                                                        <div className="nav-tabs-icon">
                                                            <img src="images/icon-licence.png" alt="icon" />
                                                            <img src="images/icon-licence-color.png" alt="icon" />
                                                        </div>
                                                        <span>Driver’s License</span>
                                                    </a>
                                                </li>
                                            </ul>
                                            {/* .nav-tabs-line */}
                                            <div className="tab-content" id="myTabContent">
                                                <div className="tab-pane fade show active" id="passport">
                                                    <h5 className="text-secondary font-bold">
                                                        To avoid delays during verification, please ensure the following:
                                                    </h5>
                                                    <ul className="list-check">
                                                        <li>Uploaded document has not expired.</li>
                                                        <li>Uploaded document is clearly visible.</li>
                                                        <li>Ensure there is no light glare on the document.</li>
                                                    </ul>
                                                    <div className="gaps-2x" />
                                                    <h5 className="font-mid">Upload Your Passport Front Side <span className="text-danger">*</span></h5>
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-8">
                                                            <div className="upload-box">
                                                                <div className="upload-zone dropzone dz-clickable">
                                                                    <div className="dz-message" data-dz-message>
                                                                        <span className="dz-message-text">Drag and drop file</span>
                                                                        <span className="dz-message-or">or</span>
                                                                        <input type='file' className="btn btn-primary"
                                                                            onChange={(e) => handleFileUpload(e, 'passport', 'front')} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4 d-none d-sm-block">
                                                            <div className="mx-md-4">
                                                                <img src="images/vector-passport.png" alt="vector" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="gaps-3x" />
                                                    <h5 className="font-mid">Upload Your Passport Back Side <span className="text-danger">*</span></h5>
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-8">
                                                            <div className="upload-box">
                                                                <div className="upload-zone dropzone dz-clickable">
                                                                    <div className="dz-message" data-dz-message>
                                                                        <span className="dz-message-text">Drag and drop file</span>
                                                                        <span className="dz-message-or">or</span>
                                                                        <input type='file' className="btn btn-primary"
                                                                            onChange={(e) => handleFileUpload(e, 'passport', 'back')} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4 d-none d-sm-block">
                                                            <div className="mx-md-4">
                                                                <img src="images/vector-passport.png" alt="vector" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* .tab-pane */}
                                                <div className="tab-pane fade" id="national-card">
                                                    <h5 className="text-secondary font-bold">
                                                        To avoid delays during verification, please ensure the following:
                                                    </h5>
                                                    <ul className="list-check">
                                                        <li>Uploaded document has not expired.</li>
                                                        <li>Uploaded document is clearly visible.</li>
                                                        <li>Ensure there is no light glare on the document.</li>
                                                    </ul>
                                                    <div className="gaps-2x" />
                                                    <h5 className="font-mid">
                                                        Upload Here Your National id Front Side
                                                        <span className="text-danger">*</span>
                                                    </h5>
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-8">
                                                            <div className="upload-box">
                                                                <div className="upload-zone dropzone dz-clickable">
                                                                    <div className="dz-message" data-dz-message>
                                                                        <span className="dz-message-text">Drag and drop file</span>
                                                                        <span className="dz-message-or">or</span>
                                                                        <input type='file' className="btn btn-primary"
                                                                            onChange={(e) => handleFileUpload(e, 'nationalCard', 'front')} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4 d-none d-sm-block">
                                                            <div className="mx-md-4">
                                                                <img src="images/vector-id-front.png" alt="vector" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="gaps-3x" />
                                                    <h5 className="font-mid">Upload Here Your National id Back Side <span className="text-danger">*</span></h5>
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-8">
                                                            <div className="upload-box">
                                                                <div className="upload-zone dropzone dz-clickable">
                                                                    <div className="dz-message" data-dz-message>
                                                                        <span className="dz-message-text">Drag and drop file</span>
                                                                        <span className="dz-message-or">or</span>
                                                                        <input type='file' className="btn btn-primary"
                                                                            onChange={(e) => handleFileUpload(e, 'national-card', 'back')} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4 d-none d-sm-block">
                                                            <div className="mx-md-4">
                                                                <img src="images/vector-id-back.png" alt="vector" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="driver-licence">
                                                    <h5 className="text-secondary font-bold">
                                                        To avoid delays during verification, please ensure the following:
                                                    </h5>
                                                    <ul className="list-check">
                                                        <li>Uploaded document has not expired.</li>
                                                        <li>Uploaded document is clearly visible.</li>
                                                        <li>Ensure there is no light glare on the document.</li>
                                                    </ul>
                                                    <div className="gaps-2x" />
                                                    <h5 className="font-mid">Upload Your Driving License <span className="text-danger">*</span></h5>
                                                    <div className="row align-items-center">
                                                        <div className="col-sm-8">
                                                            <div className="upload-box">
                                                                <div className="upload-zone dropzone dz-clickable">
                                                                    <div className="dz-message" data-dz-message>
                                                                        <span className="dz-message-text">Drag and drop file</span>
                                                                        <span className="dz-message-or">or</span>
                                                                        <input type='file' className="btn btn-primary"
                                                                            onChange={(e) => handleFileUpload(e, 'driver-license', 'front')} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-4 d-none d-sm-block">
                                                            <div className="mx-md-4">
                                                                <img src="images/vector-licence.png" alt="vector" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <FormErrorList errors={errors?.documentType} />
                                        </div>
                                        {/* .step-fields */}
                                    </div>
                                    <div className="form-step form-step3">
                                        <div className="form-step-head card-innr">
                                            <div className="step-head">
                                                <div className="step-number">03</div>
                                                <div className="step-head-text">
                                                    <h4>Your Picture</h4>
                                                    <p>Submit your picture from your webcam</p>
                                                </div>
                                            </div>
                                        </div>
                                        {/* .step-head */}
                                        <div className="form-step-fields card-innr">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div>
                                                        <ImageCapture setImage={setWebCamImage} />
                                                    </div>
                                                    <div className="mt-2">
                                                        <FormErrorList errors={errors?.webCamImage} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* .input-item */}
                                        </div>
                                        {/* .step-fields */}
                                    </div>
                                    <div className="form-step form-step-final">
                                        <div className="form-step-fields card-innr">
                                            <div className="input-item">
                                                <FormCheckbox checked={acceptedTocAndPrivacyPolicy} label={<>
                                                    I have read the Terms & Conditions and Privacy Policy</>}
                                                    setChecked={setAcceptedTocAndPrivacyPolicy} />
                                                <FormErrorList errors={errors?.acceptedTocAndPrivacyPolicy} />
                                            </div>
                                            <div className="input-item">
                                                <FormCheckbox checked={userHasConsented} label={<>
                                                    All the personal information I have entered is correct</>}
                                                    setChecked={setUserHasConsented} />
                                                <FormErrorList errors={errors?.userHasConsented} />
                                            </div>
                                            <div className="gaps-1x" />
                                            <button className="btn btn-primary" onClick={submitForm}>
                                                {isLoading ? <Spinner white /> : 'Submit for verification'}
                                            </button>
                                            <div className="uploading-div">
                                                {isUploadingDocuments ? <>Uploading Documents&nbsp;&nbsp;&nbsp;
                                                    <Spinner /></> : ''}
                                            </div>
                                        </div>
                                        {/* .step-fields */}
                                    </div>
                                </div>
                                {/* .card */}
                            </div>
                        </div>
                    </div>
                    {/* .container */}
                </div>
                {/* .page-content */}
            </div>
        </div>

    )
}

const stp = (state: StoreState) => ({
    user: state.auth
});
const dtp = {
    updateUser: updateSession,
}

export const KYCForm = connect(stp, dtp)(KYCFormComponent);
