import CheckoutLinkResponse from "../types/responses/checkout-link-response.type";
import { httpGet, httpPost } from "./http.service";


const getCheckoutLink = async (selectedAmount: number): Promise<CheckoutLinkResponse> => {
    return httpPost<CheckoutLinkResponse>("checkout/init", {
        selectedAmount
    });
}

export {
    getCheckoutLink
};
