import React from 'react';
import { Spinner } from '../spinner';

interface DataTableShowLoaderProps {
    columnCount: number;
    rowCount: number;
}

export function DataTableShowLoader({
    columnCount = 0,
    rowCount = 0,
}: DataTableShowLoaderProps) {
    return (
        <tr>
            <td rowSpan={rowCount} colSpan={columnCount}>
                <span className="table-loader" style={{
                    height: ((73.5 * rowCount) + 19) + "px"
                }}><Spinner /></span>
            </td>
        </tr>
    )
}