import React, { MouseEventHandler } from 'react';
import { KycDetail } from '../../types/generic/kyc-details.type';
import cx from "classnames";
import { Link } from 'react-router-dom';
import { formatAddress, getDownloadPathFromStorage, getStatusClass, humanReadableDate, readableDate, readableString } from '../../helpers/ui.helper';

interface KycDetailsComponentProps {
    isCard?: boolean;
    kyc: KycDetail;
    onBack?: MouseEventHandler<HTMLAnchorElement>;
    threeDotMenu?: any;
}

export function KycDetailsComponent({
    isCard,
    kyc,
    onBack,
    threeDotMenu,
}: KycDetailsComponentProps) {
    return (
        <div className={cx("content-area", {
            "card": isCard
        })}>
            <div className={cx({
                "card-innr": isCard
            })}>
                <div>
                    <div className="card-head d-flex justify-content-between align-items-center">
                        <h4 className="card-title mb-0">KYC Details</h4>
                        <div className="d-flex align-items-center guttar-20px">
                            {
                                onBack ? (
                                    <>
                                        <a href="#" onClick={onBack} className="btn btn-sm btn-auto btn-primary d-sm-block d-none"><em className="fas fa-arrow-left mr-3" title="" />Back</a>
                                        <a href="#" onClick={onBack} className="btn btn-icon btn-sm btn-primary d-sm-none"><em className="fas fa-arrow-left" title="" /></a>
                                    </>
                                ) : <></>
                            }
                            {threeDotMenu}
                        </div>
                    </div>
                    <div className="gaps-1-5x" />
                    <div className="data-details d-md-flex flex-wrap align-items-center justify-content-between">
                        <div className="fake-class">
                            <span className="data-details-title">Submited By</span>
                            <span className="data-details-info">{kyc.user?.id ?? "NA"}</span>
                        </div>
                        <div className="fake-class">
                            <span className="data-details-title">Submited On</span>
                            <span className="data-details-info">{readableDate(kyc.createdAt)}</span>
                        </div>
                        {
                            kyc.status === "approved" ? (
                                <>
                                    <div className="fake-class">
                                        <span className="data-details-title">Checked By</span>
                                        <span className="data-details-info">Admin</span>
                                    </div>
                                    <div className="fake-class">
                                        <span className="data-details-title">Checked On</span>
                                        <span className="data-details-info">2018-08-24 10:12PM</span>
                                    </div>
                                </>
                            ) : <></>
                        }
                        <div className="fake-class">
                            <span className={`badge badge-md badge-block badge-${getStatusClass(kyc.status)} ucap`}>{kyc.status}</span>
                        </div>
                        {
                            kyc.adminNote ? (
                                <>
                                    <div className="gaps-2x w-100 d-none d-md-block" />
                                    <div className="w-100">
                                        <span className="data-details-title">Admin Note</span>
                                        <span className="data-details-info">{kyc.adminNote}</span>
                                    </div>
                                </>
                            ) : <></>
                        }
                    </div>
                    <div className="gaps-3x" />
                    <h6 className="card-sub-title">Personal Information</h6>
                    <ul className="data-details-list">
                        <li>
                            <div className="data-details-head">First Name</div>
                            <div className="data-details-des">{kyc.firstName}</div>
                        </li>{/* li */}
                        <li>
                            <div className="data-details-head">Last Name</div>
                            <div className="data-details-des">{kyc.lastName}</div>
                        </li>{/* li */}
                        <li>
                            <div className="data-details-head">Email Address</div>
                            <div className="data-details-des">{kyc.emailAddress}</div>
                        </li>{/* li */}
                        <li>
                            <div className="data-details-head">Phone Number</div>
                            <div className="data-details-des">{kyc.phoneNumber}</div>
                        </li>{/* li */}
                        <li>
                            <div className="data-details-head">Date of Birth</div>
                            <div className="data-details-des">{humanReadableDate(kyc.dateOfBirth ?? "", true)}</div>
                        </li>{/* li */}
                        <li>
                            <div className="data-details-head">Full Address</div>
                            <div className="data-details-des">
                                {/* Address Line 1, Address Line 2, City Name, Zipcode */}
                                {formatAddress(kyc.address)}
                                .</div>
                        </li>{/* li */}
                        <li>
                            <div className="data-details-head">Country of Residence</div>
                            <div className="data-details-des">{kyc.address.nationality}</div>
                        </li>{/* li */}
                        {
                            kyc.telegramUsername ? (
                                <li>
                                    <div className="data-details-head">Telegram Username</div>
                                    <div className="data-details-des"><span>@{kyc.telegramUsername} <em className="far fa-paper-plane" /></span></div>
                                </li>
                            ) : <></>
                        }{/* li */}
                    </ul>
                    <div className="gaps-3x" />
                    <h6 className="card-sub-title">Uploaded Document</h6>
                    <ul className="data-details-list">
                        <li>
                            <div className="data-details-head">{readableString(kyc.documentType)}</div>
                            <ul className="data-details-docs">
                                <li>
                                    <img src={getDownloadPathFromStorage(kyc.documentPath[0])} alt="" width="54px" /> &nbsp;
                                    <a title="download" href={getDownloadPathFromStorage(kyc.documentPath[0])}><em className="ti ti-import" /></a>
                                </li>
                                {kyc.documentType !== 'driver-license' ?
                                    <li>
                                        <img src={getDownloadPathFromStorage(kyc.documentPath[1])} alt="" width="54px" /> &nbsp;
                                        <a title="download" href={getDownloadPathFromStorage(kyc.documentPath[1])}><em className="ti ti-import" /></a>
                                    </li> : <></>
                                }
                            </ul>
                        </li>{/* li */}
                    </ul>
                </div>
            </div>
        </div>
    )
}