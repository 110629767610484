import { User } from "../types/generic/user.type";

function isKYCPending(user: User) {
    return user.kycStatus === "pending";
}

function isKYCApproved(user: User) {
    return user.kycStatus === "approved";
}

export {
    isKYCPending,
    isKYCApproved,
}
