import React, { ReactNode } from 'react';
import { FormErrorList } from './form-error-list.atom';

type FormCheckboxProps = {
    checked: boolean,
    setChecked: Function,
    label: ReactNode,
    id?: string,
    errors?: string[]
}

export function FormCheckbox({
    checked = false,
    setChecked,
    label = "",
    id = "",
    errors,
}: FormCheckboxProps) {
    return (
        <div className="input-item text-left" onClick={() => {
            setChecked(!checked)
        }}>
            <input
                className="input-checkbox input-checkbox-md"
                id={id}
                type="checkbox"
                checked={checked}
                onClick={e => e.preventDefault()} />
            <label htmlFor={id}>{label}</label>
            <FormErrorList errors={errors} />
        </div>
    )
}