import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { verifyKyc } from "../../services/kyc.service";
import { createSession } from "../../store/auth.store";
import { StoreState } from "../../types/store-state.type";

function KycThankYouPageComponent({
    user,
    setUser
}: any) {

    const page = window.location.pathname.endsWith("success") ? "Success" : "Failure";

    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState<boolean>();
    const [isCheckComplete, setIsCheckComplete] = useState(false);
    const [reason, setReason] = useState<string>();

    useEffect(() => {
        if (page === "Success") {
            // wait 5 sec
            setTimeout(() => {
                verifyKycDetails();
            }, 5000);
        }
    }, [page]);

    const verifyKycDetails = async () => {
        setIsLoading(true);
        const response = await verifyKyc();
        setIsLoading(false);
        setResult(response.kycStatus === "approved");
        if (response.kycStatus === "approved") {
            // update the user
            toast.success(response.message);
            const userObject = {
                ...user,
                kycStatus: response.kycStatus,
            };
            setUser(userObject);
            localStorage.setItem('fgc.user', JSON.stringify(userObject));
        } else {
            if (response.kycStatus !== "verifying") {
                toast.error("KYC could not be completed");
            }
            console.log("Something went wrong while verifying kyc", response);
        }
        setReason(response.state);
        setIsCheckComplete(true);
    }



    return (
        <>
            <div className="page-header page-header-kyc">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-xl-7 text-center">
                            <h2 className="page-title text-white">Begin your ID-verification</h2>
                            <p className="large text-white">
                                Verify your identity to participate in tokensale.
                            </p>
                        </div>
                    </div>
                </div>
                {/* .container */}
            </div>
            {/* .page-header */}
            <div className="page-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-9">
                            <div className="kyc-status card mx-lg-4">
                                <div className="card-innr">
                                    <div className={`status status-${page === "Success" ? "thank" : "canceled"} px-md-5`}>
                                        <div className="status-icon">
                                            <em className={`ti ti-${page === "Success" ? "check" : "close"}`} />
                                        </div>
                                        <span className="status-text large text-dark">
                                            You have completed the process of KYC
                                        </span>
                                        <p className="px-md-5">
                                            {
                                                isCheckComplete ? (
                                                    isLoading ? (
                                                        <>Please wait while we verify your details...</>
                                                    ) : (
                                                        <>{
                                                            result ? "KYC Successful" : (
                                                                reason
                                                                    ? <>The current state is {reason}.<br /> If you have any queries please consult admin.</>
                                                                    : "Something went wrong, please contact Admin!"
                                                            )
                                                        }</>
                                                    )
                                                ) : <>Checking for state...</>
                                            }
                                        </p>
                                        <Link to='/profile'>
                                            <button className="btn btn-primary">
                                                Back to Profile
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* .card */}
                            <div className="card">
                                <div className="card-innr">
                                    <p className="text-light text-center mgmt-1x">
                                        If you have any question, please contact our support team{" "}
                                        <a href="#">info@footballgoalcoin.com</a>.
                                    </p>
                                </div>
                            </div>
                            <div className="gaps-1x" />
                            <div className="gaps-3x d-none d-sm-block" />
                        </div>
                    </div>
                </div>
                {/* .container */}
            </div>
            {/* .page-content */}
        </>

    )
}

const dtp = {
    setUser: createSession
}

export const KycSuccessPage = connect((state: StoreState): any => ({
    user: state.auth,
}), dtp)(KycThankYouPageComponent)
