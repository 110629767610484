import React from 'react';
import { Link } from 'react-router-dom';

export function CheckoutSuccessPage() {
    return (
        <div className="page-user">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-xl-8">
                        <div className="card content-area">
                            <div className="card-innr">
                                <div className="status status-thank px-md-5">
                                    <div className="status-icon">
                                        <em className="ti ti-check" />
                                    </div>
                                    <span className="status-text large text-dark">The transaction was successful</span>
                                    <p className="px-md-5">You can check out the status by visiting <Link to="/transactions">transaction</Link> page, it should reflect soon.</p>
                                </div>
                            </div>
                        </div>{/* .card */}
                        <div className="gaps-1x" />
                        <div className="gaps-3x d-none d-sm-block" />
                    </div>
                </div>
            </div>{/* .container */}
        </div>
    )
}
