import { getExchangeRate } from "../../services/crypto.service";
import { ExchangeRate } from "../../types/generic/exchange-rate.type";

export const SET_EXCHANGE_RATE = "SET_EXCHANGE_RATE";

export function setExchangeRateAction(exchangeRate: ExchangeRate) {
    return {
        type: SET_EXCHANGE_RATE,
        exchangeRate,
    };
}

export function getExchangeRateFromServer() {
    return async (dispatch: any) => {
        const exchangeRate = await getExchangeRate();
        dispatch(setExchangeRateAction(exchangeRate));
    }
}

export function exchangeRateReducer(state = {
    bitcoin: {
        usd: 0
    },
    ethereum: {
        usd: 0
    }
}, action: any) {
    switch (action.type) {
        case SET_EXCHANGE_RATE:
            return action.exchangeRate;
        default:
            return state;
    }
} 