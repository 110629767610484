import { useRef, useState } from "react";
import { Spinner } from "./spinner";

type BannerProps = {
    images: string[]
}

export function Banner({ images = [] }: BannerProps) {

    console.log('images', images)

    const [loading, setLoading] = useState(true);

    const counter = useRef(0);

    const imageLoaded = () => {
        counter.current += 1;
        if (counter.current >= images.length) {
            setLoading(false);
        }
    }

    return (
        <>
            <div className="row d-none d-lg-flex">
                {loading ? <div className="col-md-12"><Spinner></Spinner></div> : <></>}
                {images.map(url => {
                    return (
                        <div className="col-md-4" style={{ display: loading ? "none" : "block" }}>
                            <div className="text-center">
                                <img height="230px" key={url}
                                    src={url} alt="" onLoad={imageLoaded} />
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="gaps-3x d-none d-lg-block" />
        </>
    )
}