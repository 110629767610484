import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface TopbarIconProps {
    to: string,
    show: boolean,
    iconClass: "dashboard" | "coins" | "distribution" | "transactions" | "user" | "wallet" | "globe-outline",
    target?: '_self' | '_blank'
    children: any,
    wrapperClass?: string,
}

export function TopbarIcon({
    to,
    show = true,
    target = '_self',
    iconClass,
    children,
    wrapperClass = '',
}: TopbarIconProps) {

    const location = useLocation();

    let isActive = location.pathname === to;
    if (location.pathname === '/dashboard' && to === '/') {
        isActive = true;
    }
    const className = (isActive ? 'active' : '') + " " + wrapperClass;

    return show ? (
        <li className={className}>
            <Link to={to} target={target}>
                <em className={"ikon ikon-" + iconClass} />
                {children}
            </Link>
        </li>
    ) : <></>;
}
