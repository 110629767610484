function getJQuery() {
    return ((window as any).$);
}

function initTooltips($: any) {
    const $tooltip = $('[data-toggle="tooltip"]');
    if ($tooltip.length > 0) {
        $tooltip.tooltip();
    }
}

function initJQuery() {
    const $ = getJQuery();

    // init tooltips
    initTooltips($);
}

export {
    getJQuery,
    initJQuery,
}
