import React, { useState } from 'react';
import { DataTable } from '../../components/data-table';
import { readableDate } from '../../helpers/ui.helper';
import { getMyReferrals } from '../../services/auth.service';
import { UserCompact } from '../../types/generic/user-compact.type';

const columns = [
    { className: "dt-data", title: "Name" },
    { className: "dt-data", title: "KYC Status" },
    { className: "dt-data", title: "Referral Code" },
    { className: "dt-data", title: "Joined At" },
] as any;

function renderRow(referral: UserCompact, { }: any) {
    return [
        {
            className: "dt-referral-name", content: (
                <span>{referral.name}</span>
            )
        },
        {
            className: "dt-referral-kyc-status", content: (
                <span style={{ textTransform: "capitalize" }}>{referral.kycStatus}</span>
            )
        },
        {
            className: "dt-referral-code", content: (
                <span>{referral.referralCode}</span>
            )
        },
        {
            className: "dt-data", content: (
                <span>{readableDate(referral.createdAt)}</span>
            )
        },
    ];
}

export function ReferralsPage() {

    const [selectedReferral, setSelectedReferral] = useState<UserCompact>();

    const renderRowWithProps = (referral: UserCompact) => {
        return renderRow(referral, {
            // showTransactionDetails,
        })
    };

    return (
        <div className="page-user">
            <div className="page-content">
                <div className="container">
                    <div className="card content-area">
                        <div className="card-innr">
                            <div className="card-head">
                                <h4 className="card-title">My Referrals</h4>
                            </div>
                            <DataTable
                                columns={columns}
                                tableClass="user-tnx"
                                renderRow={renderRowWithProps}
                                fetcherMethod={getMyReferrals} />
                        </div>{/* .card-innr */}
                    </div>{/* .card */}
                </div>{/* .container */}
            </div>
        </div>
    );
}