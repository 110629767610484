import axios from 'axios';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Spinner } from '../../components/spinner';
import { payFromWallet } from '../../services/transaction.service';
import { setWalletDetailsAction } from '../../store/wallet-details.store';

interface PayFromWalletButtonProps {
    selectedCurrency: string;
    selectedAmount: number;
    setWalletDetails: Function;
}

function PayFromWalletButtonComponent({
    selectedCurrency,
    selectedAmount,
    setWalletDetails,
}: PayFromWalletButtonProps) {

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const onClick = async (e: any) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const { message, transaction, walletDetails } = await payFromWallet({
                selectedAmount,
                selectedCurrency,
            });
            setWalletDetails(walletDetails);
            toast.success(message);
            setIsLoading(false);
            navigate("/transactions");
        } catch (error) {
            if (axios.isAxiosError(error)) {
                toast.error(error?.response?.data?.message);
            } else {
                toast.error("Something went wrong");
            }
            setIsLoading(false);
        }
    }

    return (
        <button onClick={onClick} className="btn btn-light-alt btn-between w-100" disabled={isLoading}>
            {
                isLoading ? (
                    <Spinner white />
                ) : (
                    <>Pay from wallet <em className="ti ti-wallet" /></>
                )
            }
        </button>
    )
}

export const PayFromWalletButton = connect((state: any) => ({

}), {
    setWalletDetails: setWalletDetailsAction
})(PayFromWalletButtonComponent);
