import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import isEmail from 'validator/lib/isEmail';
import { FormButton } from '../../components/atoms/form-button.atom';
import { FormCheckbox } from '../../components/atoms/form-checkbox.atom';
import { FormInput } from '../../components/atoms/form-input.atom';
import { FormSelect } from '../../components/atoms/form-select.atom';
import { httpErrorToast } from '../../helpers/ui.helper';
import { isPasswordValid } from '../../helpers/validation.helper';
import { requestResetPasswordEmail, requestResetPasswordMobile, resetPasswordEmail, resetPasswordMobile } from '../../services/auth.service';
import { getPhoneCodeList, PhoneCode } from '../../services/country-state-city.service';

export function ForgotPasswordPage() {

    const [useMobileInstead, setUseMobileInstead] = useState(false);
    const [countryCode, setCountryCode] = useState("+91");
    const [errors, setErrors] = useState<any>({});
    const [countryCodeList, setCountryCodeList] = useState<PhoneCode[]>([]);
    const [mobile, setMobile] = useState("");
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [isOTPInputVisible, setIsOTPInputVisible] = useState(false);

    const [otp, setOtp] = useState<string>("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        getPhoneCodeList().then(phoneCodeList => {
            setCountryCodeList(phoneCodeList);
        });
    }, []);

    function validateSendOTP() {
        const errors = {} as any;
        let isValid = true;
        if (useMobileInstead) {
            if (countryCode.trim() === '') {
                errors.countryCode = ['Country code is required.']
                isValid = false
            }
            if (mobile.trim() === '') {
                errors.mobile = ['Mobile is required.']
                isValid = false
            }
        } else {
            if (email.trim() === '') {
                errors.email = ['Email is required.']
                isValid = false
            } else if (!isEmail(email)) {
                errors.email = ['Please enter a valid email.']
                isValid = false
            }
        }
        setErrors(errors)
        return isValid
    }

    const sendOTP = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();

        const isValid = validateSendOTP();
        if (!isValid) {
            return false;
        }
        setIsLoading(true);
        try {
            const response = await (
                useMobileInstead
                    ? requestResetPasswordMobile(countryCode, mobile) :
                    requestResetPasswordEmail(email)
            );
            // all correct, start session
            toast.success(response.message);
            setIsLoading(false);
            setIsOTPInputVisible(true);
        } catch (error: any) {
            httpErrorToast(error);
            if (error?.response?.data?.errors) {
                setErrors(error?.response?.data?.errors);
            }
            setIsLoading(false);
        }

    }


    const validateVerifyOTP = () => {

        let errors = {} as any;
        let isValid = true;

        if (useMobileInstead) {
            if (countryCode.trim() === '') {
                errors.countryCode = ['Country code is required.']
                isValid = false
            }
            if (mobile.trim() === '') {
                errors.mobile = ['Mobile is required.']
                isValid = false
            }
        } else {
            if (!isEmail(email)) {
                errors.email = ['Please enter a valid email.']
            }
        }

        if (password.trim() === '') {
            errors.password = ['Password is required.']
            isValid = false
        }

        if (!isPasswordValid(password)) {
            errors.password = [
                // 'Please enter a strong Password.',
                `Password should be minimum of 8 letters and with at least a symbol,
                upper and lower case letters and a number`];
            isValid = false
        }

        if (confirmPassword.trim() === '') {
            errors.confirmPassword = ['Confirm password is required.']
            isValid = false
        } else if (password !== confirmPassword) {
            errors.confirmPassword = ["Password's does not match."]
            isValid = false
        }

        setErrors(errors);
        return isValid
    }

    const verifyOTP = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        const isValid = validateVerifyOTP();
        if (!isValid) {
            return false;
        }

        // call api
        setIsLoading(true);
        try {
            const response = await (
                useMobileInstead
                    ? resetPasswordMobile(mobile, otp, password)
                    : resetPasswordEmail(email, otp, password)
            );
            toast.success(response.message);
            setIsLoading(false);
            navigate('/login');
        } catch (error: any) {
            httpErrorToast(error);
            if (error?.response?.data?.errors) {
                setErrors(error?.response?.data?.errors);
            }
            setIsLoading(false);
        }

    }

    return (
        <div className="page-ath-wrap">
            <div className="page-ath-content">
                <div className="page-ath-header">
                    <a href="./" className="page-ath-logo">
                        <img src="/images/fgc_logo_2.png" alt="logo" />
                    </a>
                </div>
                <div className="page-ath-form">
                    <h2 className="page-ath-heading">Reset password
                        <span>
                            {
                                isOTPInputVisible
                                    ? "Please input the new password and OTP received."
                                    : "If you forgot your password, well, then we’ll help you reset your password."
                            }
                        </span>
                    </h2>
                    <form action="#">
                        {
                            isOTPInputVisible ? (
                                <>
                                    <FormInput placeholder="New Password" value={password}
                                        onChange={setPassword} errors={errors.password} type="password" />
                                    <FormInput placeholder="Confirm Password" value={confirmPassword}
                                        onChange={setConfirmPassword} errors={errors.confirmPassword} type="password" />
                                    <FormInput placeholder="6 Digit OTP" value={otp} onChange={setOtp}
                                        errors={errors.otp} />
                                </>
                            ) : (
                                <>
                                    {/* Email or mobile */}
                                    {useMobileInstead ?
                                        <div className="mobile-with-Country-code">
                                            <FormSelect onChange={setCountryCode} options={countryCodeList} value={countryCode}
                                                errors={errors.countryCode} />
                                            <FormInput placeholder="Your Mobile" value={mobile}
                                                onChange={setMobile} errors={errors.mobile} />
                                        </div> :
                                        <FormInput placeholder="Your Email" value={email}
                                            onChange={setEmail} errors={errors.email} />
                                    }
                                    {/* auth switcher */}
                                    <FormCheckbox checked={useMobileInstead} label={'Sign up using mobile'}
                                        setChecked={() => setUseMobileInstead(!useMobileInstead)} />
                                </>
                            )
                        }
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                {
                                    isOTPInputVisible ? (
                                        <>
                                            <FormButton label="Reset Password" onClick={verifyOTP} isLoading={isLoading} />
                                        </>
                                    ) : (
                                        <>
                                            <FormButton label="Next" onClick={sendOTP} isLoading={isLoading} />
                                        </>
                                    )
                                }
                            </div>
                            <div>
                                {
                                    isOTPInputVisible ? (
                                        <a href="/forgot-password">Back</a>
                                    ) : (
                                        <Link to="/login">Return to login</Link>
                                    )
                                }
                            </div>
                        </div>
                        <div className="gaps-2x" />
                    </form>
                </div>
                <div className="page-ath-footer">
                    <ul className="footer-links">
                        <li><a href="https://footballgoalcoin.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
                        <li><a href="https://footballgoalcoin.com/terms-of-service/" target="_blank">Terms of Service</a></li>
                        <li>© 2023 FGC.</li>
                    </ul>
                </div>
            </div>
            <div className="page-ath-gfx">
                <div className="w-100 d-flex justify-content-center">
                    <div className="col-md-8 col-xl-5">
                        {/*<img src="images/ath-gfx.png" alt="image" />*/}
                    </div>
                </div>
            </div>
        </div>
    );
}
