/**
 * Closes the bootstrap modal
 * @param modalId 
 */
function closeModal(modalId: string) {
    ((window as any).$)('#' + modalId).modal('hide');
}

/**
 * Opens a modal
 * @param modalId 
 */
function openModal(modalId: string) {
    ((window as any).$)('#' + modalId).modal('show');
}

export {
    closeModal,
    openModal,
}