import AsyncSelect from 'react-select/async';
import { getUsersList } from '../../../services/user.service';

import './select-dropdown.scss';
import { ActionMeta } from 'react-select';
import { FormInput } from '../../../components/atoms/form-input.atom';
import { FormButton } from '../../../components/atoms/form-button.atom';
import { useEffect, useState } from 'react';
import { FormErrorList } from '../../../components/atoms/form-error-list.atom';
import { createOfflineTransaction } from '../../../services/transaction.service';
import { toast } from 'react-toastify';

interface Option {
    readonly value: string;
    readonly label: string;
}

export default function CreateOfflineTransactionForm(
    { filterObject }: any
) {

    const [transferMode, setTransferMode] = useState<string>("Offline Transaction");
    const [amount, setAmount] = useState<string>('');
    const [userId, setUserId] = useState<string>('');
    const [errors, setErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [timeStamp, setTimeStamp] = useState<string>(String(Date.now()));

    const validateForm = () => {

        let errors = {} as any;
        let isValid = true;

        if (!userId) {
            errors.userId = ['Please select a User.']
            isValid = false
        }
        if (!parseFloat(amount)) {
            errors.amount = ['Enter a valid amount.']
            isValid = false
        }

        setErrors(errors);
        return isValid
    }

    const getUsers = async (query: string) => {
        const resp = await getUsersList(query);
        return resp.users.map(e => ({ value: e.id, label: e.name + ' - ' + (e.email ? e.email : e.mobile) }))
    }

    const promiseOptions = (inputValue: string) =>
        new Promise<Option[]>(async (resolve) => {
            const list = await getUsers(inputValue);
            resolve(list)
        });

    const onChange = (option: Option | null, actionMeta: ActionMeta<Option>) => {
        // console.log(option, actionMeta);
        if (option !== null) {
            setUserId(option.value);
        }
    }

    const createTransaction = async () => {
        const isFormValid = validateForm();
        if (!isFormValid) {
            return
        }
        const payload = {
            fromWalletAddress: transferMode,
            usdAmount: amount,
            userId: userId,
        }
        try {
            setIsLoading(true);
            let response = await createOfflineTransaction(payload);
            toast.success(response.message);
            setIsLoading(false);
            filterObject()
        } catch (e) {
            setIsLoading(false);
        }
    }

    return (
        <div className="accordion accordion-s2" id="accordion-1">
            <div className="accordion-item">
                <h6 className="accordion-heading collapsed" data-toggle="collapse" data-target="#collapse-1">
                    <div className='filter-toggle'>Create Offline Transaction</div>
                </h6>
                <div id="collapse-1" className="collapse" data-parent="#accordion-1">
                    <div className="accordion-content">
                        <div className="row">
                            <div className="col-3">
                                <label className="input-item-label">Mode</label>
                                <FormInput onChange={() => { }} disabled value={transferMode} />
                            </div>
                            <div className="col-3">
                                <label className="input-item-label">User</label>
                                <AsyncSelect cacheOptions isClearable={true}
                                    className="react-select-container"
                                    classNamePrefix="react-select"
                                    onChange={onChange}
                                    defaultOptions loadOptions={promiseOptions} />
                                <div className="custom-error">
                                    <FormErrorList errors={errors?.userId} />
                                </div>
                            </div>
                            <div className="col-3">
                                <label className="input-item-label">Amount <span className='sub'>(USD)</span></label>
                                <FormInput onChange={setAmount} value={amount} errors={errors?.amount} />
                            </div>
                            <div className="col-3">
                                <label className="input-item-label"></label>
                                <FormButton label="Create Transaction" onClick={createTransaction} isLoading={isLoading} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}