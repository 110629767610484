import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { closeModal, openModal } from '../../helpers/modal.helper';
import { createTransaction } from '../../services/transaction.service';
import { updateSession } from '../../store/auth.store';
import { User } from '../../types/generic/user.type';
import { GenericModalProps } from '../../types/props/generic-modal.props';
import { Spinner } from '../spinner';

interface PayConfirmModalProps extends GenericModalProps {
    orderId: string;
    orderCurrency: string;
    user: User;
    setUser: Function;
}
function PayConfirmModalComponent({
    // props
    orderId,
    orderCurrency,
    modalId,
    // state
    user,
    // dispatch
    setUser,
}: PayConfirmModalProps) {

    const [fromWalletAddress, setFromWalletAddress] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    // update address for user
    useEffect(() => {
        const userWalletAddress = orderCurrency === "eth" ? user?.sendingEthereumWalletAddress : (
            orderCurrency === "btc" ? user?.sendingBitcoinWalletAddress : ""
        );
        setFromWalletAddress(userWalletAddress);
    }, [user, orderCurrency]);


    const submit = async () => {
        const transactionPayload = {
            orderId,
            fromWalletAddress
        };
        try {
            setIsLoading(true);
            const response = await createTransaction(transactionPayload);
            toast.success(response.message);
            // hide modal
            closeModal(modalId);
            // show pay-review
            openModal("pay-review");
            // update the address in user state
            const addressKey = orderCurrency === "eth" ? "sendingEthereumWalletAddress" : (
                orderCurrency === "btc" ? "sendingBitcoinWalletAddress" : ""
            )
            console.log("addresskey", addressKey, {
                ...user,
                [addressKey]: fromWalletAddress
            });
            if (addressKey) {
                setUser({
                    ...user,
                    [addressKey]: fromWalletAddress
                });
            }
            // reset modal
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("Error", error);
            toast.error("Something went wrong");
        }
    }

    return (
        <div className="modal fade" id={modalId} tabIndex={-1}>
            <div className="modal-dialog modal-dialog-md modal-dialog-centered">
                <div className="modal-content">
                    <div className="popup-body">
                        <h4 className="popup-title">Confirmation Your Payment</h4>
                        <p className="lead text-primary">Your Order no. <strong>{orderId}</strong> has been placed successfully. </p>
                        <p>The tokens balance will appear in your account only after you transaction gets 6 confirmations and approved our team.</p>
                        <p>To <strong>speed up verifcation</strong> proccesing please enter <strong>your wallet address</strong> from where you’ll transferring your ethereum to our address.</p>
                        <div className="input-item input-with-label">
                            <label htmlFor="token-address" className="input-item-label">Enter your wallet address</label>
                            <input className="input-bordered" type="text" value={fromWalletAddress} onChange={e => {
                                setFromWalletAddress(e.target.value);
                            }} placeholder="Enter your wallet address" />
                        </div>{/* .input-item */}
                        <ul className="d-flex flex-wrap align-items-center guttar-30px">
                            <li>
                                <button onClick={submit} disabled={isLoading} className="btn btn-primary">
                                    {
                                        isLoading ? <Spinner white /> : (
                                            <>Confirm Payment</>
                                        )
                                    }
                                </button>
                            </li>
                            {/* <li className="pdt-1x pdb-1x"><a href="#" data-dismiss="modal" data-toggle="modal" data-target="#pay-online" className="link link-primary">Make Online Payment</a></li> */}
                        </ul>
                        <div className="gaps-2x" />
                        <div className="gaps-1x d-none d-sm-block" />
                        <div className="note note-plane note-light mgb-1x">
                            <em className="fas fa-info-circle" />
                            <p>Do not make payment through exchange (Kraken, Bitfinex). You can use MayEtherWallet, MetaMask, Mist wallets etc.</p>
                        </div>
                        <div className="note note-plane note-danger">
                            <em className="fas fa-info-circle" />
                            <p>In case you send a different amount, number of FGC tokens will update accordingly.</p>
                        </div>
                    </div>
                </div>{/* .modal-content */}
            </div>{/* .modal-dialog */}
        </div>
    )
}

const stp = (state: any) => ({
    user: state.auth,
});

const dtp = {
    setUser: updateSession
};

export const PayConfirmModal = connect(stp, dtp)(PayConfirmModalComponent);
