import React, {FormEvent, useEffect, useState} from 'react';
import {FormInput} from "../../components/atoms/form-input.atom";
import {Spinner} from "../../components/spinner";
import {connect} from "react-redux";
import {StoreState} from "../../types/store-state.type";
import {createSession, destroySession, updateSession} from "../../store/auth.store";
import {updateProfileData} from "./profile.api.helper";
import axios from "axios";
import {toast} from "react-toastify";
import moment from "moment";
import {FormSelect} from "../../components/atoms/form-select.atom";
import {PasswordChangeSection} from "./password-change-section";
import {
    City,
    Country,
    getCountriesList,
    getPhoneCodeList,
    PhoneCode
} from "../../services/country-state-city.service";
import cx from "classnames";
import {isNameValid} from "../../helpers/validation.helper";

function ProfileDetailsComponent(
    {
        user,
        updateUser
    }: any) {
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState(user?.name);
    const [dob, setDob] = useState<string>();
    const [countryCode, setCountryCode] = useState(user?.countryCode);
    const [mobile, setMobile] = useState(user?.mobile);
    const [errors, setErrors] = useState<any>({});
    const [nationality, setNationality] = useState<string>(user?.nationality);
    const [phoneCodeList, setPhoneCodeList] = useState<PhoneCode[]>([]);
    const [countriesList, setCountriesList] = useState<Country[]>([]);

    useEffect(() => {
        getPhoneCodeList().then(phoneCodeList => {
            setPhoneCodeList(phoneCodeList);
        })
        getCountriesList().then(countries => {
            setCountriesList([{name: '', value: ''}, ...countries]);
        })
        if (user?.dob) {
            setDob(moment(user?.dob).format("YYYY-MM-DD"))
        }
    }, []);

    const updateProfile = (e: FormEvent) => {
        e.preventDefault();
        setErrors({});
        const isValid = validateForm();
        if (!isValid) {
            return false
        }
        setIsLoading(true);
        const payload = {
            name,
            dob,
            countryCode,
            mobile,
            nationality
        };
        updateProfileData(payload).then(res => {
            setIsLoading(false);
            const userObject = {
                ...res.user,
                ...{
                    token: user['token']
                }
            };
            updateUser(userObject)
            localStorage.setItem('fgc.user', JSON.stringify(userObject));
            toast.success(res.message);
        }, (error) => {
            setErrors(error.data);
            setIsLoading(false);
        });
    };

    const validateForm = () => {

        let errors = {} as any;
        let isValid = true;

        if (!isNameValid(name)) {
            errors.name = ['Enter a valid name.']
            isValid = false
        }

        setErrors(errors);
        return isValid
    }

    return (
        <div className="content-area card">
            <div className="card-innr">
                <div className="card-head">
                    <h4 className="card-title">Profile Details</h4>
                </div>
                <ul className="nav nav-tabs nav-tabs-line" role="tablist">
                    <li className="nav-item">
                        <a className="nav-link active" data-toggle="tab" href="#personal-data">Personal
                            Data</a>
                    </li>
                    {/*<li className="nav-item">*/}
                    {/*    <a className="nav-link" data-toggle="tab" href="#settings">Settings</a>*/}
                    {/*</li>*/}
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="tab" href="#password">Password</a>
                    </li>
                </ul>
                {/* .nav-tabs-line */}
                <div className="tab-content" id="profile-details">
                    <div className="tab-pane fade show active" id="personal-data">
                        <form action="#">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <label htmlFor="full-name" className="input-item-label">
                                            Full Name
                                        </label>
                                        <FormInput placeholder="Your Name" value={name}
                                                   onChange={setName}
                                                   errors={errors?.name}/>
                                    </div>
                                    {/* .input-item */}
                                </div>
                                <div className={cx('col-md-6', {'d-none': !user.isEmailVerified})}>
                                    <div className="input-item input-with-label">
                                        <label htmlFor="email-address" className="input-item-label">
                                            Email Address
                                        </label>
                                        <input className="input-bordered" type="email"
                                               id="email-address" name="email-address"
                                               value={user?.email} disabled/>
                                    </div>
                                </div>
                                <div className={cx('col-md-6', {'d-none': !user.isMobileVerified})}>
                                    <div className="input-item input-with-label">
                                        <label htmlFor="mobile-number" className="input-item-label">
                                            Mobile Number
                                        </label>
                                        <div className="mobile-with-Country-code">
                                            <FormSelect onChange={setCountryCode}
                                                        options={phoneCodeList} value={countryCode}
                                                        errors={errors?.countryCode} disabled/>
                                            <FormInput placeholder="Your Mobile" value={mobile}
                                                       onChange={setMobile} errors={errors?.mobile} disabled/>
                                        </div>
                                    </div>
                                    {/* .input-item */}
                                </div>
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <label htmlFor="date-of-birth" className="input-item-label">
                                            Date of Birth
                                        </label>
                                        <input className="input-bordered date-picker-dob"
                                               type="date" id="date-of-birth" max={moment().format("YYYY-MM-DD")}
                                               value={dob}
                                               onChange={(e) => setDob(moment(e.target.value).format("YYYY-MM-DD"))}
                                               name="date-of-birth"/>
                                    </div>
                                    {/* .input-item */}
                                </div>
                                {/* .col */}
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <label htmlFor="nationality"
                                               className="input-item-label">Nationality</label>
                                        <FormSelect options={countriesList} onChange={setNationality}
                                                    value={nationality} errors={errors?.nationality}/>
                                    </div>
                                    {/* .input-item */}
                                </div>
                                {/* .col */}
                            </div>
                            {/* .row */}
                            <div className="gaps-1x"/>
                            {/* 10px gap */}
                            <div
                                className="d-sm-flex justify-content-between align-items-center">
                                <button className="btn btn-primary" disabled={isLoading}
                                        onClick={updateProfile}>{isLoading ?
                                    <Spinner white/> : 'Update Profile'}</button>
                                {/*<div className="gaps-2x d-sm-none"/>*/}
                                {/*{isLoading ?*/}
                                {/*    <div className="text-success" style={{display: 'flex'}}>*/}
                                {/*        Updating&nbsp;...&nbsp;&nbsp;&nbsp;<Spinner green/>*/}
                                {/*    </div> :*/}
                                {/*    <span className="text-success">*/}
                                {/*    <em className="ti ti-check-box"/> All Changes are saved*/}
                                {/*</span>*/}
                                {/*}*/}
                            </div>
                        </form>
                        {/* form */}
                    </div>
                    {/* .tab-pane */}
                    <div className="tab-pane fade" id="settings">
                        <div className="pdb-1-5x">
                            <h5 className="card-title card-title-sm text-dark">Security
                                Settings</h5>
                        </div>
                        <div className="input-item">
                            <input type="checkbox" className="input-switch input-switch-sm"
                                   id="save-log" defaultChecked/>
                            <label htmlFor="save-log">Save my activities log</label>
                        </div>
                        <div className="input-item">
                            <input type="checkbox" className="input-switch input-switch-sm"
                                   id="pass-change-confirm"/>
                            <label htmlFor="pass-change-confirm">Confirm with me via email before changing
                                password</label>
                        </div>
                        <div className="pdb-1-5x">
                            <h5 className="card-title card-title-sm text-dark">Manage
                                Notification</h5>
                        </div>
                        <div className="input-item">
                            <input type="checkbox" className="input-switch input-switch-sm"
                                   id="latest-news" defaultChecked/>
                            <label htmlFor="latest-news">Notify me by email about sales and
                                latest news</label>
                        </div>
                        <div className="input-item">
                            <input type="checkbox" className="input-switch input-switch-sm"
                                   id="activity-alert" defaultChecked/>
                            <label htmlFor="activity-alert">Alert me by email for unusual
                                activity.</label>
                        </div>
                        {/*<div className="gaps-1x"/>*/}
                        {/*<div className="d-flex justify-content-between align-items-center">*/}
                        {/*    <span className="text-success">*/}
                        {/*        <em className="ti ti-check-box"/> */}
                        {/*        Setting has been updated*/}
                        {/*    </span>*/}
                        {/*</div>*/}
                    </div>
                    {/* .tab-pane */}
                    <PasswordChangeSection/>
                    {/* .tab-pane */}
                </div>
                {/* .tab-content */}
            </div>
        </div>
    );
}

const stp = (state: StoreState) => ({
    user: state.auth
});
const dtp = {
    updateUser: updateSession
}

export const ProfileDetailsSection = connect(stp, dtp)(ProfileDetailsComponent)
