import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { TokenCalculation } from '../../../components/token-calculation';
import { TokenSalesProgressCard } from '../../../components/token-sales-progress-card.component';
import { getFGCCountForUSD } from '../../../helpers/currency.helper';
import { readableNum } from '../../../helpers/ui.helper';
import { createSession } from '../../../store/auth.store';
import { StoreState } from '../../../types/store-state.type';
import { AdminTransactionTable } from './admin-transaction-table';
import {AdminTokenSaleGraph} from "../../../components/admin-token-sale-graph.component";

function AdminDashboardPageComponent({
    // state
    user,
    walletDetails,
    exchangeRate,
    // dispatch
    setUser,
}: any) {

    const { usd, fgc, eth, btc } = walletDetails;

    const bitcoinPrice = exchangeRate.bitcoin.usd;
    const ethereumPrice = exchangeRate.ethereum.usd;

    // get the conversion rates
    useEffect(() => {
        fetchPrices();
    }, []);
    const fetchPrices = async () => {};

    return (
        <div className="DashboardPage">
            <div className="page-content">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="token-statistics card card-token height-auto">
                                <div className="card-innr">
                                    <div className="token-balance token-balance-with-icon">
                                        <div className="token-balance-icon">
                                            <img src="images/fgc_logo_2.png" alt="logo" />
                                        </div>
                                        <div className="token-balance-text">
                                            <h6 className="card-sub-title">Tokens Balance</h6>
                                            <span className="lead">{readableNum(fgc)} <span>FGC</span></span>
                                        </div>
                                    </div>
                                    <div className="token-balance token-balance-s2">
                                        <h6 className="card-sub-title">Your Contribution</h6>
                                        <ul className="token-balance-list">
                                            <li className="token-balance-sub">
                                                <span className="lead">{readableNum(eth)}</span>
                                                <span className="sub">ETH</span>
                                            </li>
                                            <li className="token-balance-sub">
                                                <span className="lead">{readableNum(btc)}</span>
                                                <span className="sub">BTC</span>
                                            </li>
                                            <li className="token-balance-sub">
                                                <span className="lead">{readableNum(usd)}</span>
                                                <span className="sub">USD</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>{/* .col */}
                        <div className="col-lg-8">
                            <div className="token-information card card-full-height">
                                <div className="row no-gutters height-100">
                                    <div className="col-md-6 text-center">
                                        <div className="token-info">
                                            <img className="token-info-icon" src="images/logo-sm.png" alt="logo-sm" />
                                            <div className="gaps-2x" />
                                            <h1 className="token-info-head text-light">1 ETH = {readableNum(getFGCCountForUSD(ethereumPrice ?? 0))} FGC</h1>
                                            <h5 className="token-info-sub">1 ETH = {readableNum(ethereumPrice ?? 0)} USD</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="token-info bdr-tl">
                                            <div>
                                                <ul className="token-info-list">
                                                    <li><span>Token Name:</span>Football Goal Coin</li>
                                                    <li><span>Ticket Symbol:</span>FGC</li>
                                                </ul>
                                                <a href="/assets/Football_Goal_Coin_FGC_Whitepaper_2023_v.1.pdf" target="_blank" className="btn btn-primary">
                                                    <em className="fas fa-download mr-3" />Download Whitepaper
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>{/* .card */}
                        </div>{/* .col */}
                        <div className="col-xl-8 col-lg-7">
                            <AdminTransactionTable />
                        </div>
                        <TokenCalculation />
                    </div>{/* .row */}
                    <div className="row">
                        <div className="col-xl-8 col-lg-7">
                            <AdminTokenSaleGraph/>
                        </div>{/* .col */}
                        <div className="col-xl-4 col-lg-5">
                            <TokenSalesProgressCard />
                        </div>
                    </div>{/* .row */}
                </div>{/* .container */}
            </div>{/* .page-content */}
            {/* JavaScript (include all script here) */}
        </div>
    )
}

const stp = (state: StoreState) => ({
    user: state.auth,
    walletDetails: state.walletDetails,
    exchangeRate: state.exchangeRate,
});

const dtp = {
    setUser: createSession
}

export const AdminDashboardPage = connect(stp, dtp)(AdminDashboardPageComponent);
