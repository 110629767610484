import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

export function RegisterSuccessPage() {

    const [searchParams, setSearchParams] = useSearchParams();

    // call verify api
    const isByEmail = searchParams.get('mode') === "email";

    return (
        <div className="RegisterSuccessPage">
            <div className="page-ath-wrap">
                <div className="page-ath-content">
                    <div className="page-ath-header">
                        <a href="./" className="page-ath-logo">
                            <img src="/images/fgc_logo_2.png" alt="logo" />
                        </a>
                    </div>
                    <div className="page-ath-text">
                        <h2 className="page-ath-heading">
                            Thank you!
                            {
                                isByEmail ?
                                    <>
                                        <small>
                                            You're just a few steps away from completing sign-up process.
                                            An email has been sent to you for verification purpose.
                                        </small>
                                        <span>
                                            Please check your email for the verification link.
                                        </span>
                                    </> : <small>Your sign-up process is done.</small>
                            }

                        </h2>
                        <div className="form-note">
                            {isByEmail ? "Already verified?" : ""} <Link to="/login"> <strong>Login here</strong></Link>
                        </div>
                    </div>
                    <div className="page-ath-footer">
                        <ul className="footer-links">
                            <li><a href="https://footballgoalcoin.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
                            <li><a href="https://footballgoalcoin.com/terms-of-service/" target="_blank">Terms of Service</a></li>
                            <li>© 2023 FGC.</li>
                        </ul>
                    </div>
                </div>
                <div className="page-ath-gfx">
                    <div className="w-100 d-flex justify-content-center">
                        <div className="col-md-8 col-xl-5">
                            {/*<img src="images/ath-gfx.png" alt="image" />*/}
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
