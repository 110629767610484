import { User } from "../types/generic/user.type";
import { httpGet } from "./http.service";

interface UsersListResponse {
    message: string,
    users: User[]
}

const getUsersList = async (query: string): Promise<UsersListResponse> => {
    return httpGet<UsersListResponse>("admin/user/search?name=" + query );
}

export {
    getUsersList,
}
