import React from 'react';
import { Spinner } from '../spinner';

interface DataTableShowEmptyProps {
    columnCount: number;
    rowCount: number;
}

export function DataTableShowEmpty({
    columnCount = 0,
    rowCount = 0,
}: DataTableShowEmptyProps) {
    return (
        <tr>
            <td rowSpan={rowCount} colSpan={columnCount}>
                <span className="table-loader" style={{
                    margin: "1rem",
                    height: ((73.5 * rowCount) + 19) + "px"
                }}>No data</span>
            </td>
        </tr>
    )
}