import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { httpPost } from "../services/http.service";
import axios from "axios";
import { toast } from "react-toastify";
import { FormButton } from '../components/atoms/form-button.atom';
import { verifyEmail } from '../services/auth.service';

export default function VerifyEmailPage() {

    const [searchParams, setSearchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();

    // call verify api
    const token = searchParams.get('token') ?? "";
    const email = searchParams.get('email') ?? "";

    // call the verification api
    useEffect(() => {
        if (token && email) {
            callVerifyEmailAPI();
        }
    }, [token, email]);

    // function to call the verification API
    const callVerifyEmailAPI = async () => {
        setIsLoading(true);
        try {
            let resp = await verifyEmail({
                email,
                token
            });
            // success, show message and send to login
            if (resp.message) {
                toast.success(resp.message);
                navigate('/login');
            }
        } catch (err) {
            // error, show error
            setIsLoading(false);
            if (axios.isAxiosError(err)) {
                setErrorMessage(err?.response?.data?.message);
            }
        }
    }


    return (
        <div className="ICOApp">
            <div className="page-ath-wrap">
                <div className="page-ath-content">
                    <div className="page-ath-header">
                        <a href="./" className="page-ath-logo">
                            <img src="/images/fgc_logo_2.png" alt="logo" />
                        </a>
                    </div>
                    <div className="page-ath-form">
                        <h2 className="page-ath-heading">Verify email <small></small></h2>
                        {
                            isLoading ? (
                                "Verifying details..."
                            ) : (
                                <span className="error">{errorMessage}</span>
                            )
                        }
                    </div>
                    <div className="page-ath-footer">
                        <ul className="footer-links">
                            <li><a href="https://footballgoalcoin.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
                            <li><a href="https://footballgoalcoin.com/terms-of-service/" target="_blank">Terms of Service</a></li>
                            <li>© 2023 FGC.</li>
                        </ul>
                    </div>
                </div>
                <div className="page-ath-gfx">
                    <div className="w-100 d-flex justify-content-center">
                        <div className="col-md-8 col-xl-5">
                            {/*<img src="images/ath-gfx.png" alt="image" />*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
