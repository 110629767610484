import React, { useEffect } from 'react';
import { ToggleDropdown } from '../toggle-dropdown';

export function Footer() {

    return (
        <div className="footer-bar">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="bg-white">
                            <div className="row align-items-center justify-content-center pr-2">
                                <div className="col-md-9">
                                    <ul className="footer-links">
                                        <li><a href="https://footballgoalcoin.com/"
                                            target="_blank">Main Website</a></li>
                                        <li><a href="http://mining.footballgoalcoin.com/"
                                            target="_blank">Mining</a></li>
                                        <li><a href="https://footballgoalcoin.com/earn/"
                                            target="_blank">Earn</a></li>
                                        <li><a href="https://footballgoalcoin.com/whitepaper/"
                                            target="_blank">Whitepaper</a></li>
                                        <li><a href="https://footballgoalcoin.com/what-is-football-goal-coin/" target="_blank">FAQs</a></li>
                                        <li><a href="https://footballgoalcoin.com/privacy-policy/" target="_blank">Privacy
                                            Policy</a></li>
                                        <li><a href="https://footballgoalcoin.com/terms-of-service/" target="_blank">Terms
                                            & Conditions</a></li>
                                        <li><a href="mailto:info@footballgoalcoin.com" target="_blank">Contact Us</a></li>
                                        <li><a href="https://footballgoalcoin.com/jobs/" target="_blank">Jobs</a></li>
                                    </ul>
                                </div>
                                {/* .col */}
                                <div className="col-md-3 mt-2 mt-sm-0">
                                    <div
                                        className="d-flex justify-content-center justify-content-md-end align-items-center guttar-25px pdt-0-5x pdb-0-5x">
                                        <div className="copyright-text text-center">© Football Goal Coin 2024.</div>
                                        {/* <div className="lang-switch relative"> */}
                                        {/*<ToggleDropdown*/}
                                        {/*    buttonClass="lang-switch-btn"*/}
                                        {/*    buttonElement={*/}
                                        {/*        <>En <em className="ti ti-angle-up" /></>*/}
                                        {/*    }*/}
                                        {/*    dropdownContent={*/}
                                        {/*        <ul className="lang-list">*/}
                                        {/*            <li><a href="#">Fr</a></li>*/}
                                        {/*            <li><a href="#">Bn</a></li>*/}
                                        {/*            <li><a href="#">Lt</a></li>*/}
                                        {/*        </ul>*/}
                                        {/*    }*/}
                                        {/*    direction="up" />*/}
                                        {/* </div> */}
                                    </div>
                                </div>
                                {/* .col */}
                            </div>
                        </div>
                        {/* .row */}
                    </div>
                </div>
            </div>
            {/* .container */}
        </div>
    )
}
