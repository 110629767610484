import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { closeModal, openModal } from '../../helpers/modal.helper';
import { TokenCurrencyChooser } from '../../pages/buy-token.page/token-currency-chooser';
import { createOrder, createTransaction } from '../../services/transaction.service';
import { updateSession } from '../../store/auth.store';
import { Config } from '../../types/generic/config.type';
import { User } from '../../types/generic/user.type';
import { GenericModalProps } from '../../types/props/generic-modal.props';
import { Spinner } from '../spinner';

interface GetPayAddressModalProps extends GenericModalProps {
    setOrderId: Function;
    setOrderCurrency: Function;
    setUser: Function;
    config: Config;
    user: User;
    isComponent?: boolean;
}

function GetPayAddressModalComponent({
    modalId,
    setOrderId,
    setOrderCurrency,
    // state,
    user,
    config,
    isComponent = false,
    // dispatch,
    setUser,
}: GetPayAddressModalProps) {

    const [isLoading, setIsLoading] = useState(false);
    const [isAgreementAccepted, setIsAgreementAccepted] = useState(false);

    const [selectedCurrency, setSelectedCurrency] = useState("eth");

    const paymentAddress = selectedCurrency === "eth" ? config.ETHEREUM_WALLET_ADDRESS : config.BITCOIN_WALLET_ADDRESS;
    console.log("config", config);
    const submit = async () => {
        // create order
        const orderPayload = {
            selectedCurrency, // select currency is usd as we are paying in usd only
            isAgreementAccepted,
        };
        try {
            setIsLoading(true);
            const response = await createOrder(orderPayload);
            // toast.success(response.message);
            const { orderId } = response.order;
            setOrderId(orderId);
            setOrderCurrency(selectedCurrency);
            // hide modal
            // closeModal(modalId);
            // show pay-review
            await triggerCreateTransaction(orderId, selectedCurrency);
            // // show pay-confirm
            // openModal("pay-confirm");
            // reset modal
        } catch (error) {
            setIsLoading(false);
            toast.error("Something went wrong");
        }
    }


    const triggerCreateTransaction = async (orderId: string, orderCurrency: string) => {
        const fromWalletAddress = orderCurrency === "eth" ? user?.sendingEthereumWalletAddress : (
            orderCurrency === "btc" ? user?.sendingBitcoinWalletAddress : ""
        );
        const transactionPayload = {
            orderId,
            fromWalletAddress
        };
        try {
            setIsLoading(true);
            const response = await createTransaction(transactionPayload);
            toast.success(response.message);
            // hide modal
            closeModal(modalId);
            // show pay-review
            openModal("pay-review");
            // update the address in user state
            const addressKey = orderCurrency === "eth" ? "sendingEthereumWalletAddress" : (
                orderCurrency === "btc" ? "sendingBitcoinWalletAddress" : ""
            )
            console.log("addresskey", addressKey, {
                ...user,
                [addressKey]: fromWalletAddress
            });
            if (addressKey) {
                setUser({
                    ...user,
                    [addressKey]: fromWalletAddress
                });
            }
            // reset modal
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.log("Error", error);
            toast.error("Something went wrong");
        }
    }

    const Wrapper = isComponent ? Component : Modal

    return (
        <Wrapper modalId={modalId}>
            {/* show buttons for eth and btc */}
            <h4 className="popup-title">Payment Address for Deposit</h4>
            <TokenCurrencyChooser
                selectedCurrency={selectedCurrency}
                setSelectedCurrency={setSelectedCurrency}
                enabledCurrencies={["eth", "btc"]}
                showBalance={false} />
            {/*<p>Please make deposit, once we receive payment.</p>*/}
            {/* <div className="gaps-1x" /> */}
            <h6 className="font-bold">Please use your account email address for reference / transfer note</h6>
            <div className="gaps-1x" />
            <h6 className="font-bold">Payment to the following Address</h6>
            <div className="copy-wrap mgb-0-5x">
                <span className="copy-feedback" />
                <em className="fab fa-ethereum" />
                <input type="text" className="copy-address" value={paymentAddress} disabled />
                <button className="copy-trigger copy-clipboard" data-clipboard-text={paymentAddress}><em className="ti ti-files" /></button>
            </div>{/* .copy-wrap */}
            <ul className="pay-info-list row">
                {/*<li className="col-sm-6"><span>SET GAS LIMIT:</span> 120 000</li>*/}
                {/*<li className="col-sm-6"><span>SET GAS PRICE:</span> 95 Gwei</li>*/}
            </ul>{/* .pay-info-list */}
            <div className="pdb-2-5x pdt-1-5x">
                <input type="checkbox" className="input-checkbox input-checkbox-md" id="agree-term" checked={isAgreementAccepted} onChange={() => { /* */ }} />
                <label htmlFor="agree-term" onClick={() => { setIsAgreementAccepted(!isAgreementAccepted) }}>
                    I hereby agree to the <strong>Terms & Conditions</strong>
                </label>
            </div>
            <button className="btn btn-primary" onClick={submit} disabled={!isAgreementAccepted || isLoading}>
                {isLoading ? <Spinner white /> : (
                    <>Deposit  <em className="ti ti-arrow-right mgl-4-5x" /></>
                )}
            </button>
            <div className="gaps-3x" />
            <div className="note note-plane note-light mgb-1x">
                <em className="fas fa-info-circle" />
                <p> Do not make payment through exchange (Kraken, Bitfinex). You can use MayEtherWallet, MetaMask, Mist wallets etc.</p>
            </div>
            <div className="note note-plane note-danger">
                <em className="fas fa-info-circle" />
                <p>In case you send a different amount, number of FGC tokens will update accordingly.</p>
            </div>
        </Wrapper>
    )
}

function Component({ children }: any) {
    return (
        <>{children}</>
    )
}

function Modal({
    modalId,
    children
}: any) {
    return (
        <div className="modal fade" id={modalId} tabIndex={-1}>
            <div className="modal-dialog modal-dialog-md modal-dialog-centered">
                <div className="modal-content">
                    <a href="#" className="modal-close" data-dismiss="modal" aria-label="Close"><em className="ti ti-close" /></a>
                    <div className="popup-body">
                        {children}
                    </div>
                </div>{/* .modal-content */}
            </div>{/* .modal-dialog */}
        </div>
    )
}


const dtp = {
    setUser: updateSession
};

export const GetPayAddressModal = connect((s: any) => ({
    user: s.auth,
    balance: s.walletDetails,
    config: s.config,
    exchangeRate: s.exchangeRate,
}), dtp)(GetPayAddressModalComponent);
