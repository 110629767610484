import { useIdleTimer } from 'react-idle-timer'
import { useEffect, useState } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { destroySession } from "../store/auth.store";

function customIdleTimerComponent({
    endSession,
    idleTime = 1
}: any) {

    // const [isIdle, setIsIdle] = useState(false);

    useEffect(() => {
        checkSession();
    });

    const checkSession = () => {
        const userSession = localStorage.getItem('fgc.userSession');
        try {
            if (userSession) {
                const session = JSON.parse(userSession);
                const lastActive = moment(session.lastActive).format();
                const now = moment().format();
                const diff = moment(now).diff(lastActive, 'minutes');
                if (diff >= 20) {
                    logOut();
                }
            } else {
                logOut();
            }
        } catch (e) {
            logOut();
        }
    }

    const onPresenceChange = (event: any) => {
        const isIdle = event.type === 'idle';
        if (isIdle) {
            const diff = getTimeDifference();
            if (diff >= idleTime) {
                endSession();
                localStorage.removeItem("fgc.user");
                localStorage.removeItem("fgc.userSession");
                window.location.href = "/";
            }
        }
    }

    const getTimeDifference = () => {
        const lastActive = moment(getLastActiveTime()).format();
        const now = moment().format();
        const diff = moment(now).diff(lastActive, 'minutes');
        console.log(diff);
        return diff
    }

    const logOut = () => {
        endSession();
        localStorage.removeItem("fgc.user");
        localStorage.removeItem("fgc.userSession");
    }

    const { getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * idleTime,
        onPresenceChange: onPresenceChange,
        debounce: 500
    });

    return (
        <></>
    )
}

const stp = (state: any) => ({
    user: state.auth
});

const dtp = {
    endSession: destroySession
};

export const CustomIdleTimer = connect(stp, dtp)(customIdleTimerComponent)

