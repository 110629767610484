import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { StoreState } from "../../types/store-state.type";
import { initKycSession } from '../../services/kyc.service';

function KYCApplicationComponent({ user }: any) {

    return (
        <div className="page-user">
            <div className="page-header-kyc pb-0">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-9 text-center">
                            <div className="card ">
                                <div className="card-innr">
                                    <h2 className="page-title">KYC Verification</h2>
                                    <p className="large">To comply with regulations, each participant must undergo identity verification (KYC/AML) to prevent fraudulent transactions.
                                        Please, complete our fast and secure verification process to participate in our token sale.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* .container */}
            </div>
            {/* .page-header */}
            <div className="page-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-9">
                            <div className="kyc-status card">
                                <div className="card-innr">
                                    <div className="status status-empty">
                                        <div className="status-icon">
                                            <em className="ti ti-files" />
                                        </div>
                                        {
                                            user?.kycStatus === 'pending' || user?.kycStatus === 'rejected' ?
                                                <span className="status-text text-dark">
                                                    Your essential identity verification documents have not been submitted
                                                </span> : ''
                                        }
                                        {
                                            user?.kycStatus === 'verifying' ?
                                                <span className="status-text text-dark">You have submitted your necessary documents, we are verifying your identity.</span> : ''
                                        }

                                        <Link to='/kyc-form'>
                                            <button className="btn btn-primary" disabled={['verifying', 'approved'].includes(user?.kycStatus)}>Click here to complete your KYC</button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* .card */}
                            <div className="card mt-4">
                                <div className="card-innr">
                                    <p className="text-light text-center">
                                        <div>
                                            If you have any question, please contact our support team
                                            <a href="mailto:info@footballgoalcoin.com"> info@footballgoalcoin.com</a>.
                                        </div>
                                        <div>
                                            or Telegram for a quick response via
                                            <a href="https://t.me/footballgoalcoin1" target='_blank'> https://t.me/footballgoalcoin1 </a>
                                        </div>
                                    </p>
                                </div>
                            </div>
                            <div className="gaps-1x" />
                            <div className="gaps-3x d-none d-sm-block" />
                        </div>
                    </div>
                </div>
                {/* .container */}
            </div>
        </div>

    )
}

function SessionFrame({ sessionUrl }: { sessionUrl: string }) {
    return (
        <iframe
            // src={`https://www.yoti.com/connect/${"e54bedfa-d851-4e92-91aa-26e06ffc1da3"}?session_token=${"60746f3b-5254-4755-9392-b74880ea6ab8"}`}
            src={sessionUrl}
            title="Yoti Verification"
            width="100%"
            height="600"
        />
    );
}

const stp = (state: StoreState) => ({
    user: state.auth
});
const dtp = {}

export const KYCApplication = connect(stp, dtp)(KYCApplicationComponent)
