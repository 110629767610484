import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { Address } from "../types/generic/kyc-details.type";

function readableNum(floatValue: number, places = 2) {
    try {
        return places === 0 ? floatValue.toLocaleString() : floatValue.toFixed(places).toLocaleString();
    } catch (e) {
        console.log(e);
    }
}

function readableDate(date: any) {
    // 2018-08-24 10:45PM
    return moment(date).format("YYYY-MM-DD hh:mmA");
}
function humanReadableDate(date: any, noTime = false) {
    // 2018-08-24 10:45PM
    return moment(date).format("DD MMM, YYYY" + (noTime ? "" : " hh:mmA"));
}

function shrinkHash(string: string, cutoff = 10) {
    return string && (string.length > cutoff) ? string.slice(0, 4) + "...." + string.slice(-4) : string;
}

function getDownloadPathFromStorage(string: String) {
    return (process.env.REACT_APP_SERVER_URL ?? "") + string;
}

function getCurrencyNameFromCode(currency: string) {
    switch (currency) {
        case "eth":
            return "Ethereum";
        case "btc":
            return "Bitcoin";
        case "fgc":
            return "Football Goal Coin";
        case "stripe": 
            return "Stripe";
        default:
            return "Unknown";
    }
}

function getStatusClass(status?: string) {
    switch (status) {
        case "pending":
            return "warning";
        case "verifying":
            return "info";
        case "approved":
            return "success";
        case "rejected":
            return "danger";
        default:
            return "primary";
    }
}

function formatAddress(address?: Address) {
    if (!address) return "";

    let addressArr = [];
    if (address.line1) {
        addressArr.push(address.line1);
    }
    if (address.line2) {
        addressArr.push(address.line2);
    }
    if (address.city) {
        addressArr.push(address.city);
    }
    if (address.zipCode) {
        addressArr.push(address.zipCode);
    }
    if (address.state) {
        addressArr.push(address.state);
    }

    return addressArr.join(", ");
}

function readableString(text?: string) {
    const newText = (text ?? "").replace(/[-_]/g, " ");
    return newText.slice(0, 1).toUpperCase() + newText.slice(1)
}

function isAxiosError(error: any) {
    return axios.isAxiosError(error);
}

function httpErrorToast(error: any) {
    if (axios.isAxiosError(error)) {
        toast.error(error?.response?.data?.message);
    } else {
        console.log('error', error);
        toast.error("Something went wrong!");
    }
}

function axiosSetError(error: any, setErrors?: Function) {
    if (error?.response?.status === 422) {
        // validation error
        toast.error("Some validation error occurred");
        if (setErrors) {
            setErrors(error?.response?.data.errors);
        }
    } else {
        toast.error(error.response?.data.message);
    }
    console.log("Error", error?.response?.data);
}

export {
    readableNum,
    readableDate,
    humanReadableDate,
    shrinkHash,
    getCurrencyNameFromCode,
    getDownloadPathFromStorage,
    getStatusClass,
    formatAddress,
    readableString,
    isAxiosError,
    httpErrorToast,
    axiosSetError,
};
