import React from 'react';
import { connect } from 'react-redux';
import { readableNum } from '../../helpers/ui.helper';
import { StoreState } from '../../types/store-state.type';

function ContributionCardComponent({
    // state
    walletDetails,
}: any) {

    const { usd, fgc, eth, btc } = walletDetails;

    return (
        <div className="token-statistics card card-token height-auto">
            <div className="card-innr">
                <span className='position-absolute d-lg-none' style={{
                    top: '10px',
                    right: '15px',
                    fontSize: '1.5em',
                }}>Wallet</span>
                <div className="token-balance token-balance-with-icon">
                    <div className="token-balance-icon">
                        <img src="images/fgc_logo_2.png" alt="logo" />
                    </div>
                    <div className="token-balance-text">
                        <h6 className="card-sub-title">Tokens Balance</h6>
                        <span className="lead">{readableNum(fgc)} <span>FGC</span></span>
                    </div>
                </div>
                <div className="token-balance token-balance-s2">
                    <h6 className="card-sub-title">Your Contribution</h6>
                    <ul className="token-balance-list">
                        <li className="token-balance-sub">
                            <span className="lead">{readableNum(eth, 8)}</span>
                            <span className="sub">ETH</span>
                        </li>
                        <li className="token-balance-sub">
                            <span className="lead">{readableNum(btc, 8)}</span>
                            <span className="sub">BTC</span>
                        </li>
                        <li className="token-balance-sub">
                            <span className="lead">{readableNum(usd)}</span>
                            <span className="sub">USD</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

const stp = (state: StoreState) => ({
    walletDetails: state.walletDetails,
})


export const ContributionCard = connect(stp)(ContributionCardComponent);
