import React from "react";
import { HomePage, LoginPage, RegisterPage, RegisterSuccessPage } from "../pages";
import { Navigate } from "react-router-dom";
import VerifyEmailPage from "../pages/verify-email.page";
import { AuthRoute, UnAuthRoute } from "./route-auth-checker";
import { ForgotPasswordPage } from "../pages/forgot-password.page";

const guestRouteChildren = [
    {
        path: "/",
        element: <AuthRoute><Navigate replace to="/dashboard" /></AuthRoute>,
    },
    {
        path: "/login",
        element: (
            <UnAuthRoute>
                <React.Suspense fallback={<>...</>} >
                    <LoginPage />
                </React.Suspense>
            </UnAuthRoute>
        ),
    },
    {
        path: "/register",
        element: <UnAuthRoute><RegisterPage /></UnAuthRoute>,
    },
    {
        path: "/register-success",
        element: <UnAuthRoute><RegisterSuccessPage /></UnAuthRoute>,
    },
    {
        path: "/verify-email",
        element: <VerifyEmailPage />,
    },
    {
        path: "/forgot-password",
        element: <UnAuthRoute><ForgotPasswordPage /></UnAuthRoute>,
    },
];

export { guestRouteChildren };
