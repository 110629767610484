import React from 'react';
import { connect } from 'react-redux';

const defaultList = [
    {
        id: "eth",
        name: "ETH",
    },
    {
        id: "ltc",
        name: "LTC",
    },
    {
        id: "btc",
        name: "BTC",
    },
    {
        id: "usd",
        name: "USD",
    },
];

function TokenCurrencyChooserComponent({
    selectedCurrency,
    setSelectedCurrency,
    walletDetails,
    enabledCurrencies = ["usd"],
    showBalance = true,
}: any) {

    const currencyList = defaultList.filter(c => enabledCurrencies.includes(c.id));

    return (
        <div className="token-currency-choose">
            <div className="row guttar-15px">
                {
                    currencyList.map(currency => (
                        <PayOption
                            key={currency.id}
                            id={currency.id}
                            name={currency.name}
                            selected={selectedCurrency === currency.id}
                            balance={walletDetails}
                            onClick={() => {
                                setSelectedCurrency(currency.id);
                            }}
                            showBalance={showBalance} />
                    ))
                }
            </div>
        </div>
    )
}

function PayOption({
    id,
    name,
    balance,
    selected = false,
    onClick = () => { },
    showBalance = true,
}: any) {

    const htmlId = "pay" + id;

    // dollar ico fas fa-dollar-sign

    return (
        <div className="col-6">
            <div className="pay-option" onClick={onClick}>
                <input className="pay-option-check" type="radio" id={htmlId} name="payOption" checked={selected} onChange={() => { /* do nothing */ }} />
                <label className="pay-option-label" htmlFor={htmlId}>
                    <span className="pay-title">
                        {
                            id === "usd" ? (
                                <em className={"pay-icon cf cf-" + id}>$</em>
                            ) : (
                                <em className={"pay-icon cf cf-" + id} />
                            )
                        }
                        <span className="pay-cur">{name}</span>
                    </span>
                    {
                        showBalance ? (
                            <span className="pay-amount">{balance[id].toFixed(2)}</span>
                        ) : <></>
                    }
                </label>
            </div>
        </div>
    );
}

export const TokenCurrencyChooser = connect((s: any) => ({
    walletDetails: s.walletDetails,
}))(TokenCurrencyChooserComponent);
