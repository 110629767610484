import React, { ReactElement, useEffect, useState } from 'react';
import classnames from "classnames";

type Direction = "up" | "down" | "left" | "right" | "top-left";


type ToggleDropdownProps = {
    buttonClass?: String;
    buttonElement?: ReactElement;
    dropdownContent?: ReactElement;
    dropdownClass?: String;
    direction?: Direction;
    arrowDirection?: Direction;
    showArrow?: Boolean;
}

const cx = classnames as any;

export function ToggleDropdown({
    buttonClass = "",
    dropdownClass = "",
    dropdownContent = <></>,
    buttonElement = <></>,
    direction = "up",
    arrowDirection = "up",
    showArrow = false,
}: ToggleDropdownProps) {

    const [isActive, setIsActive] = useState(false);

    // add body listener
    useEffect(() => {
        if (isActive) {
            const listener = () => {
                setIsActive(false);
            }
            document.body.addEventListener("click", listener);
            () => {
                document.body.removeEventListener("click", listener);
            }
        }
    }, [isActive]);

    return (
        <>
            <a href="#" className={
                cx(
                    "toggle-tigger",
                    buttonClass,
                    {
                        active: isActive,
                    }
                )
            } onClick={e => {
                e.stopPropagation();
                setIsActive(!isActive)
            }}>{buttonElement}</a>
            <div className={
                cx(
                    "toggle-class dropdown-content",
                    "dropdown-content-" + direction,
                    dropdownClass,
                    isActive ? "active" : "",
                    {
                        ["dropdown-arrow-" + arrowDirection]: showArrow,
                        "active": isActive,
                    },
                )
            }>{dropdownContent}</div>
        </>
    );
}