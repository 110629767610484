import React, {useEffect} from "react";
import {getTokenSaleByRange} from "../../services/reporting.service";
import moment from "moment";

const AdminTokenSaleGraphComponent = () => {

    useEffect(() => {
        getTokenSaleData();
    }, []);

    const getTokenSaleData = async () => {
        const resp = await getTokenSaleByRange();
        console.log('resp', resp);
        const labels: string[] = [];
        const data: number[] = [];
        resp.data.map((e: { _id: any; tokens: any; }) => {
            labels.push(moment(e._id).format('DD MMMM'));
            data.push(e.tokens);
        })
        loadChart(labels, data);
    }


    // load chart
    const loadChart = (labels: string[], data: number[]) => {


        // Line Chart
        const lineChart = 'tknSale';
        if ((window as any).$('#' + lineChart).length > 0) {
            const lineCh = (document.getElementById(lineChart) as any).getContext("2d");

            const chart = (window as any).Chart(lineCh, {
                // The type of chart we want to create
                type: 'line',

                // The data for our dataset
                data: {
                    labels: labels,
                    datasets: [{
                        label: "",
                        tension: 0.4,
                        backgroundColor: 'transparent',
                        borderColor: '#2c80ff',
                        pointBorderColor: "#2c80ff",
                        pointBackgroundColor: "#fff",
                        pointBorderWidth: 2,
                        pointHoverRadius: 6,
                        pointHoverBackgroundColor: "#fff",
                        pointHoverBorderColor: "#2c80ff",
                        pointHoverBorderWidth: 2,
                        pointRadius: 6,
                        pointHitRadius: 6,
                        data: data,
                    }]
                },

                // Configuration options go here
                options: {
                    legend: {
                        display: false
                    },
                    maintainAspectRatio: false,
                    tooltips: {
                        callbacks: {
                            title: function (tooltipItem: any, data: any) {
                                return 'Date : ' + data['labels'][tooltipItem[0]['index']];
                            },
                            label: function (tooltipItem: any, data: any) {
                                return data['datasets'][0]['data'][tooltipItem['index']] + ' Tokens';
                            }
                        },
                        backgroundColor: '#eff6ff',
                        titleFontSize: 13,
                        titleFontColor: '#6783b8',
                        titleMarginBottom: 10,
                        bodyFontColor: '#9eaecf',
                        bodyFontSize: 14,
                        bodySpacing: 4,
                        yPadding: 15,
                        xPadding: 15,
                        footerMarginTop: 5,
                        displayColors: false
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                fontSize: 12,
                                fontColor: '#9eaecf',

                            },
                            gridLines: {
                                color: "#e5ecf8",
                                tickMarkLength: 0,
                                zeroLineColor: '#e5ecf8'
                            },

                        }],
                        xAxes: [{
                            ticks: {
                                fontSize: 12,
                                fontColor: '#9eaecf',
                                source: 'auto',
                            },
                            gridLines: {
                                color: "transparent",
                                tickMarkLength: 20,
                                zeroLineColor: '#e5ecf8',
                            },
                        }]
                    }
                }
            });
        }
    }

    return (
        <div className="token-sale-graph card card-full-height">
            <div className="card-innr">
                <div className="card-head has-aside">
                    <h4 className="card-title">Tokens Sale Graph</h4>
                    <div className="card-opt">
                        {/*<a href="#" className="link ucap link-light toggle-tigger toggle-caret">7 Days</a>*/}
                        {/*<div className="toggle-class dropdown-content">*/}
                        {/*    <ul className="dropdown-list">*/}
                        {/*        <li><a href="#">30 days</a></li>*/}
                        {/*        <li><a href="#">1 years</a></li>*/}
                        {/*    </ul>*/}
                        {/*</div>*/}
                    </div>
                </div>
                <div className="chart-tokensale">
                    <canvas id="tknSale"/>
                </div>
            </div>
        </div>
    )
}

export const AdminTokenSaleGraph = AdminTokenSaleGraphComponent
