import React, {useRef, useState} from 'react';
import {FormInput} from "../atoms/form-input.atom";
import {FormButton} from "../atoms/form-button.atom";
import {FormSelect} from "../atoms/form-select.atom";
import './filters-styles.scss';
import moment from "moment";

export const AdminTransactionTableFilters = ({filterObject}: any) => {
    const transactionTypes = [{name: '', value: ''}, {name: 'Purchase', value: 'purchase'}, {name: 'Bonus', value: 'bonus'}, {name: 'Deposit', value: 'deposit'}]
    const currencyTypes = [{name: '', value: ''}, {name: 'ETH', value: 'eth'}, {name: 'BTC', value: 'btc'}, {name: 'USD', value: 'usd'}]
    const [user, setUser] = useState<string>('');
    const [transactionType, setTransactionType] = useState<string>('');
    const [currencyType, setCurrencyType] = useState<string>('');
    const [beforeDate, setBeforeDate] = useState<string>('');
    const [afterDate, setAfterDate] = useState<string>('');
    const accordionHeaderRef = useRef(null);
    const search = () => {
        const filters = {
            search: user,
            transactionType,
            currencyType,
            beforeDate,
            afterDate
        }
        filterObject(filters);
    }

    const clear = () => {
        setUser('');
        setTransactionType('');
        setCurrencyType('');
        setBeforeDate('');
        setAfterDate('');
        filterObject({});
    }

    const [accordionTitle, setAccordionTitle] = useState('Show')

    const updateTitle = (e: React.MouseEvent<HTMLElement>) => {
        setTimeout(() => {

        })
        const accordionToggle: HTMLElement = accordionHeaderRef?.current!;
        if (accordionToggle.classList?.contains('collapsed')) {
            setAccordionTitle('Show');
        } else {
            setAccordionTitle('Hide');
        }
    }

    return (
        <>
            <div className={'mb-4 border-bottom'}>
                <div className="accordion accordion-s2" id="accordion-1">
                    <div className="accordion-item">
                        <h6 className="accordion-heading collapsed" data-toggle="collapse" data-target="#collapse-1"
                            ref={accordionHeaderRef}
                            onClick={(e) => {
                                setTimeout(() => {
                                    updateTitle(e)
                                }, 100)
                            }}>
                            <div className={'filter-toggle'}>{accordionTitle}&nbsp;Filters</div>
                        </h6>
                        <div id="collapse-1" className="collapse" data-parent="#accordion-1">
                            <div className="accordion-content">
                                <div className="row">
                                    <div className="col">
                                        <div className="input-with-label">
                                            <label className="input-item-label">Use name</label>
                                            <FormInput onChange={setUser} value={user} placeholder={'Type user name'}/>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="input-with-label">
                                            <label className="input-item-label">Transaction&nbsp;Type</label>
                                            <FormSelect onChange={setTransactionType} options={transactionTypes}
                                                        value={transactionType}/>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="input-with-label">
                                            <label className="input-item-label">Currency&nbsp;Type</label>
                                            <FormSelect onChange={setCurrencyType} options={currencyTypes}
                                                        value={currencyType}/>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="input-with-label">
                                            <label className="input-item-label">Date Range</label>
                                            <div className={'date-range'}>
                                                <input type="date" value={moment(afterDate).format("YYYY-MM-DD")}
                                                       onChange={(e) => setAfterDate(new Date(e.target.value).toUTCString())}
                                                       className="input-bordered"/>
                                                <span className={'px-2'} style={{fontSize: '12px'}}>To</span>
                                                <input type="date" value={moment(beforeDate).format("YYYY-MM-DD")}
                                                       onChange={(e) => setBeforeDate(new Date(e.target.value).toUTCString())}
                                                       className="input-bordered"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className={'filter-actions'}>
                                            <div className="input-with-label submit-button">
                                                <label className="input-item-label">&nbsp;</label>
                                                <FormButton onClick={search} label="Search"/>
                                            </div>
                                            <div className="input-with-label clear-button">
                                                <label className="input-item-label">&nbsp;</label>
                                                <FormButton onClick={clear} label="Clear"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
