import axios from 'axios';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { FormErrorList } from '../../components/atoms/form-error-list.atom';
import { closeModal } from '../../helpers/modal.helper';
import { updateReceivingWalletAddress } from '../../services/auth.service';
import { updateSession } from '../../store/auth.store';
import { User } from '../../types/generic/user.type';
import { GenericModalProps } from '../../types/props/generic-modal.props';

interface GetWalletAdressModalProps extends GenericModalProps {
    user: User,
    updateUser: Function;
}

function GetWalletAdressModalComponent({
    modalId,
    // state
    user,
    // dispatch
    updateUser,
}: GetWalletAdressModalProps) {

    const [receivingWalletType, setReceivingWalletType] = useState("eth");
    const [receivingWalletAddress, setReceivingWalletAddress] = useState("");

    // form related
    const [errors, setErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);

    // submit form
    const submit = async (e: React.MouseEvent) => {
        e.preventDefault();
        // submit values
        const payload = {
            receivingWalletType,
            receivingWalletAddress,
        };
        // start loading, clear errors
        setIsLoading(true);
        setErrors({});

        try {
            const response = await updateReceivingWalletAddress(payload);
            if (response.message) {
                toast.success(response.message);
                updateUser(response.user);
                closeModal(modalId);
                localStorage.setItem('fgc.user', JSON.stringify({
                    ...user,
                    ...response.user
                }));
            }
            setIsLoading(false);
        } catch (error: any) {
            setIsLoading(false);
            if (axios.isAxiosError(error)) {
                if (error?.response?.status === 422) {
                    // validation error
                    toast.error("Some validation error occurred");
                    setErrors(error?.response?.data.errors);
                } else {
                    toast.error(error.response?.data.message);
                }
                console.log("Error", error?.response?.data);
            } else {
                toast.error("Something went wrong");
                console.log("Error", error);
            }
        }
    }

    return (
        <div className="modal fade" id={modalId} tabIndex={-1}>
            <div className="modal-dialog modal-dialog-md modal-dialog-centered">
                <div className="modal-content">
                    <a href="#" className="modal-close" data-dismiss="modal" aria-label="Close"><em className="ti ti-close" /></a>
                    <div className="popup-body">
                        <h4 className="popup-title">Wallet Address</h4>
                        <p>In order to receive your <a href="#"><strong>FGC Tokens</strong></a>, please select your wallet address and you have to put the address below input box. <strong>You will receive FGC tokens to this address after the Token Sale end.</strong></p>
                        <form action="#">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="input-item input-with-label">
                                        <label htmlFor="swalllet" className="input-item-label">Select Wallet </label>
                                        <select className="select-bordered select-block" name="swalllet" id="swalllet" value={receivingWalletType} onChange={e => {
                                            setReceivingWalletType(e.target.value);
                                        }}>
                                            <option value="eth">Ethereum</option>
                                            <option value="dac">DashCoin</option>
                                            <option value="btc">BitCoin</option>
                                        </select>
                                    </div>{/* .input-item */}
                                </div>{/* .col */}
                            </div>{/* .row */}
                            <div className="input-item input-with-label">
                                <label htmlFor="token-address" className="input-item-label">Your Address for tokens:</label>
                                <input className="input-bordered" type="text" id="token-address" name="token-address" value={receivingWalletAddress} onChange={e => {
                                    setReceivingWalletAddress(e.target.value);
                                }} />
                                <span className="input-note">Note: Address should be ERC20-compliant.</span>
                                <FormErrorList errors={errors.receivingWalletAddress} />
                            </div>{/* .input-item */}
                            <div className="note note-plane note-danger">
                                <em className="fas fa-info-circle" />
                                <p>DO NOT USE your exchange wallet address such as Kraken, Bitfinex, Bithumb, Binance etc. You can use MetaMask, MayEtherWallet, Mist wallets etc. Do not use the address if you don’t have a private key of the your address. You WILL NOT receive FGC Tokens and WILL LOSE YOUR FUNDS if you do.</p>
                            </div>
                            <div className="gaps-3x" />
                            <div className="d-sm-flex justify-content-between align-items-center">
                                <button className="btn btn-primary" onClick={submit}>Add Wallet</button>
                                <div className="gaps-2x d-sm-none" />
                                {/* <span className="text-success"><em className="ti ti-check-box" /> Added wallet address</span> */}
                            </div>
                        </form>{/* form */}
                    </div>
                </div>{/* .modal-content */}
            </div>{/* .modal-dialog */}
        </div>
    )
}

const stp = (state: any) => ({
    user: state.auth
});

const dtp = {
    updateUser: updateSession
}

export const GetWalletAdressModal = connect(stp, dtp)(GetWalletAdressModalComponent);
