export function WesternUnionBtn() {
    return (
        <div className="accordion" id="accordion-wester-union-btn">
            <div className="accordion-item">
                <button className="btn btn-primary w-100" style={{ color: '#000', backgroundColor: '#ffe810', border: 'none' }} data-toggle="collapse" data-target="#collapse-western-union">
                    <div className="d-flex justify-content-between">
                        <span>Western Union</span>
                        <span><img src="./images/western-union.png" style={{
                            height: '30px',
                            position: 'absolute',
                            top: '5px',
                            right: '10px',
                        }} alt="" /></span>
                    </div>
                </button>
                <div id="collapse-western-union" className="collapse" data-parent="#accordion-wester-union-btn">
                    <div className="accordion-content mt-3">
                        <div>
                            <div className='text-primary'>
                                Western Union is a global money transfer service where individuals can only transfer money to other individuals. To purchase ICO Tokens using Western Union, please transfer the payment using the details below. The recipient is our Chief Operations Director. Please ensure you retain a copy of your transfer receipt for verification purposes. Additionally, email us the reference MTCN number immediately at info@footballgoalcoin.com.
                            </div>
                            <div className="gaps-1x" />
                            <div>
                                Account name: Mr. Manjap Singh
                            </div>
                            <div>
                                Email: fgc@footballgoalcoin.com
                            </div>
                            <div>
                                Barclays: 20-11-81
                            </div>
                            <div>
                                Account: 60185175
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}