import { combineReducers } from "redux";
import { authReducer } from "./auth.store";
import { configReducer } from "./config.store";
import { exchangeRateReducer } from "./exchange-rate.store";
import { totalTokenSaleReducer } from "./token-sale.store";
import { walletDetailsReducer } from "./wallet-details.store";

export const rootReducer = combineReducers({
    auth: authReducer,
    walletDetails: walletDetailsReducer,
    exchangeRate: exchangeRateReducer,
    config: configReducer,
    totalTokenSale: totalTokenSaleReducer,
});
