import {handleError, httpGet, httpPut} from "../../services/http.service";
import axios from "axios";
import {toast} from "react-toastify";

interface ProfileResponse {
    message: string;
    user: object;
}

interface UpdateProfilePayload {

}

interface UpdateProfileResponse {
    message: string;
    user: object;
}

interface UpdateWalletPayload {

}

async function getProfileData() {
    return (await httpGet<ProfileResponse>("my/profile"));
}

async function updateProfileData(payload: UpdateProfilePayload): Promise<ProfileResponse> {
    try {
        return await httpPut<ProfileResponse>("my/profile", payload);
    } catch (e) {
        throw handleError(e);
    }
}

async function updateWalletAddress(payload: UpdateWalletPayload) {
    return (await httpPut('my/update-receiving-wallet-address', payload))
}

export {
    getProfileData,
    updateProfileData,
    updateWalletAddress
}
