import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ContributionCard } from '../../components/contribution-card.component';
import { TokenCalculation } from '../../components/token-calculation';
import { TokenSalesProgressCard } from '../../components/token-sales-progress-card.component';
import { getFGCCountForUSD } from '../../helpers/currency.helper';
import { readableNum } from '../../helpers/ui.helper';
import { createSession } from '../../store/auth.store';
import { StoreState } from '../../types/store-state.type';
import { TransactionTable } from './transaction-table';
import { Banner } from '../../components/banner';
import { MobileOnlyPageHeading } from '../../components/mobile-only-page-heading';
import cx from "classnames";

import './dashboard.scss';

function DashboardPageComponent({
    // state
    user,
    exchangeRate,
    // dispatch
    setUser,
}: any) {

    const bitcoinPrice = exchangeRate.bitcoin.usd;
    const ethereumPrice = exchangeRate.ethereum.usd;

    // get the conversion rates
    useEffect(() => {
        fetchPrices();
    }, []);
    const fetchPrices = async () => {
    };

    // load chart
    // useEffect(() => {
    //
    {/*    // Line Chart*/
    }
    {/*    var lineChart = 'tknSale';*/
    }
    {/*    if ((window as any).$('#' + lineChart).length > 0) {*/
    }
    {/*        var lineCh = (document.getElementById(lineChart) as any).getContext("2d");*/
    }

    {/*        var chart = (window as any).Chart(lineCh, {*/
    }
    {/*            // The type of chart we want to create*/
    }
    {/*            type: 'line',*/
    }

    //             // The data for our dataset
    //             data: {
    {/*                labels: ["01 Oct", "02 Oct", "03 Oct", "04 Oct", "05 Oct", "06 Oct", "07 Oct"],*/
    }
    {/*                datasets: [{*/
    }
    {/*                    label: "",*/
    }
    {/*                    tension: 0.4,*/
    }
    {/*                    backgroundColor: 'transparent',*/
    }
    //                     borderColor: '#2c80ff',
    //                     pointBorderColor: "#2c80ff",
    //                     pointBackgroundColor: "#fff",
    {/*                    pointBorderWidth: 2,*/
    }
    {/*                    pointHoverRadius: 6,*/
    }
    {/*                    pointHoverBackgroundColor: "#fff",*/
    }
    {/*                    pointHoverBorderColor: "#2c80ff",*/
    }
    {/*                    pointHoverBorderWidth: 2,*/
    }
    {/*                    pointRadius: 6,*/
    }
    {/*                    pointHitRadius: 6,*/
    }
    {/*                    data: [110, 80, 125, 55, 95, 75, 90],*/
    }
    {/*                }]*/
    }
    {/*            },*/
    }

    {/*            // Configuration options go here*/
    }
    {/*            options: {*/
    }
    {/*                legend: {*/
    }
    {/*                    display: false*/
    }
    {/*                },*/
    }
    //                 maintainAspectRatio: false,
    //                 tooltips: {
    //                     callbacks: {
    //                         title: function (tooltipItem: any, data: any) {
    {/*                            return 'Date : ' + data['labels'][tooltipItem[0]['index']];*/
    }
    {/*                        },*/
    }
    {/*                        label: function (tooltipItem: any, data: any) {*/
    }
    {/*                            return data['datasets'][0]['data'][tooltipItem['index']] + ' Tokens';*/
    }
    {/*                        }*/
    }
    {/*                    },*/
    }
    //                     backgroundColor: '#eff6ff',
    //                     titleFontSize: 13,
    {/*                    titleFontColor: '#6783b8',*/
    }
    {/*                    titleMarginBottom: 10,*/
    }
    {/*                    bodyFontColor: '#9eaecf',*/
    }
    {/*                    bodyFontSize: 14,*/
    }
    {/*                    bodySpacing: 4,*/
    }
    {/*                    yPadding: 15,*/
    }
    {/*                    xPadding: 15,*/
    }
    //                     footerMarginTop: 5,
    //                     displayColors: false
    //                 },
    {/*                scales: {*/
    }
    {/*                    yAxes: [{*/
    }
    {/*                        ticks: {*/
    }
    {/*                            beginAtZero: true,*/
    }
    {/*                            fontSize: 12,*/
    }
    {/*                            fontColor: '#9eaecf',*/
    }

    //                         },
    //                         gridLines: {
    {/*                            color: "#e5ecf8",*/
    }
    {/*                            tickMarkLength: 0,*/
    }
    {/*                            zeroLineColor: '#e5ecf8'*/
    }
    {/*                        },*/
    }

    {/*                    }],*/
    }
    //                     xAxes: [{
    //                         ticks: {
    //                             fontSize: 12,
    {/*                            fontColor: '#9eaecf',*/
    }
    //                             source: 'auto',
    //                         },
    //                         gridLines: {
    //                             color: "transparent",
    //                             tickMarkLength: 20,
    //                             zeroLineColor: '#e5ecf8',
    //                         },
    //                     }]
    //                 }
    //             }
    //         });
    //     }
    // }, []);

    return (
        <div className="DashboardPage">
            <div className="page-content">
                <div className="container">
                    <Banner images={['/images/dashboard_1.jpg', '/images/dashboard_2.png', '/images/dashboard_3.jpg']}></Banner>
                    <div className="row">
                        <MobileOnlyPageHeading title="Overview" />
                        <div className="col-lg-4">
                            <ContributionCard />
                        </div>
                        {/* .col */}
                        <div className='col-md-12 d-block d-lg-none mb-3 mb-sm-4'>
                            <div className="d-flex flex-wrap" style={{ gap: '10px' }}>
                                <Link to={user.kycStatus === 'approved' ? '' : "/kyc-application"} className={cx("btn btn-primary flex-grow-1 kyc-status align-items-center d-flex justify-content-center",
                                    {
                                        'warning': user.kycStatus === 'pending',
                                        'info': user.kycStatus === 'verifying',
                                        'success': user.kycStatus === 'approved',
                                        'error': user.kycStatus === 'rejected'
                                    })}>
                                    <div className='d-flex flex-column'>
                                        <span style={{ fontSize: '1.9em' }}>KYC</span>
                                        <span style={{ fontSize: '1em', textTransform: 'capitalize' }}>{user.kycStatus}</span>
                                    </div>
                                </Link>
                                <Link to="/buy-token" className="btn btn-primary buy-now flex-grow-1 buy-now align-items-center d-flex justify-content-center">
                                    <div className='d-flex flex-column'>
                                        <span style={{ fontSize: '1.5em' }}><em className="ti ti-wallet" />&nbsp;&nbsp;Buy Now</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className="token-information card card-full-height">
                                <div className="row no-gutters height-100">
                                    <div className="col-md-6 text-center d-lg-none">
                                        <img src="/images/money_back_guarantee.png" alt="" />
                                    </div>
                                    <div className="col-md-6 text-center d-none d-lg-block">
                                        <div className="token-info">
                                            <img className="token-info-icon" src="images/logo-sm.png" alt="logo-sm" />
                                            {/*<div className="gaps-2x" />*/}
                                            <h1 className="token-info-head text-light">1 ETH
                                                = {readableNum(getFGCCountForUSD(ethereumPrice ?? 0))} FGC</h1>
                                            <h5 className="token-info-sub">1 ETH
                                                = {readableNum(ethereumPrice ?? 0)} USD</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="token-info bdr-tl">
                                            <div>
                                                <ul className="token-info-list">
                                                    <li><span>Token Name:</span>Football Goal Coin</li>
                                                    <li><span>Ticket Symbol:</span>FGC</li>
                                                </ul>
                                                <a href="/assets/Football_Goal_Coin_FGC_Whitepaper_2023_v.1.pdf"
                                                    target="_blank" className="btn btn-primary">
                                                    <em className="fas fa-download mr-3" />Download Whitepaper
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* .card */}
                        </div>
                        {/* .col */}
                        <div className="col-xl-8 col-lg-7">
                            <TransactionTable />
                        </div>
                        <TokenCalculation />
                    </div>
                </div>
                {/* .container */}
            </div>
            {/* .page-content */}
            {/* JavaScript (include all script here) */}
        </div>
    )
}

const stp = (state: StoreState) => ({
    user: state.auth,
    exchangeRate: state.exchangeRate,
});

const dtp = {
    setUser: createSession
}

export const DashboardPage = connect(stp, dtp)(DashboardPageComponent);
