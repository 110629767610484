
// admin

import { KycDetail } from "../types/generic/kyc-details.type";
import GenericPaginatedResponse from "../types/responses/generic-paginated-response.type";
import InitKycResponse from "../types/responses/init-kyc-response.type";
import KycResponse from "../types/responses/kyc-response.type";
import VerifyKycResponse from "../types/responses/verify-kyc-response.type";
import { httpGet, httpPost } from "./http.service";

// user
const initKycSession = async (): Promise<InitKycResponse> => {
    return httpPost<InitKycResponse>("kyc-session/init", {});
}

const verifyKyc = async (): Promise<VerifyKycResponse> => {
    return httpGet<VerifyKycResponse>("kyc-session/verify");
}

// admin

const getKycList = async (pageStart: number): Promise<GenericPaginatedResponse<KycDetail>> => {
    // return httpGet<GenericPaginatedResponse<KycDetail>>("admin/kyc-details/all?pageStart=" + pageStart);
    return httpGet<GenericPaginatedResponse<KycDetail>>("admin/kyc-details/list?pageStart=" + pageStart);
}

const approveKyc = async (id: string): Promise<KycResponse> => {
    return httpGet<KycResponse>("admin/kyc-details/" + id + "/approve");
}

const rejectKyc = async (id: string): Promise<KycResponse> => {
    return httpGet<KycResponse>("admin/kyc-details/" + id + "/reject");
}

const deleteKyc = async (id: string): Promise<KycResponse> => {
    return httpGet<KycResponse>("admin/kyc-details/" + id + "/delete");
}

// yoti kyc list
const getYotiKycList = async (pageStart: number): Promise<GenericPaginatedResponse<KycDetail>> => {
    // return httpGet<GenericPaginatedResponse<KycDetail>>("admin/kyc-details/all?pageStart=" + pageStart);
    return httpGet<GenericPaginatedResponse<KycDetail>>("admin/kyc-details/yoti/list?pageStart=" + pageStart);
}

const approveYotiKyc = async (id: string): Promise<KycResponse> => {
    return httpGet<KycResponse>("admin/kyc-details/yoti/" + id + "/approve");
}

const rejectYotiKyc = async (id: string): Promise<KycResponse> => {
    return httpGet<KycResponse>("admin/kyc-details/yoti/" + id + "/reject");
}

const deleteYotiKyc = async (id: string): Promise<KycResponse> => {
    return httpGet<KycResponse>("admin/kyc-details/yoti/" + id + "/delete");
}


export {
    initKycSession,
    verifyKyc,
    getKycList,
    approveKyc,
    rejectKyc,
    deleteKyc,
    getYotiKycList,
    approveYotiKyc,
    rejectYotiKyc,
    deleteYotiKyc,
}
