import React from 'react';
import { Transaction } from '../../types/generic/transaction.type';
import { GenericModalProps } from '../../types/props/generic-modal.props';
import { TransactionDetailsComponent } from '../transaction-details.component';

interface TransactionDetailsModalProps extends GenericModalProps {
    transaction?: Transaction;
    onClose: Function;
}

export function TransactionDetailsModal({
    modalId,
    transaction,
    onClose,
}: TransactionDetailsModalProps) {
    return (
        <div className="modal fade" id={modalId} tabIndex={-1}>
            <div className="modal-dialog modal-dialog-lg modal-dialog-centered">
                <div className="modal-content">
                    <a href="#" className="modal-close" data-dismiss="modal" aria-label="Close" onClick={e => {
                        e.preventDefault();
                        // wait half second before triggering
                        setTimeout(() => {
                            onClose();
                        }, 500);
                    }}><em className="ti ti-close" /></a>
                    {
                        transaction ? (
                            <div className="popup-body popup-body-lg">
                                <TransactionDetailsComponent transaction={transaction} />
                            </div>
                        ) : <></>
                    }
                </div>{/* .modal-content */}
            </div>
        </div>
    )
}