import React, { useEffect, useState } from 'react';
import { getProfileData, updateProfileData } from "./profile.api.helper";
import { ProfileDetailsSection } from "./profile-details-section";
import { FormInput } from "../../components/atoms/form-input.atom";
import { Spinner } from "../../components/spinner";
import './profile.page.scss'
import { User } from "../../types/generic/user.type";
import { AccountStatusSection } from "./account-status-section";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { StoreState } from "../../types/store-state.type";
import { isKYCApproved, isKYCPending } from '../../helpers/kyc.helper';
import { MobileOnlyPageHeading } from '../../components/mobile-only-page-heading';

function ProfilePageComponent({ user }: any) {

    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const [errors, setErrors] = useState<any>({});
    const [profileData, setProfileData] = useState<any>({});

    const updateProfile = () => {
        const payload = {};
        updateProfileData(payload).then((response) => {

        })
    }

    return (
        <div className="ProfilePage">
            <div className="page-profile">
                <div className="page-content">
                    <div className="container">
                        <div className="row">
                            <MobileOnlyPageHeading title="Profile" />
                        </div>
                        <div className="row">
                            <div className="main-content col-lg-8">
                                <ProfileDetailsSection />
                                {/* .card */}
                                {/* <div className="content-area card">
                                    <div className="card-innr">
                                        <div className="card-head">
                                            <h4 className="card-title">Two-Factor Verification</h4>
                                        </div>
                                        <p>Two-factor authentication is a method for protection your web account. When
                                            it is activated you need to enter not only your password, but also a special
                                            code. You can receive this code by in mobile app. Even if third person will
                                            find your password, then can't access with that code.</p>
                                        <div className="d-sm-flex justify-content-between align-items-center pdt-1-5x">
                                            <span className="text-light ucap d-inline-flex align-items-center">
                                                <span className="mb-0"><small>Current Status:</small></span>
                                                <span className="badge badge-disabled ml-2">Disabled</span>
                                            </span>
                                            <div className="gaps-2x d-sm-none" />
                                            <button className="order-sm-first btn btn-primary">Enable 2FA</button>
                                        </div>
                                    </div>
                                </div> */}
                                {/* .card */}
                            </div>
                            {/* .col */}
                            <div className="aside sidebar-right col-lg-4">
                                <AccountStatusSection />
                                <div className="referral-info card">
                                    <div className="card-innr">
                                        <h6 className="card-title card-title-sm">Earn with FGC</h6>
                                        <p className=" pdb-0-5x">Invite your friends &amp; family and receive a bonus
                                            {/* <strong><span className="text-primary">bonus - 15%</span> of the value of contribution.</strong> */}
                                        </p>
                                        <div className="copy-wrap mgb-0-5x">
                                            <span className="copy-feedback" />
                                            <em className="fas fa-link" />
                                            <input type="text" className="copy-address"
                                                defaultValue={`${process.env.REACT_APP_PANEL_URL}register?ref=${user.referralCode}`}
                                                disabled />
                                            <button className="copy-trigger copy-clipboard"
                                                data-clipboard-text={`${process.env.REACT_APP_PANEL_URL}register?ref=${user.referralCode}`}>
                                                <em className="ti ti-files" /></button>
                                        </div>
                                        {/* .copy-wrap */}
                                    </div>
                                </div>
                                {
                                    isKYCApproved(user) ? <></> : (
                                        <div className="kyc-info card">
                                            <div className="card-innr">
                                                <h6 className="card-title card-title-sm">Identity Verification - KYC</h6>
                                                <p>To comply with regulations, all participants must complete the identity verification process.</p>
                                                {
                                                    isKYCPending(user) ? (
                                                        <>
                                                            <p className="lead text-light pdb-0-5x">You have not submitted your KYC
                                                                application to verify your identity.</p>
                                                            <Link to='/kyc-application'>
                                                                <button className="btn btn-primary btn-block" disabled={['verifying', 'approved'].includes(user?.kycStatus)}>Click to Proceed</button>
                                                            </Link>
                                                        </>
                                                    ) : <></>
                                                }
                                                <h6 className="kyc-alert text-danger">* KYC verification required for purchase.</h6>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                            {/* .col */}
                        </div>
                        {/* .container */}
                    </div>
                    {/* .container */}
                </div>
            </div>
        </div >
    )
}

const stp = (state: StoreState) => ({
    user: state.auth
});
const dtp = {
}

export const ProfilePage = connect(stp, dtp)(ProfilePageComponent)
