import React from 'react';
import { FormErrorList } from './form-error-list.atom';

type FormInputProps = {
    options: { name: string, value: string | number }[],
    value: string,
    onChange: Function,
    errors?: string[],
    disabled?: boolean,
}

export function FormSelect({
    value = "",
    errors,
    onChange = (value: string) => {
    },
    options = [],
    disabled = false,
}: FormInputProps) {
    const selectOptions = options.map(item => {
        return <option value={item.value}>{item.name}</option>
    });
    return (
        <div className="input-item">
            <select
                title="Form Select"
                value={value}
                onChange={e => {
                    onChange(e.target.value);
                }}
                disabled={disabled}
                className="input-bordered">
                {selectOptions}
            </select>
            <FormErrorList errors={errors} />
        </div>
    )
}
