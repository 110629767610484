interface MobileOnlyPageHeadingProps {
    title: string
}

export function MobileOnlyPageHeading({ title }: MobileOnlyPageHeadingProps) {
    return (
        <div className='col-md-12 d-lg-none'>
            <div className="text-white h1" style={{ fontSize: '1.7em' }}>{title}</div>
        </div>
    )
}