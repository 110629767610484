import React, { useCallback, useRef, useState } from 'react';
import Webcam from "react-webcam";
import "./image-capture.scss";

export function ImageCapture({
    setImage = (image: any) => { },
}) {
    const webcamRef = useRef<any>(null); // create a webcam reference
    const [imgSrc, setImgSrc] = useState<any>(null); // initialize it

    const [isWebcamInitiated, setIsWebcamInitiated] = useState(false);

    const startWebcam = () => {
        setIsWebcamInitiated(true);
    }

    // create a capture function
    const capture = useCallback(async () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImgSrc(imageSrc);
        setImage(await getImage(imageSrc));
    }, [webcamRef]);

    const reset = () => {
        setImgSrc(null);
        setImage(null);
    }

    const getImage = async (imageSrc: string) => {
        const image = await ((await fetch(imageSrc)).blob());
        return image;
    }

    return (
        <div className="ImageCapture">
            {
                isWebcamInitiated ? (
                    <>
                        {imgSrc ? (
                            <div className="image-preview">
                                <img src={imgSrc} alt="webcam" />
                                <button type="button" className="btn btn-sm btn-outline btn-light" onClick={reset}>Retake</button>
                            </div>
                        ) : (<></>)}
                        <div className={"camera-holder " + (imgSrc ? "hidden" : "block")}>
                            <Webcam ref={webcamRef} />
                            <button type="button" className="btn btn-sm btn-outline btn-light" onClick={capture}>Capture</button>
                        </div>
                    </>
                ) : (
                    <button type="button" className="btn btn-sm btn-outline btn-light" onClick={startWebcam}>Start Webcam</button>
                )
            }
        </div>
    )
}