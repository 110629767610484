import React from 'react';
import { Link } from 'react-router-dom';
import { closeModal } from '../../helpers/modal.helper';
import { GenericModalProps } from '../../types/props/generic-modal.props';

export function PayReviewModal({
    modalId,
}: GenericModalProps) {
    return (
        <div className="modal fade" id={modalId} tabIndex={-1}>
            <div className="modal-dialog modal-dialog-md modal-dialog-centered">
                <div className="modal-content">
                    <div className="popup-body text-center">
                        <div className="gaps-2x" />
                        <div className="pay-status pay-status-success">
                            <em className="ti ti-check" />
                        </div>
                        <div className="gaps-2x" />
                        <h3>We’re reviewing your payment.</h3>
                        <p>We’ll review your transaction and get back to your within 6 hours. You’ll receive an email with the details of your contribution.</p>
                        <div className="gaps-2x" />
                        <Link to="/transactions" className="btn btn-primary" onClick={() => {
                            closeModal(modalId);
                        }}>View Transaction</Link>
                        <div className="gaps-1x" />
                    </div>
                </div>{/* .modal-content */}
            </div>{/* .modal-dialog */}
        </div>
    )
}