import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { httpErrorToast } from '../../helpers/ui.helper';
import { getCheckoutLink } from '../../services/checkout.service';
import { Spinner } from '../spinner';

export function BuyTokenCheckoutButton({
    selectedAmount
}: {
    selectedAmount: number
}) {

    const [isLoading, setIsLoading] = useState(false);

    const onClick = async (e: any) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            const { checkoutLink } = await getCheckoutLink(selectedAmount);
            window.location.href = checkoutLink;
            setIsLoading(false);
        } catch (error) {
            httpErrorToast(error);
            setIsLoading(false);
        }
    }

    return (
        <a
            href="#"
            className="btn btn-primary btn-between w-100"
            onClick={onClick}>

            {
                isLoading ? (
                    <Spinner white />
                ) : (
                    <>
                        <div className="d-flex justify-content-between">
                            <span>Debit Card or Credit Card ot Paypal</span>
                            <span>
                                <img src="./images/card.png" style={{
                                    height: '65px',
                                    position: 'absolute',
                                    top: '-14px',
                                    right: '-20px'
                                }} alt="" />
                            </span>
                        </div>
                    </>
                )
            }
        </a>
    )
}