import { getUserWalletDetails } from "../../services/auth.service";
import { WalletDetails } from "../../types/generic/wallet-details.type";

export const GET_WALLET_DETAILS = "";
export const SET_WALLET_DETAILS = "SET_WALLET_DETAILS";

export function setWalletDetailsAction(walletDetails: WalletDetails) {
    return {
        type: SET_WALLET_DETAILS,
        walletDetails,
    }
}

// thunk
export function getWalletDetails() {
    return async (dispatch: any, getState: any) => {
        const { walletDetails } = await getUserWalletDetails();
        dispatch(setWalletDetailsAction(walletDetails));
    }
}

export function walletDetailsReducer(state = {
    eth: 0,
    btc: 0,
    fgc: 0,
    usd: 0,
}, action: any) {
    switch (action.type) {
        case SET_WALLET_DETAILS:
            return action.walletDetails;
        default:
            return state;
    }
}
