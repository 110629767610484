import React, { FormEvent, useState } from 'react';
import { StoreState } from "../../types/store-state.type";
import { updateSession } from "../../store/auth.store";
import { connect } from "react-redux";
import cx from "classnames";
import { FormSelect } from "../../components/atoms/form-select.atom";
import { FormInput } from "../../components/atoms/form-input.atom";
import { updateWalletAddress } from "./profile.api.helper";
import axios from "axios";
import { toast } from "react-toastify";
import { Spinner } from "../../components/spinner";

function AccountStatusSectionComponent({ user }: any) {

    const [receivingWalletType, setReceivingWalletType] = useState('eth');
    const [receivingWalletAddress, setReceivingWalletAddress] = useState('0xde0b295669a9fd93d5f28d9ec85e40f4cb697bae');
    const [errors, setErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);

    const updateWallet = (e: FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        const payload = {
            receivingWalletType,
            receivingWalletAddress
        };
        updateWalletAddress(payload).then(r => {
            console.log(r)
            setIsLoading(false);
        }, (error) => {
            if (axios.isAxiosError(error)) {
                if (error?.response?.status === 422) {
                    // validation error
                    toast.error("Some validation error occurred");
                    setErrors(error?.response?.data.errors);
                } else {
                    toast.error(error.response?.data.message);
                }
            } else {
                toast.error("Something went wrong");
                console.log("Error", error);
            }
            setIsLoading(false);
        })
    }

    return (
        <div className="account-info card">
            <div className="card-innr">
                <h6 className="card-title card-title-sm">Your Account Status</h6>
                <ul className="btn-grp">
                    {/*<li>*/}
                    {/*    <a href="#"*/}
                    {/*        className={*/}
                    {/*            cx('btn', 'btn-auto', 'btn-xs',*/}
                    {/*                { 'btn-success': user?.isEmailVerified },*/}
                    {/*                { 'btn-danger': !(user?.isEmailVerified) }*/}
                    {/*            )*/}
                    {/*        }>*/}
                    {/*        {user?.isEmailVerified ? 'Email Verified' : 'Email not Verified'}*/}
                    {/*    </a>*/}
                    {/*</li>*/}
                    <li>
                        <a href="#"
                            className={
                                cx('btn', 'btn-auto', 'btn-xs',
                                    { 'btn-warning': user?.kycStatus === 'pending' },
                                    { 'btn-warning': user?.kycStatus === 'verifying' },
                                    { 'btn-success': user?.kycStatus === 'approved' },
                                    { 'btn-danger': user?.kycStatus === 'rejected' },
                                )
                            }>
                            KYC <span style={{ textTransform: 'capitalize' }}>{user?.kycStatus}</span>
                        </a>
                    </li>
                </ul>
                {/* <div className="gaps-2-5x" /> */}
                {/* <h6 className="card-title card-title-sm">Receiving Wallet</h6>
                <div className="d-flex justify-content-between">
                    <span><span>0x39deb3.....e2ac64rd</span>
                        <em className="fas fa-info-circle text-exlight" data-toggle="tooltip"
                            data-placement="bottom" title="1 ETH = 100 FGC" />
                    </span>
                    <a href="#" data-toggle="modal" data-target="#edit-wallet"
                        className="link link-ucap">Edit</a>
                </div> */}
            </div>
            <div className="modal fade" id="edit-wallet" tabIndex={-1}>
                <div className="modal-dialog modal-dialog-md modal-dialog-centered">
                    <div className="modal-content">
                        <a href="#" className="modal-close" data-dismiss="modal" aria-label="Close"><em
                            className="ti ti-close" /></a>
                        <div className="popup-body">
                            <h4 className="popup-title">Wallet Address</h4>
                            <p>In order to receive your <a href="#"><strong>FGC Tokens</strong></a>, please select
                                your wallet address and you have to put the address below input box. <strong>You
                                    will receive FGC tokens to this address after the Token Sale end.</strong></p>
                            <form action="#">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="input-item input-with-label">
                                            <label htmlFor="swalllet" className="input-item-label">
                                                Select Wallet
                                            </label>
                                            <FormSelect onChange={setReceivingWalletType} options={[
                                                { name: 'Ethereum', value: 'eth' },
                                                { name: 'DashCoin', value: 'dac' },
                                                { name: 'BitCoin', value: 'bic' },
                                            ]} value={receivingWalletType} />
                                        </div>
                                        {/* .input-item */}
                                    </div>
                                    {/* .col */}
                                </div>
                                {/* .row */}
                                <div className="input-item input-with-label">
                                    <label htmlFor="token-address" className="input-item-label">
                                        Your Address for tokens:
                                    </label>
                                    <FormInput onChange={setReceivingWalletAddress} value={receivingWalletAddress}
                                        errors={errors?.receivingWalletAddress} />
                                    <span className="input-note">Note: Address should be ERC20-compliant.</span>
                                </div>
                                {/* .input-item */}
                                <div className="note note-plane note-danger">
                                    <em className="fas fa-info-circle" />
                                    <p>DO NOT USE your exchange wallet address such as Kraken, Bitfinex, Bithumb,
                                        Binance etc. You can use MetaMask, MayEtherWallet, Mist wallets etc. Do not
                                        use the address if you don’t have a private key of the your address. You
                                        WILL NOT receive FGC Tokens and WILL LOSE YOUR FUNDS if you do.</p>
                                </div>
                                <div className="gaps-3x" />
                                <div className="d-sm-flex justify-content-between align-items-center">
                                    <button className="btn btn-primary" disabled={isLoading} onClick={updateWallet}>
                                        {isLoading ? <Spinner white /> : 'Update Wallet'}</button>
                                    {/*<div className="gaps-2x d-sm-none" />*/}
                                    {/*{isLoading ?*/}
                                    {/*    <div className="text-success" style={{ display: 'flex' }}>*/}
                                    {/*        Updating&nbsp;...&nbsp;&nbsp;&nbsp;<Spinner green />*/}
                                    {/*    </div> :*/}
                                    {/*    <span className="text-success"><em className="ti ti-check-box" /> Updated wallet address</span>*/}
                                    {/*}*/}
                                </div>
                            </form>
                            {/* form */}
                        </div>
                    </div>
                    {/* .modal-content */}
                </div>
                {/* .modal-dialog */}
            </div>
        </div>

    );
}

const stp = (state: StoreState) => ({
    user: state.auth
});
const dtp = {}
export const AccountStatusSection = connect(stp, dtp)(AccountStatusSectionComponent);
