import React, { useEffect, useState } from 'react';
import cx from "classnames";
import GenericPaginatedResponse from '../../types/responses/generic-paginated-response.type';
import { PaginationButtons } from './pagination-buttons';
import { DataTableShowLoader } from './data-table-show-loader';
import { DataTableShowEmpty } from './data-table-show-empty';

interface ColumnType {
    className?: string;
    title: string | React.ReactElement;
};

type RowType = CellType[];

interface CellType {
    className: string;
    content: any;
}

interface DataTableProps {
    reRender?: string,
    columns: ColumnType[];
    renderRow: Function,
    fetcherMethod: Function,
    dataState?: { // is used to override localstate and manage in parent
        data: any[];
        setData: Function;
    }
    tableClass?: string
}

export function DataTable({
    reRender,
    columns,
    tableClass = "",
    renderRow,
    dataState,
    fetcherMethod,
}: DataTableProps) {

    const [data, setData] = useState<any>([]); // used when dataState is not there
    const [pageStart, setPageStart] = useState(0);
    const [recordsTotal, setRecordsTotal] = useState(0);
    const [recordsFiltered, setRecordsFiltered] = useState(0);
    const [pageLength, setPageLength] = useState(5);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getData();
    }, [reRender]);

    /**
     * Fetch data from api
     */
    async function getData(pageStartParam = 0) {
        setIsLoading(true);
        const {
            message,
            data,
            pageStart,
            recordsTotal,
            recordsFiltered,
            pageLength,
        } = await fetcherMethod(pageStartParam);
        if (!dataState) {
            setData(data);
        } else {
            dataState.setData(data);
        }
        setIsLoading(false);
        setPageStart(pageStart);
        setRecordsTotal(recordsTotal);
        setRecordsFiltered(recordsFiltered);
        setPageLength(pageLength);
    }

    const dataList = dataState ? dataState.data : data;

    return (
        <>
            <table className={cx("data-table dt-init", tableClass)}>
                {/* header */}
                <thead>
                    {/* render header row */}
                    {
                        isLoading ? <></> : (
                            <tr className="data-item data-head">
                                {
                                    columns.map((column, index) => (
                                        <th key={index} className={cx("data-col", column.className)}>
                                            {column.title}
                                        </th>
                                    ))
                                }
                            </tr>
                        )
                    }
                </thead>
                {/* body */}
                <tbody>
                    {
                        isLoading ? (
                            <DataTableShowLoader columnCount={columns.length} rowCount={recordsFiltered} />
                        ) : (
                            dataList.length ? dataList.map((record: any, index: number) => (
                                <tr key={index} className="data-item">
                                    {
                                        renderRow(record).map((cell: any, cellIndex: number) => (
                                            <td key={cellIndex} className={cx("data-col", cell.className)}>
                                                {cell.content}
                                            </td>
                                        ))
                                    }
                                </tr>
                            )) : <DataTableShowEmpty columnCount={columns.length} rowCount={recordsFiltered} />
                        )
                    }
                </tbody>
            </table>
            <PaginationButtons
                pageStart={pageStart}
                recordsTotal={recordsTotal}
                recordsFiltered={recordsFiltered}
                getData={getData}
                pageLength={pageLength} />
        </>
    )
}


/**
 * 
        $data_table.each(function () {
            var $self = $(this);
            $self.DataTable({
                ordering,
                autoWidth,
                dom: '<t><"row align-items-center"<"col-sm-6 text-left"p><"col-sm-6 text-sm-right"i>>',
                pageLength: itemsPerPage,
                bPaginate: totalItems > itemsPerPage,
                iDisplayLength: itemsPerPage,
                language: {
                    search,
                    searchPlaceholder,
                    info: "_START_ - _END_ of _TOTAL_",
                    infoEmpty,
                    infoFiltered: "( Total _MAX_  )",
                    paginate: {
                        first: "First",
                        last: "Last",
                        next: "Next",
                        previous: "Prev"
                    },
                },
                ajax: function (data: any, callback: any, settings: any) {

                }
            });
 */
