import { Link } from "react-router-dom";

function KycThankYouPageComponent() {
    return (
        <>
            <div className="page-header page-header-kyc">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 col-xl-7 text-center">
                            <h2 className="page-title text-white">Begin your ID-verification</h2>
                            <p className="large text-white">
                                Verify your identity to participate in tokensale.
                            </p>
                        </div>
                    </div>
                </div>
                {/* .container */}
            </div>
            {/* .page-header */}
            <div className="page-content">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-xl-9">
                            <div className="kyc-status card mx-lg-4">
                                <div className="card-innr">
                                    <div className="status status-thank px-md-5">
                                        <div className="status-icon">
                                            <em className="ti ti-check" />
                                        </div>
                                        <span className="status-text large text-dark">
                                            You have completed the process of KYC
                                        </span>
                                        <p className="px-md-5">
                                            We are still waiting for your identity verification. Once our
                                            team verified your identity, you will be notified by email.
                                            You can also check your KYC compliance status from your
                                            profile page.
                                        </p>
                                        <Link to='/profile'>
                                            <button className="btn btn-primary">
                                                Back to Profile
                                            </button>
                                        </Link>
                                        <div className="gaps-2x" />
                                        <h6 className="message-blink">Refresh page for KYC/AML update</h6>
                                    </div>
                                </div>
                            </div>
                            {/* .card */}
                            <div className="card mx-lg-4">
                                <div className="card-innr">
                                    <p className="text-light text-center mgmt-1x">
                                        If you have any question, please contact our support team{" "}
                                        <a href="#">info@footballgoalcoin.com</a>.
                                    </p>
                                </div>
                            </div>
                            <div className="gaps-1x" />
                            <div className="gaps-3x d-none d-sm-block" />
                        </div>
                    </div>
                </div>
                {/* .container */}
            </div>
            {/* .page-content */}
        </>

    )
}

export const KycThankYouPage = KycThankYouPageComponent
