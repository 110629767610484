import { Transaction } from "../types/generic/transaction.type";
import GenericPaginatedResponse from "../types/responses/generic-paginated-response.type";
import OrderResponse from "../types/responses/order-response.type";
import TransactionResponse from "../types/responses/transaction-response.type";
import { httpGet, httpPost } from "./http.service"

interface CreateOrderPayload {
    selectedCurrency: string;
    isAgreementAccepted?: boolean;
};

interface PayFromWalletPayload {
    selectedCurrency: string;
    selectedAmount: number;
}

interface CreateTransactionPayload {
    orderId: string;
    fromWalletAddress: string;
};
interface CreateOfflineTransactionPayload {
    fromWalletAddress: string;
    usdAmount: string;
    userId: string;
};

interface AdminTransactionPayload {
    pageStart: number
    pageLength?: number
    search?: string
    afterDate?: string
    beforeDate?: string
    transactionType?: string
    currencyType?: string
}

const createOrder = async (payload: CreateOrderPayload): Promise<OrderResponse> => {
    return httpPost<OrderResponse>("order", payload);
}

const createTransaction = async (payload: CreateTransactionPayload): Promise<TransactionResponse> => {
    return httpPost<TransactionResponse>("transaction", payload);
}

const payFromWallet = async (payload: PayFromWalletPayload): Promise<TransactionResponse> => {
    return httpPost<TransactionResponse>("pay-from-wallet", payload);
}

const getTransactions = async (pageStart: number): Promise<GenericPaginatedResponse<Transaction>> => {
    return httpGet<GenericPaginatedResponse<Transaction>>("transactions?pageStart=" + pageStart);
}

// admin

const getAllUserTransactions = async (filterQueries: AdminTransactionPayload): Promise<GenericPaginatedResponse<Transaction>> => {
    return httpGet<GenericPaginatedResponse<Transaction>>("admin/transactions", {}, filterQueries);
}

const createOfflineTransaction = async (payload: CreateOfflineTransactionPayload): Promise<TransactionResponse> => {
    return httpPost<TransactionResponse>("admin/transactions/offline", payload);
}

export {
    createOrder,
    createTransaction,
    createOfflineTransaction,
    payFromWallet,
    getTransactions,
    // admin,
    getAllUserTransactions,
}
