import TotalTokenSaleResponse from "../types/responses/total-token-sale-response.type";
import { httpGet } from "./http.service";

const getTotalTokenSale = async (): Promise<TotalTokenSaleResponse> => {
    return httpGet<TotalTokenSaleResponse>("reports/total-token-sale");
}

const getTokenSaleByRange = async ():Promise<any> => {
    return httpGet('reports/token-sale');
}

export {
    getTotalTokenSale,
    getTokenSaleByRange,
}
