import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getFGCCountForUSD } from "../../helpers/currency.helper";
import { isAdmin } from "../../helpers/role.helper";
import { getExchangeRate } from "../../services/crypto.service";
import { ExchangeRate } from "../../types/generic/exchange-rate.type";
import { StoreState } from "../../types/store-state.type";
import './token-calculation.css'

function TokenCalculationComponent({ user }: any) {
    const [baseAmount, setBaseAmount] = useState<number>(1);
    const [payCurrency, setPayCurrency] = useState<string>('eth');
    const [calculatedFGC, setCalculatedFGC] = useState<number>(0);
    const [exchangeRate, setExchangeRate] = useState<ExchangeRate>(
        {
            bitcoin: {
                usd: 0
            },
            ethereum: {
                usd: 0
            }
        }
    );
    const userIsAdmin = isAdmin(user);

    useEffect(() => {
        getExchangeRate().then(e => {
            setExchangeRate(e);
        })
    }, []);

    useEffect(() => {
        calculateFGCToken();
    }, [exchangeRate, baseAmount, payCurrency]);

    const handleChange = (field: string, value: string | number) => {
        if (field === 'baseAmount') {
            setBaseAmount(~~value);
        }
        if (field === 'payCurrency') {
            setPayCurrency(`${value}`);
        }
    }

    const calculateFGCToken = () => {
        switch (payCurrency) {
            case 'eth': {
                const calculatedUSD = baseAmount * exchangeRate.ethereum.usd;
                const calculatedFGC = getFGCCountForUSD(calculatedUSD);
                setCalculatedFGC(calculatedFGC);
                break;
            }
            case 'btc': {
                const calculatedUSD = baseAmount * exchangeRate.bitcoin.usd;
                const calculatedFGC = getFGCCountForUSD(calculatedUSD);
                setCalculatedFGC(calculatedFGC);
                break;
            }
        }
    }

    return (
        <div className="col-xl-4 col-lg-5">
            <div className="token-calculator card card-full-height">
                <div className="card-innr d-none d-lg-block">
                    <div className="card-head">
                        <h4 className="card-title">Token Calculation</h4>
                        <p className="card-title-text">Enter amount to calculate token.</p>
                    </div>
                    <div className="token-calc">
                        <div className="token-pay-amount">
                            <input id="token-base-amount" min={1} className="input-bordered input-with-hint" type="number"
                                onChange={(e) => handleChange('baseAmount', e.target.value)} value={baseAmount} />
                            <div className="token-pay-currency" style={{ top: '0px' }}>
                                <select className='select-block' onChange={(e) => handleChange('payCurrency', e.target.value)} value={payCurrency} >
                                    <option value="eth">ETH</option>
                                    <option value="btc">BTC</option>
                                    {/* <option value="ltc">LTC</option> */}
                                    {/* <option value="usd">USD</option> */}
                                </select>
                            </div>
                        </div>
                        <div className="token-received">
                            <div className="token-eq-sign">=</div>
                            <div className="token-received-amount">
                                <h5 className="token-amount">{calculatedFGC.toFixed(4)}</h5>
                                <div className="token-symbol">FGC</div>
                            </div>
                        </div>
                    </div>
                    <div className="token-calc-note note note-plane">
                        <em className="fas fa-info-circle text-light" />
                        <span className="note-text text-light">Amount calculated based current tokens price</span>
                    </div>
                    {
                        userIsAdmin ? <></> :
                            <div className="token-buy">
                                <Link to='/buy-token'>
                                    <button className="btn btn-primary">Buy Tokens</button>
                                </Link>
                            </div>
                    }
                </div>
                <div className="card-innr d-lg-none text-center py-3 px-0">
                    {
                        userIsAdmin ? <></> :
                            <Link to='/buy-token'>
                                <button className="btn btn-primary">Buy Tokens</button>
                            </Link>
                    }
                </div>
            </div>
        </div>
    )
}

const stp = (state: StoreState) => ({
    user: state.auth
});
const dtp = {};

export const TokenCalculation = connect(stp, dtp)(TokenCalculationComponent)
