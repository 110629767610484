import React from "react";

export function TermsAndConditionsModal({
                                            modalId
                                        }: any) {
    return (
        <div className="modal fade" id={modalId} tabIndex={-1}>
            <div className="modal-dialog modal-dialog-md modal-dialog-centered">
                <div className="modal-content">
                    <a href="#" className="modal-close" data-dismiss="modal" aria-label="Close"><em
                        className="ti ti-close"/></a>
                    <div className="popup-body">
                        <h4 className="popup-title">Terms and Conditions</h4>
                        <div className="scrollable-modal">
                            <h4>Introduction</h4>
                            <p>
                                Welcome to Football Goal Coin, our website(wwwfootballgoalcoin.com), Initial Coin
                                Offering
                                (ICO) platform (panel.footballgoalcoin.com). These terms and conditions govern your use
                                of the
                                website(s) / sub-domains and your participation in the ICO. By using our platform, you
                                agree to
                                these terms and conditions.
                            </p>
                            <p>
                                Football Goal Coin services and products are provided by Football Goal Coin Limited,
                                which is
                                registered in England and Wales, with Company number 14443716. For enquiries (including
                                any
                                complaints) please use the contact form on <a
                                href="www.footballgoalcoin.com">www.footballgoalcoin.com</a>.
                            </p>
                            <h4>Conditions of Use</h4>
                            <p>
                                We will provide services to you, which are subject to the conditions stated below. Every
                                time you
                                visit this website, use its services or make a purchase, you accept the following
                                conditions.
                            </p>
                            <h4>General</h4>
                            <p>
                                We reserve all rights to reject, remove any account we deem not to meet the required
                                legal
                                requirements.
                            </p>
                            <h4>Communications</h4>
                            <p>
                                The entire communication with us is electronic. Every time you send us an email or visit
                                our
                                website, you are going to be communicating with us. You hereby consent to receive
                                communications from us. If you subscribe to the news on our website, you are going to
                                receive
                                regular emails from us. We will continue to communicate with you by posting news and
                                notices
                                on our website and by sending you emails. You also agree that all notices, disclosures,
                                agreements, and other communications we provide to you electronically meet the legal
                                requirements that such communications be in writing.
                            </p>
                            <h4>User Account</h4>
                            <p>
                                If you are an owner of an account on www.footballgoalcoin.com or related sub-domain, you
                                are
                                solely responsible for maintaining the confidentiality of your private user details
                                (username and
                                password). You are responsible for all activities that occur under your account or
                                password.
                            </p>
                            <p>
                                We reserve all rights to terminate accounts, edit or remove content and cancel orders at
                                our
                                sole discretion.
                                If you would like to delete your account, please contact us. We shall continue to hold
                                any
                                information which we are legally obliged to do so and in accordance with our policies.
                            </p>
                            <h4>Privacy Policy</h4>
                            <p>
                                Before you continue using our website we advise you to read our Privacy Policy regarding
                                our
                                user data collection. It will help you better understand our practices.
                            </p>
                            <h4>Purpose</h4>
                            <p>
                                The purpose of this website is not intended to offer any financial advice - It is here
                                to describe
                                Football Goal Coin (FGC) and its associated services. You may obtain access to such
                                products
                                and services via www.footballgoalcoin.com and its associated sub-domains, subject to
                                successfully registering for and opening an account. Your use of the
                                www.footballgoalcoin.com
                                is subject to the following or related terms and conditions.
                            </p>
                            <h4>Eligibility</h4>
                            <p>
                                To participate in the ICO, you must be at least 18 years old and have the legal capacity
                                to enter
                                into a contract. If you are accessing the platform on behalf of a company or
                                organisation, you
                                represent and warrant that you have the authority to bind such an entity to these terms
                                and
                                conditions.
                            </p>
                            <h4>Token Sale</h4>
                            <p>
                                We will offer tokens for sale during the ICO. The sale will be conducted in accordance
                                with the
                                terms specified in the FGC Whitepaper. We reserve the right to change the terms of the
                                sale at
                                any time without notice.
                            </p>
                            <h4>Token Issuance</h4>
                            <p>
                                Tokens will be issued to your wallet address after your purchase is confirmed. You are
                                solely
                                responsible for maintaining the security of your wallet and any private keys. We are not
                                responsible for any loss or theft of tokens resulting from your failure to maintain
                                proper security.
                            </p>
                            <h4>Use of Tokens</h4>
                            <p>
                                Tokens are intended to be used solely on our platform. We do not guarantee that tokens
                                will be
                                accepted or traded on any third-party exchange. You agree not to use tokens for any
                                illegal or
                                fraudulent purpose.
                            </p>
                            <h4>Licence and Site Access</h4>
                            <p>
                                We grant you a limited licence to access and make personal use of this website. You may
                                also
                                share this website with others and download and share any of the media content. You are
                                not
                                allowed to download or modify it. This may be done only with written consent from us.
                            </p>
                            <h4>Copyright</h4>
                            <p>
                                Content published on this website (digital downloads, images, texts, graphics, logos) is
                                the
                                property of Football Goal Coin and/or its content creators and protected by
                                international
                                copyright laws.
                            </p>
                            <h4>Disclaimers</h4>
                            <p>
                                We make no representations or warranties regarding the value, usability, or
                                marketability of the
                                tokens. Tokens are sold "as is" and without any warranty of any kind, express or
                                implied.
                            </p>
                            <p>
                                You should consider whether this ICO is a suitable investment for you in light of your
                                financial
                                situation. You should only buy tokens if you are prepared to accept the risk.
                            </p>
                            <p>
                                Like any investment, cryptocurrency and initial coin offerings carry a risk of loss and
                                you should
                                take time to understand how the process works. You should familiarise yourself with our
                                whitepaper here.
                            </p>
                            <h4>Limitation of Liability</h4>
                            <p>
                                In no event shall we be liable for any indirect, incidental, consequential, special, or
                                punitive
                                damages arising out of or in connection with your use of the platform or the tokens.
                            </p>
                            <p>
                                The information on this website or related sub-domain does not constitute investment
                                advice,
                                nor a solicitation or recommendation for you to buy or sell any cryptocurrency or any
                                related
                                financial instrument including during the initial coin offering. You are responsible for
                                your own
                                decisions. Please ensure you understand all risks and seek independent advice if
                                necessary.
                            </p>
                            <p>
                                Please note that the availability of FGC or any of its products and services is subject
                                to lawful
                                limitations.
                            </p>
                            <h4>Governing Law and Jurisdiction</h4>
                            <p>
                                These terms and conditions shall be governed by and construed in accordance with the
                                laws of
                                England & Wales. Any disputes arising out of or in connection with these terms and
                                conditions
                                shall be resolved through arbitration in accordance with the rules of the English Law.
                            </p>
                            <h4>Amendments</h4>
                            <p>
                                We reserve the right to amend these terms and conditions at any time without notice.
                                Your
                                continued use of the platform after any such amendment may constitute your acceptance of
                                the
                                amended terms and conditions.
                            </p>
                            <h4>Entire Agreement</h4>
                            <p>
                                These terms and conditions constitute the entire agreement between you and us with
                                respect to
                                your use of the platform and your participation in the ICO.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
