
import {
    createBrowserRouter,
    Navigate,
    Outlet,
} from "react-router-dom";
import { Error404 } from "../pages";
import { Header } from "../components/header.component";
import { dashboardRoutes } from "./dashboard.routes";
import { guestRouteChildren } from "./guest.routes";
import { Footer } from "../components/footer.component";
import { AuthRoute } from "./route-auth-checker";
import { User } from "../types/generic/user.type";
import { isAdmin } from "../helpers/role.helper";

function generateRoutes(user: User | null) {

    const authRoutes = user ? [
        {
            path: "/", // dashboard
            element: (
                <AuthRoute>
                    <Header />
                    <Outlet />
                    <Footer />
                </AuthRoute>
            ),
            children: dashboardRoutes(user)
        }
    ] : [];

    return createBrowserRouter([
        {
            path: "/", // homepage
            element: <><Outlet /></>,
            errorElement: user ? <Error404 /> : <Navigate to="/login" />,
            children: guestRouteChildren,
        },
        ...authRoutes,
    ])
}

export default generateRoutes;
