import { FormInput } from "../../components/atoms/form-input.atom";
import { Spinner } from "../../components/spinner";
import React, { FormEvent, useState } from "react";
import { updateProfileData } from "./profile.api.helper";
import { connect } from "react-redux";
import { StoreState } from "../../types/store-state.type";
import { updateSession } from "../../store/auth.store";
import { updatePassword } from "../../services/auth.service";
import { axiosSetError, httpErrorToast } from "../../helpers/ui.helper";
import { toast } from "react-toastify";

function PasswordChangeSectionComponent({ user }: any) {
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [cfmNewPassword, setCfmNewPassword] = useState('');
    const [errors, setErrors] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);

    const clearForm = () => {
        setOldPassword("");
        setNewPassword("");
        setCfmNewPassword("");
    }

    const submit = async (e: FormEvent) => {
        e.preventDefault();
        setErrors({});

        // check password validation

        if (oldPassword.trim() === '') {
            setErrors({ oldPassword: ['Passwords can not be empty.'] });
            return
        }
        if (newPassword.trim() === '') {
            setErrors({ newPassword: ['Passwords can not be empty.'] });
            return
        }
        if (!isPasswordValid(newPassword)) {
            setErrors({
                'newPassword': ['Please enter a strong Password.', ' Password should be minimum of 8 letters and with at least a symbol, upper and lower case letters and\n' +
                    '                    a number']
            });
            return
        }
        if (cfmNewPassword.trim() === '') {
            setErrors({ 'cfmNewPassword': ['Passwords can not be empty.'] });
            return
        }
        if (newPassword !== cfmNewPassword) {
            setErrors({
                newPassword: ['Passwords do not match.'],
                cfmNewPassword: ['Passwords do not match.']
            });
            return
        }
        const payload = {
            oldPassword,
            newPassword,
        };
        setIsLoading(true);
        try {
            const response = await updatePassword(payload);
            toast.success(response.message);
            clearForm();
        } catch (error) {
            httpErrorToast(error);
            axiosSetError(error, setErrors);
        }
        setIsLoading(false);
        // clear form
    }

    const isPasswordValid = (password: string) => {
        // min 8 letter password, with at least a symbol, upper and lower case letters and a number
        const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
        return re.test(password);
    }

    return (
        <div className="tab-pane fade" id="password">
            <div className="row">
                <div className="col-md-6">
                    <div className="input-item input-with-label">
                        <label htmlFor="old-pass" className="input-item-label">Old Password</label>
                        <FormInput onChange={setOldPassword} value={oldPassword}
                            errors={errors.oldPassword} type="password" />
                    </div>
                    {/* .input-item */}
                </div>
                {/* .col */}
            </div>
            {/* .row */}
            <div className="row">
                <div className="col-md-6">
                    <div className="input-item input-with-label">
                        <label htmlFor="new-pass" className="input-item-label">New
                            Password</label>
                        <FormInput onChange={setNewPassword} value={newPassword}
                            errors={errors.newPassword} type="password" />
                    </div>
                    {/* .input-item */}
                </div>
                {/* .col */}
                <div className="col-md-6">
                    <div className="input-item input-with-label">
                        <label htmlFor="confirm-pass" className="input-item-label">Confirm
                            New Password</label>
                        <FormInput onChange={setCfmNewPassword} value={cfmNewPassword}
                            errors={errors.cfmNewPassword} type="password" />
                    </div>
                    {/* .input-item */}
                </div>
                {/* .col */}
            </div>
            {/* .row */}
            <div className="note note-plane note-info pdb-1x">
                <em className="fas fa-info-circle" />
                <p>Password should be minimum of 8 letters and with at least a symbol, upper and lower case letters and
                    a number.</p>
            </div>
            <div className="gaps-1x" />
            {/* 10px gap */}
            <div className="d-sm-flex justify-content-between align-items-center">
                <button className="btn btn-primary" disabled={isLoading} onClick={submit}>
                    {isLoading ? <Spinner /> : 'Update'}
                </button>
            </div>
        </div>
    )
}

const stp = (state: StoreState) => ({
    user: state.auth
});
const dtp = {
}

export const PasswordChangeSection = connect(stp, dtp)(PasswordChangeSectionComponent)
