import {Country, State, City} from 'country-state-city';

export interface PhoneCode {
    name: string,
    value: string
}

export interface Country {
    name: string,
    value: string
}

export interface State {
    name: string,
    value: string
}

export interface City {
    name: string,
    value: string
}

async function getPhoneCodeList() {
    const countries = Country.getAllCountries();
    return countries.map((country) => {
        let name = country.phonecode.includes('+') ? country.phonecode : '+' + country.phonecode
        return {
            name: name,
            value: name
        }
    }).sort((a, b) => {
        return a.name.localeCompare(b.name);
    });
}

async function getCountriesList() {
    const countries = Country.getAllCountries();
    return countries.map((country) => {
        return {
            name: country.name,
            value: country.isoCode
        }
    })
}
async function getStatesList(countryCode: string) {
    const states = State.getStatesOfCountry(countryCode)
    return states.map((state) => {
        return {
            name: state.name,
            value: state.isoCode
        }
    })
}

async function getCitiesList(countryCode: string, stateCode: string) {
    const cities = City.getCitiesOfState(countryCode, stateCode);
    return cities.map((city) => {
        return {
            name: city.name,
            value: city.name
        }
    })
}

export {
    getPhoneCodeList,
    getCountriesList,
    getStatesList,
    getCitiesList
}
