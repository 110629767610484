import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "../../components/spinner";
import { shrinkHash, readableDate, readableNum } from "../../helpers/ui.helper";
import { getTransactions } from "../../services/transaction.service";
import { Transaction } from "../../types/generic/transaction.type";
import { StoreState } from "../../types/store-state.type";

function TransactionTableComponent() {

    useEffect(() => {
        getTransactionList();
    }, []);

    const [transactionList, setTransactionList] = useState<Transaction[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const getTransactionList = async () => {
        try {
            setIsLoading(true);
            const response = await getTransactions(1);
            setTransactionList(response.data.slice(0, 3));
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            console.log(e);
        }
    };

    return (
        <div className="token-transaction card card-full-height">
            <div className="card-innr">
                <div className="card-head has-aside">
                    <h4 className="card-title">Transaction</h4>
                    <div className="card-opt">
                        <Link to='/transactions'>
                            <a className="link ucap">View ALL <em className="fas fa-angle-right ml-2" /></a>
                        </Link>
                    </div>
                </div>
                {
                    isLoading ? <Spinner />
                        :
                        <table className="table tnx-table">
                            <thead>
                                <tr>
                                    <th>TXN NO</th>
                                    <th>USD Amount</th>
                                    <th className="d-none d-sm-table-cell tnx-date">Date</th>
                                    <th className="tnx-type"><div className="tnx-type-text" /></th>
                                </tr>
                            </thead>
                            <tbody>
                                <TransactionTableBody transactionList={transactionList} ></TransactionTableBody>
                            </tbody>
                        </table>
                }
            </div>
        </div>
    )
}

function TransactionTableBody({ transactionList }: any) {
    return (
        <>
            {
                Array.isArray(transactionList)
                    ? transactionList.map((transaction: Transaction, index: number) => (
                        <tr key={index}>
                            <td>
                                <div className="d-flex align-items-center">
                                    <div className={"data-state data-state-" + transaction.status}>
                                        <span className="d-none">{transaction.status}</span>
                                    </div>
                                    <div className="fake-class">
                                        <div className="lead tnx-id">{shrinkHash(transaction.hash)}</div>
                                        <div className="sub sub-date">{readableDate(transaction.createdAt)}</div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div>
                                    <span className="lead amount-pay">{readableNum(transaction.tokenDetails.usdContribution)}</span>
                                    <span className="sub sub-symbol">USD <em className="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" title="1 ETH = 350.54 USD" /></span>
                                </div>
                            </td>
                            <td className="d-none d-sm-table-cell tnx-date">
                                <span className="sub sub-s2">{moment(transaction.createdAt).format('YYYY-MM-DD HH:MM a')}</span>
                            </td>
                            <td className="tnx-type">
                                <span className="tnx-type-md badge badge-outline badge-success badge-md">{transaction.type === 'purchase' ? 'Purchase' : 'Deposit'}</span>
                                <span className="tnx-type-sm badge badge-sq badge-outline badge-success badge-md">{transaction.type === 'purchase' ? 'Pur' : 'Dep'}</span>
                            </td>
                        </tr>
                    ))
                    :
                    <></>
            }
            {
                transactionList.length === 0 ?
                    <tr>
                        <td colSpan={4}>
                            <div style={{ textAlign: 'center', paddingTop: '15px', fontWeight: '500' }}> No Data </div>
                        </td>
                    </tr>
                    : <></>
            }
        </>
    )
}

const stp = (state: StoreState) => ({});

const dtp = {};

export const TransactionTable = connect(stp, dtp)(TransactionTableComponent);