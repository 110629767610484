import { getTotalTokenSale } from "../../services/reporting.service";


export const SET_TOTAL_TOKEN_SALE = "SET_TOTAL_TOKEN_SALE";

export function setTotalTokenSale(amount: number) {
    return {
        type: SET_TOTAL_TOKEN_SALE,
        amount,
    };
}

export function getTotalTokenSaleFromServer() {
    return async (dispatch: any) => {
        const { amount } = await getTotalTokenSale();
        dispatch(setTotalTokenSale(amount));
    }
}

export function totalTokenSaleReducer(state = 0, action: any) {
    switch (action.type) {
        case SET_TOTAL_TOKEN_SALE:
            return action.amount;
        default:
            return state;
    }
}
