const isPasswordValid = (password: string) => {
    // min 8 letter password, with at least a symbol, upper and lower case letters and a number
    const re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
    return re.test(password);
}

const isNameValid = (name: string) => {
    const regex = /^[a-zA-Z\s]+$/;
    return regex.test(name);
}

export {
    isPasswordValid,
    isNameValid
}
