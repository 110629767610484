import React, { MouseEventHandler } from 'react';
import { Spinner } from '../spinner';

type FormButtonProps = {
    label: string,
    onClick: MouseEventHandler<HTMLButtonElement>,
    isLoading?: boolean,
}

export function FormButton({
    label = "Click here",
    onClick,
    isLoading = false,
}: FormButtonProps) {
    return (
        <button className="btn btn-primary btn-block" onClick={onClick} disabled={isLoading}>
            {isLoading ? <Spinner white /> : label}
        </button>
    )
}
