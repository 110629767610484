import { BuyTokenPage, ICODistributionPage, KYCApplication, LoginPage, TransactionsPage } from "../pages";
import { AdminDashboardPage, AllUserTransactionsPage, OfflineTransactionsPage } from "../pages/admin";
import { KycListPage } from "../pages/admin/kyc-list.page";
import { DashboardPage } from "../pages/dashboard.page";
import { ProfilePage } from "../pages/profile.page";
import DepositsPage from "../pages/deposits.page";
import { KYCForm } from "../pages/kyc-form.page";
import { KycThankYouPage } from "../pages/kyc-thank-you.page";
import { User } from "../types/generic/user.type";
import { isAdmin } from "../helpers/role.helper";
import { isKYCApproved } from "../helpers/kyc.helper";
import { KYCStatusPage } from "../pages/kyc/kyc-status.page";
import { ReferralsPage } from "../pages/referrals.page";
import { Navigate } from "react-router-dom";
import { CheckoutSuccessPage } from "../pages/checkout-success.page";
import { CheckoutFailurePage } from "../pages/checkout-failure.page";
import { KycSuccessPage } from "../pages/kyc-success.page";
import { YotiKycListPage } from "../pages/admin/yoti-kyc-list.page";

const userDashboardRouteChildren = (user: User) => {
    const isApproved = isKYCApproved(user);

    return [
        {
            path: "dashboard",
            element: <DashboardPage />,
        },
        {
            path: "profile",
            element: <ProfilePage />,
        },
        {
            path: "ico-distribution",
            element: <ICODistributionPage />,
        },
        {
            path: "buy-token",
            // element: isApproved ? <BuyTokenPage /> : <KYCStatusPage />,
            element: <BuyTokenPage />,
        },
        {
            path: "deposits",
            element: <DepositsPage />,
        },
        {
            path: "referrals",
            element: <ReferralsPage />,
        },
        {
            path: "transactions",
            element: <TransactionsPage />,
        },
        {
            path: "kyc-application",
            element: <KYCApplication />,
        },
        {
            path: "kyc-approved",
            element: <KYCApplication />,
        },
        {
            path: "kyc-form",
            element: <KYCForm />,
        },
        {
            path: "kyc-thank-you",
            element: isApproved ? <Navigate to="/dashboard" /> : <KycThankYouPage />,
        },
        {
            path: "checkout/success",
            element: <CheckoutSuccessPage />,
        },
        {
            path: "checkout/fail",
            element: <CheckoutFailurePage />,
        },
        {
            path: "kyc/success",
            element: <KycSuccessPage />,
        },
        {
            path: "kyc/fail",
            element: <KycSuccessPage />,
        },
    ]
};

const adminDashboardRouteChildren = (user?: User) => (
    [
        {
            path: "dashboard",
            element: <AdminDashboardPage />,
        },
        {
            path: "transactions",
            element: <AllUserTransactionsPage />,
        },
        {
            path: "kyc-list/yoti",
            element: <YotiKycListPage />,
        },
        {
            path: "kyc-list",
            element: <KycListPage />,
        },
        {
            path: "kyc-detail/:id",
            element: <KycListPage />,
        },
        {
            path: "offline-transactions",
            element: <OfflineTransactionsPage />,
        },
    ]
);

const dashboardRoutes = (user: User) =>
    isAdmin(user)
        ? adminDashboardRouteChildren(user)
        : userDashboardRouteChildren(user);

export { dashboardRoutes };
