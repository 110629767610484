import React, { useEffect } from 'react';
import cx from "classnames";
import { Transaction } from '../../types/generic/transaction.type';
import { getCurrencyNameFromCode, humanReadableDate, readableDate, readableNum } from '../../helpers/ui.helper';
import { initJQuery } from '../../helpers/jquery.helper';
import { Link } from 'react-router-dom';

interface TransactionDetailsComponentProps {
    isCard?: boolean;
    transaction?: Transaction;
}

export function TransactionDetailsComponent({
    isCard,
    transaction,
}: TransactionDetailsComponentProps) {

    useEffect(() => {
        initJQuery();
    }, []);

    return (
        <div className={cx("content-area", {
            "card": isCard
        })}>
            <div className={cx({
                "card-innr": isCard
            })}>
                <div className="card-head d-flex justify-content-between align-items-center">
                    <h4 className="card-title mb-0">Transaction Details</h4>
                    {
                        isCard ? (
                            <>
                                <Link to="transactions" className="btn btn-sm btn-auto btn-primary d-sm-block d-none"><em className="fas fa-arrow-left mr-3" title="" />Back</Link>
                                <Link to="transactions" className="btn btn-icon btn-sm btn-primary d-sm-none"><em className="fas fa-arrow-left" title="" /></Link>
                            </>
                        ) : <></>
                    }
                </div>
                <div className="gaps-1-5x" />
                <div className="data-details d-md-flex">
                    <div className="fake-class">
                        <span className="data-details-title">Tranx Date</span>
                        <span className="data-details-info">{humanReadableDate(transaction?.createdAt)}</span>
                    </div>
                    <div className="fake-class">
                        <span className="data-details-title">Tranx Status</span>
                        <span className={`badge badge-${badgeClassForTransactionStatus(transaction?.status ?? "")} ucap`}>{transaction?.status}</span>
                    </div>
                    {
                        transaction?.approvalDate ? (
                            <div className="fake-class">
                                <span className="data-details-title">Tranx Approved Note</span>
                                <span className="data-details-info">By <strong>Admin</strong> at {humanReadableDate(transaction?.approvalDate)}</span>
                            </div>
                        ) : <></>
                    }
                </div>
                <div className="gaps-3x" />
                <h6 className="card-sub-title">Transaction Info</h6>
                <ul className="data-details-list">
                    <li>
                        <div className="data-details-head">Transaction Type</div>
                        <div className="data-details-des capitalize"><strong>{transaction?.type}</strong></div>
                    </li>{/* li */}
                    <li>
                        <div className="data-details-head">Payment Getway</div>
                        <div className="data-details-des">
                            <strong>
                                {getCurrencyNameFromCode(transaction?.paymentGateway ?? "")}
                                {
                                    transaction?.isPaymentOffline ? (
                                        <small> - Offline Payment</small>
                                    ) : <></>
                                }
                            </strong></div>
                    </li>{/* li */}
                    <li>
                        <div className="data-details-head">Deposit From</div>
                        <div className="data-details-des"><strong>{transaction?.fromWalletAddress}</strong></div>
                    </li>{/* li */}
                    <li>
                        <div className="data-details-head">Tx Hash</div>
                        <div className="data-details-des"><span>{transaction?.hash}</span> <span /></div>
                    </li>{/* li */}
                    <li>
                        <div className="data-details-head">Deposit To</div>
                        <div className="data-details-des"><span>{transaction?.toWalletAddress}</span> <span /></div>
                    </li>{/* li */}
                    <li>
                        <div className="data-details-head">Details</div>
                        <div className="data-details-des">{transaction?.details}</div>
                    </li>{/* li */}
                </ul>{/* .data-details */}
                <div className="gaps-3x" />
                <h6 className="card-sub-title">Token Details</h6>
                <ul className="data-details-list">
                    <li>
                        <div className="data-details-head">Stage Name</div>
                        <div className="data-details-des"><strong>{transaction?.tokenDetails.stageName}</strong></div>
                    </li>{/* li */}
                    <li>
                        <div className="data-details-head">Contribution</div>
                        <div className="data-details-des">
                            <span>
                                <strong>{readableNum(transaction?.tokenDetails.contributionAmount ?? 0, 8)} <span className="uppercase">{transaction?.tokenDetails.contributionCurrency}</span></strong>
                                {/* <em className="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" data-original-title="1 ETH = 100 TWZ" /> */}
                            </span>
                            <span>
                                {/* <em className="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" data-original-title="1 ETH = 100 TWZ" />  */}
                                ${readableNum(transaction?.tokenDetails.usdContribution ?? 0, 8)}
                            </span>
                        </div>
                    </li>{/* li */}
                    <li>
                        <div className="data-details-head">User Detail</div>
                        <div className="data-details-des">
                            <strong>
                                {transaction?.tokenDetails.user.name ?? transaction?.tokenDetails.user.id}
                                <small> - {transaction?.tokenDetails.user.email ?? transaction?.tokenDetails.user.mobile}</small>
                            </strong>
                        </div>
                    </li>{/* li */}
                    <li>
                        <div className="data-details-head">Token (T)</div>
                        <div className="data-details-des">
                            <span>{readableNum(transaction?.tokenDetails.tokens ?? 0)}</span>
                            {/* <span>(4780 * 1)</span> */}
                        </div>
                    </li>{/* li */}
                    <li>
                        <div className="data-details-head">Bonus Tokens (B)</div>
                        <div className="data-details-des">
                            <span>{readableNum(transaction?.tokenDetails.bonusTokens ?? 0)}</span>
                            {/* <span>(956 * 1)</span> */}
                        </div>
                    </li>{/* li */}
                    <li>
                        <div className="data-details-head">Total Tokens</div>
                        <div className="data-details-des">
                            <span>
                                <strong>
                                    {readableNum((transaction?.tokenDetails.tokens ?? 0) + (transaction?.tokenDetails.bonusTokens ?? 0))}
                                </strong>
                            </span>
                            <span>(T+B)</span>
                        </div>
                    </li>{/* li */}
                </ul>{/* .data-details */}
            </div>
        </div>
    );
}

function badgeClassForTransactionStatus(status: string) {
    switch (status) {
        case "pending":
            return "warning";
        case "approved":
            return "success";
        case "declined":
            return "danger";
        default:
            return "info";
    }
}