import React, { useEffect, useState } from 'react';
import { DataTable } from '../../../components/data-table';
import { Transaction } from '../../../types/generic/transaction.type';
import { readableDate, readableNum, shrinkHash } from '../../../helpers/ui.helper';
import { getAllUserTransactions } from '../../../services/transaction.service';
import { AdminTransactionTableFilters } from "../../../components/admin-transaction-table-filters.component";
import { ExportToCSV } from "../../../components/export-to-csv";
import { openModal } from '../../../helpers/modal.helper';
import { TransactionDetailsModal } from '../../../components/modals/transaction-details.modal';
import { connect } from 'react-redux';
import { getFGCCountForUSD } from '../../../helpers/currency.helper';

const columns = [
    {
        className: "dt-tnxno",
        title: "User"
    },
    {
        className: "dt-tnxno",
        title: "Txn No"
    },
    {
        className: "dt-token",
        title: "Tokens"
    },
    {
        className: "dt-amount",
        title: "Amount"
    },
    {
        className: "dt-usd-amount",
        title: "USD Amount"
    },
    {
        className: "dt-account",
        title: "From"
    },
    {
        className: "dt-type",
        title: <div className="dt-type-text">Type</div>
    },
    {
        className: "",
        title: "",
    },
];

function renderRow(transaction: Transaction, {
    showTransactionDetails,
    ethereumPrice,
}: any) {

    const transactionType = transaction.type.slice(0, 1).toUpperCase() + transaction.type.slice(1);

    return [
        {
            className: "dt-user",
            content: (
                <div className="d-flex align-items-center">
                    <div className="fake-class">
                        <span className="lead tnx-id">{transaction.tokenDetails.user.name}</span>
                    </div>
                </div>
            ),
        },
        {
            className: "dt-tnxno",
            content: (
                <div className="d-flex align-items-center">
                    <div className={"data-state data-state-" + transaction.status}>
                        <span className="d-none">{transaction.status}</span>
                    </div>
                    <div className="fake-class">
                        <span className="lead tnx-id">{shrinkHash(transaction.hash)}</span>
                        <span className="sub sub-date">{readableDate(transaction.createdAt)}</span>
                    </div>
                </div>
            ),
        },
        {
            className: "dt-token",
            content: (
                <div>
                    <span className="lead token-amount">{readableNum(transaction.tokenDetails.tokens + transaction.tokenDetails.bonusTokens)}</span>
                    <span className="sub sub-symbol">FGC</span>
                </div>
            )
        },
        {
            className: "dt-amount",
            content: (
                <div>
                    <span className="lead amount-pay">{transaction.tokenDetails.contributionAmount}</span>
                    <span className="sub sub-symbol">{transaction.tokenDetails.contributionCurrency.toUpperCase()} <em className="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" title={`1 ETH = ${readableNum(getFGCCountForUSD(ethereumPrice ?? 0))} FGC`} /></span>
                </div>
            )
        },
        {
            className: "dt-usd-amount",
            content: (
                <div>
                    <span className="lead amount-pay">{readableNum(transaction.tokenDetails.usdContribution)}</span>
                    <span className="sub sub-symbol">USD <em className="fas fa-info-circle" data-toggle="tooltip" data-placement="bottom" title={`1 ETH = ${readableNum(ethereumPrice)} USD`} /></span>
                </div>
            )
        },
        {
            className: "dt-account",
            content: (
                <>
                    <span className="lead user-info">{shrinkHash(transaction.fromWalletAddress)}</span>
                    <span className="sub sub-date">{readableDate(transaction.createdAt)}</span>
                </>
            )
        },
        {
            className: "dt-type",
            content: (
                <div>
                    <span className="dt-type-md badge badge-outline badge-success badge-md">{transactionType}</span>
                    <span className="dt-type-sm badge badge-sq badge-outline badge-success badge-md">{transaction.type.slice(0, 1)}</span>
                </div>
            )
        },
        {
            className: "text-right",
            content: (
                <div>
                    <div className="relative d-inline-block d-md-none">
                        <a href="#" className="btn btn-light-alt btn-xs btn-icon toggle-tigger"><em className="ti ti-more-alt" /></a>
                        <div className="toggle-class dropdown-content dropdown-content-center-left pd-2x">
                            <ul className="data-action-list">
                                <li><a href="#" className="btn btn-auto btn-primary btn-xs"><span>Pay <span className="d-none d-xl-inline-block">Now</span></span><em className="ti ti-wallet" /></a></li>
                                <li><a href="#" className="btn btn-danger-alt btn-xs btn-icon"><em className="ti ti-trash" /></a></li>
                            </ul>
                        </div>
                    </div>
                    <ul className="data-action-list d-none d-md-inline-flex">
                        {/* <li><a href="#" className="btn btn-auto btn-primary btn-xs"><span>Pay <span className="d-none d-xl-inline-block">Now</span></span><em className="ti ti-wallet" /></a></li> */}
                        <li><a href="#" onClick={e => {
                            e.preventDefault();
                            showTransactionDetails(transaction);
                        }} className="btn btn-light-alt btn-xs btn-icon"><em className="ti ti-eye" /></a></li>
                        {/* <li><a href="#" className="btn btn-danger-alt btn-xs btn-icon"><em className="ti ti-trash" /></a></li> */}
                    </ul>
                </div>
            )
        }
    ];
}

function AllUserTransactionsPageComponent({
    exchangeRate
}: any) {

    const [filterObject, setFilterObject] = useState({});
    const [timeStamp, setTimeStamp] = useState<string>(String(Date.now()));
    const [selectedTransaction, setSelectedTransaction] = useState<Transaction>();

    const bitcoinPrice = exchangeRate.bitcoin.usd;
    const ethereumPrice = exchangeRate.ethereum.usd;

    useEffect(() => {
        setTimeStamp(String(Date.now()));
    }, [filterObject]);

    const getAllFilteredTransactions = (pageStart: number) => {
        const reqQuery = { ...filterObject, ...{ pageStart: pageStart } };
        return getAllUserTransactions(reqQuery).then((r) => {
            return r
        });
    }

    const showTransactionDetails = (transaction: Transaction) => {
        setSelectedTransaction(transaction);
        openModal("transaction-details");
    }

    const renderRowWithProps = (transaction: Transaction) => {
        return renderRow(transaction, {
            showTransactionDetails,
            ethereumPrice,
        })
    };

    return (
        <div className="page-user">
            <div className="page-content">
                <div className="container">
                    <div className="card content-area">
                        <div className="card-innr">
                            <div className="card-head">
                                <h4 className="card-title">All User Transactions</h4>
                            </div>
                            <ExportToCSV filterObject={filterObject} />
                            <AdminTransactionTableFilters filterObject={setFilterObject} />
                            <DataTable
                                reRender={timeStamp}
                                columns={columns}
                                tableClass="user-tnx"
                                renderRow={renderRowWithProps}
                                fetcherMethod={getAllFilteredTransactions} />
                        </div>{/* .card-innr */}
                    </div>{/* .card */}
                </div>{/* .container */}
            </div>
            {/* Modal Large */}
            <TransactionDetailsModal
                modalId="transaction-details"
                transaction={selectedTransaction}
                onClose={() => {
                    setSelectedTransaction(undefined);
                }} />
            {/* Modal End */}
            {/* JavaScript (include all script here) */}
        </div>
    );
}

const stp = (s: any) => ({
    exchangeRate: s.exchangeRate,
})

export const AllUserTransactionsPage = connect(
    stp,
)(AllUserTransactionsPageComponent);
