import React from 'react';
import { closeModal } from '../../helpers/modal.helper';
import { KycDetail } from '../../types/generic/kyc-details.type';
import { Transaction } from '../../types/generic/transaction.type';
import { GenericModalProps } from '../../types/props/generic-modal.props';
import { KycDetailsComponent } from '../kyc-details.component';
import { TransactionDetailsComponent } from '../transaction-details.component';

interface KycDetailsModalProps extends GenericModalProps {
    kyc?: KycDetail;
    onClose: Function;
    threeDotMenu?: any;
}

export function KycDetailsModal({
    modalId,
    kyc,
    onClose,
    threeDotMenu,
}: KycDetailsModalProps) {

    const close = (e: any) => {
        e.preventDefault();
        closeModal(modalId);
        // wait half second before triggering
        setTimeout(() => {
            onClose();
        }, 500);
    };

    return (
        <div className="modal fade" id={modalId} tabIndex={-1}>
            <div className="modal-dialog modal-dialog-lg modal-dialog-centered">
                <div className="modal-content">
                    <a href="#" className="modal-close" aria-label="Close" onClick={close}><em className="ti ti-close" /></a>
                    {
                        kyc ? (
                            <div className="popup-body popup-body-lg">
                                {/* <TransactionDetailsComponent transaction={transaction} /> */}
                                <KycDetailsComponent
                                    isCard
                                    kyc={kyc}
                                    onBack={close}
                                    threeDotMenu={threeDotMenu} />
                            </div>
                        ) : <></>
                    }
                </div>{/* .modal-content */}
            </div>
        </div>
    )
}