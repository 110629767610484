import React from "react";

export function PrivacyAndPolicyModal({
                                            modalId
                                        }: any) {
    return (
        <div className="modal fade" id={modalId} tabIndex={-1}>
            <div className="modal-dialog modal-dialog-md modal-dialog-centered">
                <div className="modal-content">
                    <a href="#" className="modal-close" data-dismiss="modal" aria-label="Close"><em
                        className="ti ti-close"/></a>
                    <div className="popup-body">
                        <h4 className="popup-title">Privacy and Policy</h4>
                        <div className="scrollable-modal">
                            <h4>Data collection</h4>
                            <p>
                                When a user signs up to our website or related sub-domains, they may be required to provide
                                certain personal information, such as their name, address, date of birth, and identification
                                documents. This information is collected to fulfil KYC / AML requirements and ensure
                                compliance with applicable laws and regulations.
                            </p>
                            <h4>Data storage</h4>
                            <p>
                                The collected data is stored securely in a database, encrypted with the appropriate encryption
                                protocols, and accessible only to authorised personnel who need to access it for the purpose of
                                providing services to users and complying with regulatory requirements.
                            </p>
                            <h4>Data processing</h4>
                            <p>
                                The collected data is processed in accordance with the relevant laws and regulations. This may
                                include verifying the user's identity and conducting due diligence to ensure compliance with
                                anti-money laundering (AML) and counter-terrorism financing (CTF) laws.
                            </p>
                            <h4>Data retention</h4>
                            <p>
                                The collected data is retained for a certain period of time, as required by law or regulation, and
                                securely disposed of when no longer needed. For users that register on our website, we store
                                the personal information they provide in accordance with the law and the user to use their
                                account effectively. All users can see, edit, or delete their account information.
                            </p>
                            <h4>Data sharing</h4>
                            <p>
                                We do not share your data with any third party or use your data for any other purpose. In some
                                cases, the website may be required by law to share the collected data with third parties, such as
                                law enforcement agencies or regulatory bodies.
                            </p>
                            <h4>What rights you have over your data</h4>
                            <p>
                                If you have an account on this site you can request to receive an exported file of the personal
                                data we hold about you, including any data you have provided to us. You can also request that
                                we erase any personal data we hold about you though this may result in your account be
                                disabled. This does not include any data we are obliged to keep for administrative, legal, or
                                security purposes.
                            </p>
                            <h4>Data protection</h4>
                            <p>
                                The website has appropriate security measures in place to protect the collected data from
                                unauthorised access, theft, or misuse. This includes firewalls, encryption, access controls, and
                                regular security audits.
                            </p>
                            <p>
                                Overall, the Football Goal Coin's handling of private KYC / AML and other data is designed to
                                avoid fraud, ensure compliance with applicable laws and regulations while providing users with
                                a secure and reliable service.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
