import React, { useState } from "react";
import { GetPayAddressModal } from "../../components/modals/get-pay-address.modal";
import { PayConfirmModal } from "../../components/modals/pay-confirm.modal";
import { PayReviewModal } from "../../components/modals/pay-review.modal";
import { MobileOnlyPageHeading } from "../../components/mobile-only-page-heading";
import { ContributionCard } from "../../components/contribution-card.component";
import { Banner } from "../../components/banner";

export default function DepositsPage() {

    // transaction related
    const [orderId, setOrderId] = useState("");
    const [orderCurrency, setOrderCurrency] = useState("");

    return (
        <div className="DepositsPage">
            <div className="page-user">
                <div className="page-content">
                    <div className="container">
                        <Banner images={['/images/deposit_1.jpg', '/images/dashboard_2.png', '/images/money_back_guarantee.png']}></Banner>
                        <div className="row">
                            <MobileOnlyPageHeading title="Buy With Crypto" />
                            <div className='col-md-12 d-lg-none'>
                                <ContributionCard />
                            </div>
                            <div className="main-content col-lg-8">
                                <div className="content-area card">
                                    <div className="card-innr">
                                        <GetPayAddressModal
                                            modalId="get-pay-address"
                                            setOrderId={setOrderId}
                                            setOrderCurrency={setOrderCurrency}
                                            isComponent />
                                        <PayConfirmModal
                                            modalId="pay-confirm"
                                            orderId={orderId}
                                            orderCurrency={orderCurrency} />
                                        <PayReviewModal modalId="pay-review" />
                                    </div>
                                </div>
                            </div>
                            <div className="aside sidebar-right col-lg-4">
                                <div className='d-none d-lg-block'>
                                    <ContributionCard />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
